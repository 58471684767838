import React, { useEffect, useState } from "react";
import logo from "../../assets/images/LogoW.svg";
import newWhitelogo from "../../assets/images/WhiteLogo.png";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Link, useNavigate } from "react-router-dom";
import "./Footer.scss";
import mastercard from "../../assets/images/Mastercard.svg";
import visa from "../../assets/images/Visa.svg";
import amex from "../../assets/images/Amex.svg";
import payPal from "../../assets/images/PayPal.svg";
import badge from "../../assets/images/Badge.svg";
import badge2 from "../../assets/images/Badge2.svg";
import { FaFacebook, FaInstagram, FaLinkedin, FaTelegram } from "react-icons/fa";
import { footerPageData, getPageContent } from "../../api/apiRequest";
import { NotificationManager } from "react-notifications";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Cookies from "js-cookie";
import { useLangContext } from "../../context/languageContext";

const Footer = () => {
  const navigate = useNavigate();
  const { hostLanguage } = useLangContext();
  const [currentYear] = useState(new Date().getFullYear());
  const [data, setData] = useState([]);

  const [show, setShow] = useState(true);
  const handleClose = () => setShow(false);

  const [isChecked, setIsChecked] = useState(false);
  const [contentDetails, setContentDetails] = useState();

  const handleCheckboxChange = (event) => {
    setIsChecked(event.target.checked);
  };

  const [tokenexist, setTokenexist] = useState(false);
  const [scriptLoaded, setScriptLoaded] = useState(false);

  const [hide, setHide] = useState(false);
  const hideInstaAlert = () => {
    setHide(true);
    Cookies.set("showCookieNotify", "set", {
      expires: 30,
    });

    if (isChecked) {
      Cookies.set("allowCookieTranslate", "set", {
        expires: 1,
      });
    }
  };

  // get page content
  const getAllPageContent = async () => {
    try {
      let apiRes = await getPageContent(hostLanguage);
      let responseData = await apiRes.json();
      if (responseData.res) {
        setContentDetails(responseData.data);
      } else {
        NotificationManager.error(responseData.msg, "", 2000);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const GetCookie = () => {
    setTokenexist(Cookies.get("showCookieNotify"));
  };

  const fetchData = async () => {
    try {
      let apiRes = await footerPageData(hostLanguage);
      let responseData = await apiRes.json();

      if (responseData.res) {
        setData(responseData.data);
      } else {
        NotificationManager.error(responseData.msg, "", 2000);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const appLink = (type) => {
    if (type == "android") {
      window.open("https://play.google.com/store/apps/details?id=com.cleverdomizilmail");
    } else if (type == "apple") {
      window.open("https://apps.apple.com/in/app/cleverdomicile/id6502281781");
    }
  };

  useEffect(() => {
    GetCookie();
    getAllPageContent();
    fetchData();
  }, [hostLanguage]);

  return (
    <div className="MainFooter">
      <Container className="FooterContainer">
        <Row>
          <Col sm={12} md={6} lg={3}>
            <div className="MainFooterInfo">
              <img src={newWhitelogo} className="MainFooterLogo" alt="logo" onClick={() => navigate("/")} style={{ cursor: "pointer" }} />

              <div dangerouslySetInnerHTML={{ __html: data?.rightFooterData?.[0]?.description1 }}></div>
              <ul>
                <li>
                  <a href="https://www.facebook.com/people/CleverDomizil/61558857249140/" target="_blank" title="Facebook" style={{ color: "#fff" }}>
                    <FaFacebook />
                  </a>
                </li>
                <li>
                  <a href="https://www.instagram.com/cleverdomizil/?igsh=M2dvNnQzbjdibWh0&utm_source=qr" target="_blank" title="Instagram" style={{ color: "#fff" }}>
                    <FaInstagram />
                  </a>
                </li>
                <li>
                  <a href="https://t.me/+491773613619" target="_blank" style={{ color: "#fff" }} title="Telegram">
                    <FaTelegram />
                  </a>
                </li>
                <li>
                  <FaLinkedin />
                </li>
              </ul>
            </div>
          </Col>
          <Col sm={12} md={6} lg={6}>
            <div className="MainFooterLink">
              <h3>{contentDetails?.footer?.footer_heading}</h3>
              <ul>
                <li>
                  <Link to="/product">{contentDetails?.footer?.parent_0}</Link>
                </li>
                <li>
                  <Link to="/LocationsWithoutMap">{contentDetails?.footer?.parent_1}</Link>
                </li>
                <li>
                  <Link to="/PricingMailbox">{contentDetails?.footer?.parent_2}</Link>
                </li>
                <li>
                  <Link to="/about-us">{contentDetails?.footer?.parent_3}</Link>
                </li>

                <li>
                  <Link to="/contact-us">{contentDetails?.footer?.parent_4}</Link>
                </li>

                <li>
                  <Link to="/terms">{contentDetails?.footer?.parent_5}</Link>
                </li>
                <li>
                  <Link to="/privacy">{contentDetails?.footer?.parent_6}</Link>
                </li>
                <li>
                  <Link to="/imprint">{contentDetails?.footer?.parent_7}</Link>
                </li>
              </ul>
            </div>
            <div className="MainFooterLinkcard">
              <ul>
                <li>
                  <img src={mastercard} className="MainLogo" alt="logo" />
                </li>
                <li>
                  <img src={visa} className="MainLogo" alt="logo" />
                </li>
                <li>
                  <img src={amex} className="MainLogo" alt="logo" />
                </li>
                <li>
                  <img src={payPal} className="MainLogo" alt="logo" />
                </li>
              </ul>
            </div>
            <div className="MainFooterLinkcard2">
              <ul>
                <li>
                  <img src={badge} className="MainLogo" alt="logo" onClick={() => appLink("android")} style={{ cursor: "pointer" }} />
                </li>
                <li>
                  <img src={badge2} className="MainLogo" alt="logo" onClick={() => appLink("apple")} style={{ cursor: "pointer" }} />
                </li>
              </ul>
            </div>
          </Col>
          <Col sm={12} md={6} lg={3}>
            <div className="MainFooterInfo">
              <h2>CleverDomizil</h2>
              <div dangerouslySetInnerHTML={{ __html: data?.leftFooterData?.[0]?.description1 }}></div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col sm={12} md={12} lg={12}>
            <p style={{ textAlign: "center", color: "#fff", margin: "20px", marginBottom: "0", fontSize: "14px" }}>{contentDetails?.footer?.footer_text}</p>
            <div className="MainFooterBottom">
              <p>Copyright &copy; {currentYear} CleverDomizil</p>
            </div>
          </Col>
        </Row>
      </Container>

      {/* Modal of Accept all cookies */}

      {hide ? (
        <></>
      ) : tokenexist ? (
        <></>
      ) : (
        <Modal
          show={show}
          onHide={handleClose}
          backdrop="static"
          keyboard={false}
          aria-labelledby="contained-modal-title-vcenter"
          centered
          size="lg"
          className="CookiesModal"
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              Datenschutzeinstellungen
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>
              Wir verwenden Cookies und andere Tracking-Technologien, die Daten auslesen oder Informationen auf Ihrem Endgerät speichern können. Mit Ihrer
              Einwilligung verarbeiten wir Ihre personenbezogenen Daten zu Analyse- und Optimierungszwecken sowie zu Marketing- und Werbezwecken. Dazu werden
              teilweise Informationen an Dienstleister weitergegeben, die ihren Sitz in einem Drittland haben können, in dem kein vergleichbares
              Datenschutzniveau herrscht. Indem Sie auf "Alle akzeptieren. Cookies“ erklären Sie sich mit dieser Verarbeitung und Übermittlung Ihrer Daten
              einverstanden. Sie können Ihre Einwilligung jederzeit in den Cookie-Einstellungen in der Fußzeile widerrufen. Klicken Sie auf die
              Cookie-Einstellungen, um mehr zu erfahren Weitere Informationen finden Sie in unserer DatenschutzerklärungImpressum
            </p>
            <div className="newDiv">
              <div className="newDivTop">
                <label>
                  <input
                    type="checkbox"
                    checked={true}
                    disabled
                  />
                  Notwendige Cookies
                </label>

                <table>
                  <tr>
                    <th>Cookie</th>
                    <th>Ablaufdatum</th>
                  </tr>
                  <tr>
                    <td>PHPSESSID</td>
                    <td>
                      Beim Beenden der Browsersitzung
                    </td>
                  </tr>
                  <tr>
                    <td>ci_session</td>
                    <td>
                      Nach 2 Stunden
                    </td>
                  </tr>
                  <tr>
                    <td>showCookieNotify</td>
                    <td>
                     Nach 30 Tagen
                    </td>
                  </tr>
                  <tr>
                    <td>allowCookieTranslate</td>
                    <td>
                      Nach 30 Tagen
                    </td>
                  </tr>
                </table>
              </div>
              <div className="newDivTop">
                <label>
                  <input type="checkbox" checked={isChecked} onChange={handleCheckboxChange} />
                  Google Übersetzer
                </label>

                <table>
                  <tr>
                    <th>Cookie</th>
                    <th>Ablaufdatum</th>
                  </tr>
                  <tr>
                    <td>NID</td>
                    <td>
                      Nach 1 Tag
                    </td>
                  </tr>
                  <tr>
                    <td>__Secure-3PAPISID</td>
                    <td>
                     Nach 1 Tag
                    </td>
                  </tr>
                  <tr>
                    <td>__Secure-3PSID</td>
                    <td>
                      Nach 1 Tag
                    </td>
                  </tr>
                  <tr>
                    <td>__Secure-3PSIDCC</td>
                    <td>
                      Nach 1 Tag
                    </td>
                  </tr>
                </table>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Link to="/privacy" className="MOsaveBtn2">
              Mehr Informationen
            </Link>
            
            <Button variant="primary" onClick={() => hideInstaAlert()} className="MOsaveBtn">
              Auswahl akzeptieren
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    </div>
  );
};

export default Footer;
