import React, { useEffect, useState } from "react";
import InnerHeader from "../../components/header/InnerHeader";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Sidebar from "../../components/sidebar/Sidebar";
import Mailboxright from "../../components/mailboxright/Mailboxright";
import Table from "react-bootstrap/Table";
import Form from "react-bootstrap/Form";
import ScanEnvelop from "../../assets/images/ScanEnvelop.svg";
import ScanIcon from "../../assets/images/ScanIcon.svg";
import Send from "../../assets/images/Send.svg";
import Collect from "../../assets/images/Collect.svg";
import Pagination from "react-bootstrap/Pagination";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import TickBlue from "../../assets/images/TickBlue.svg";
import Nav from "react-bootstrap/Nav";
import Remove from "../../assets/images/Remove.svg";
import "./EnvelopScan.scss";
import { useNavigate, Link } from "react-router-dom";
import { NotificationManager } from "react-notifications";

import { getEnvelopScanData, getAllMailCategory, updateMailCategoryById, recordMovedtoTrash, getPageContent } from "../../api/apiRequest";
import { useContextValues } from "../../context/commonContext";
import { useLangContext } from "../../context/languageContext";


const EnvelopScan = () => {
  const {setEventFire} = useContextValues();
  const {hostLanguage,languageJson} = useLangContext();
  const [show, setShow] = useState(false);
  const [payshow, paysetShow] = useState(false);
  const [addressshow, addresssetShow] = useState(false);
  const [thankshow, thanksetShow] = useState(false);
  const [delshow, deletShow] = useState(false);
  const [selectedValue, setSelectedValue] = useState(1);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const payhandleClose = () => paysetShow(false);
  const payhandleShow = () => paysetShow(true);

  const addresshandleClose = () => addresssetShow(false);
  const addresshandleShow = () => addresssetShow(true);

  const thankhandleClose = () => thanksetShow(false);
  const thankhandleShow = () => thanksetShow(true);

  const delhandleClose = () => deletShow(false);
  const delhandleShow = () => deletShow(true);

  const [allEnvArr,setAllEnvArr] = useState([]);
  const [totalPages,setTotalPages] = useState();
  const [pageButtonsToShow,setPageButtonShow] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [categories,setCategories] = useState([]);
  const [deleteRecordId,setDeleteRecodId] = useState();
  const [contentDetails,setContentDetails] = useState();

  // all mail categories
  const fetchingAllMailCategories = async() => {
    try
    {
      let apiRes = await getAllMailCategory(hostLanguage);
      let responseData = await apiRes.json();
      if(responseData.res)
      {
        setCategories(responseData.data);
      }
      else
      {
        NotificationManager.error(responseData.msg,'',2000);
      }
    }
    catch(error)
    {
        // NotificationManager.error('Something went wrong!', '',2000);
    }
  }

  // all envelop data
  const fetchAllEnvData = async(pageNumber) =>{
    try
    {
      let apiRes = await getEnvelopScanData(pageNumber,hostLanguage);
      let responseData = await apiRes.json();
      if(responseData.res)
      {
        // Convert object to array
        const dataArr = Object.values(responseData.data.data).filter(obj => typeof obj === 'object');
        setAllEnvArr(dataArr);
        setPageButtonShow(responseData.data.total);
        const totalPages = Math.ceil(Number(responseData.data.data.total_mail_count) / Number(responseData.data.per_page));
        setTotalPages(totalPages);
      }
      else
      {
        NotificationManager.error(responseData.msg,'',2000);
      }
    }
    catch(error)
    {
        // NotificationManager.error('Something went wrong!', '',2000);
    }
  }

  // get page content
  const getAllPageContent = async() => {
    try
    {
      let apiRes = await  getPageContent(hostLanguage);
      let responseData = await apiRes.json();
      if(responseData.res)
      {
        setContentDetails(responseData.data);
        // console.warn(responseData.data.header);
      }
      else
      {
        NotificationManager.error(responseData.msg,'',2000);
      }
    }
    catch(error)
    {
      console.error(error);
    }
  }

  // date format
  const dateFormat = (date) => {
    let split_date = date.split('-');
    // let final_date = split_date[1] + '.' + split_date[2] + '.' + split_date[0];
    let final_date = split_date[2] + '.' + split_date[1] + '.' + split_date[0];
    return final_date
  }

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
    fetchAllEnvData(newPage);
  };

  const getPageNumbers = () => {
    const pageNumbers = [];
    const ellipsisKey = 'ellipsis';
  
    // console.warn('pages: ' + totalPages)
    // console.warn('button: ' + pageButtonsToShow)
    if (totalPages <= pageButtonsToShow)
    {
      // console.warn(1);
      for (let i = 1; i <= totalPages; i++)
      {
        pageNumbers.push(i);
      }
    }
    else
    {
      console.warn(2);
      if (currentPage <= Math.ceil(pageButtonsToShow / 2))
      {
        console.warn(3);
        for (let i = 1; i <= pageButtonsToShow - 1; i++)
        {
          pageNumbers.push(i);
        }
        pageNumbers.push({ key: ellipsisKey });
        pageNumbers.push(totalPages);
      }
      else if (currentPage >= totalPages - Math.floor(pageButtonsToShow / 2))
      {
        console.warn(4);
        pageNumbers.push(1);
        pageNumbers.push({ key: ellipsisKey });
        for (let i = totalPages - pageButtonsToShow + 2; i <= totalPages; i++)
        {
          pageNumbers.push(i);
        }
      }
      else
      {
        console.warn(5);
        pageNumbers.push(1);
        pageNumbers.push({ key: ellipsisKey });
        for (let i = currentPage - Math.floor(pageButtonsToShow / 2) + 1; i <= currentPage + Math.floor(pageButtonsToShow / 2) - 1; i++) {
          pageNumbers.push(i);
        }
        pageNumbers.push({ key: ellipsisKey });
        pageNumbers.push(totalPages);
      }
      console.warn(6);
    }
    console.log(pageNumbers);
    return pageNumbers;
  };

  // moved trashed data
  const MoveToTrashed = async() => {
    try
    {
      setEventFire(true);
      let apiRes = await recordMovedtoTrash(deleteRecordId);
      let responseData = await apiRes.json();
      if(responseData.res)
      {
        NotificationManager.success(responseData.msg,'',2000);
        fetchAllEnvData(1);
        delhandleClose();
        setEventFire(false);
      }
      else
      {
        NotificationManager.error(responseData.msg,'',2000);
      }
    }
    catch(error)
    {
        // NotificationManager.error('Something went wrong!', '',2000);
    }
  }

  // update mail category by id
  const updateCategoryById = async(record_id,cat_id) => {
    try
    {
      let apiRes = await updateMailCategoryById(record_id,cat_id);
      let responseData = await apiRes.json();
      if(responseData.res)
      {
        NotificationManager.success(responseData.msg,'',2000);
        fetchAllEnvData(1);
      }
      else
      {
        NotificationManager.error(responseData.msg,'',2000);
      }
    }
    catch(error)
    {
        // NotificationManager.error('Something went wrong!', '',2000);
    }
  }

  //download files
  const handleDownload = async (record_id) => {
    try 
    {
      let base_url = 'https://cleverdomizil.de';
      const newInputs = allEnvArr.filter((item) => item.id === record_id);
      let envelop_file = newInputs[0]?.envelop;
      let scan_document = newInputs[0]?.scan_document;
      let download_arr = [];

      if(envelop_file === '' || envelop_file === null || envelop_file === undefined)
      {
        // console.warn('envelop_file: ' + envelop_file);
      }
      else
      {
        let env_url = base_url + envelop_file?.split("httpdocs")[1];
        // await downloadFile(env_url,'envelop.png');
        download_arr.push(env_url);
      }

      if(scan_document === '' || scan_document === null || scan_document === undefined)
      {
        // console.warn('scan_document: ' + scan_document);
      }
      else
      {
        let scan_url = base_url + scan_document?.split("httpdocs")[1];
        // await downloadFile(scan_url,'scan_document.pdf');
        // download_arr.push(scan_url);
      }
      download_arr?.map((item,index)=>{
        downloadFile(item)
      })
      // NotificationManager.success("File Download has started..",'',2000);
    }
    catch (error)
    {
      console.error(error);
    }
  };

  const downloadFile = async (url, fileName) => 
  {
    window.open(url, '_blank');
    // NotificationManager.success("File Download has started..",'',2000);
    // const response = await fetch(url);
    // const blob = await response.blob();

    // const link = document.createElement('a');
    // link.href = window.URL.createObjectURL(blob);
    // link.download = fileName;
    // document.body.appendChild(link);
    // link.click();
    // document.body.removeChild(link);
  };

  useEffect(()=>{
    fetchingAllMailCategories();
    fetchAllEnvData(1);
    getAllPageContent();
  },[hostLanguage])

  return (
    <>
      {/* <div className="lightBlue"> */}
        {/* <InnerHeader /> */}
        {/* <div className="mail-box-main-body"> */}
          {/* <Row className="mail-box-body"> */}
            {/* <Col sm={2}>
              <Sidebar />
            </Col> */}
            <Col sm={12} md={9} lg={10}>
              <div className="MailboxTable">
                <div className="Titelbox">
                  <span>
                    {/* <h3>Envelop Scan</h3> */}
                    <h3>{contentDetails?.after_login?.EnvelopScan}</h3>
                  </span>
                  <span>
                    <Link to="/Dashboard" className="DashboardBtn">
                      {" "}
                      {/* Go To Dashboard */}
                      {contentDetails?.after_login?.GoToDashboard}
                    </Link>
                  </span>
                </div>

                <Table>
                  <thead>
                    <tr>
                      {/* <th>
                        <div className="form-check">
                          <Form.Check
                            aria-label="option 1"
                            id="flexCheckChecked2"
                          />
                        </div>
                      </th> */}
                      {/* <th>From</th>
                      <th>Type</th>
                      <th>Weight</th>
                      <th>Date</th>
                      <th>Collect</th>
                      <th>Category</th>
                      <th>Actions</th> */}
                      <th> {contentDetails?.after_login?.From}</th>
                      <th> {contentDetails?.after_login?.Type}</th>
                      <th> {contentDetails?.after_login?.Weight}</th>
                      <th> {contentDetails?.after_login?.Date}</th>
                      <th> {contentDetails?.after_login?.Collect}</th>
                      <th> {contentDetails?.after_login?.Category}</th>
                      <th> {contentDetails?.after_login?.Actions}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {allEnvArr?.length > 0 ? (
                      allEnvArr && allEnvArr.map((item,index)=>{
                        return(
                          <>
                            <tr className="odd_tr">
                              {/* <td>
                                <div className="form-check">
                                  <Form.Check
                                    aria-label="option 1"
                                    id="flexCheckChecked2"
                                  />
                                </div>
                              </td> */}
                              <td>{item?.from}</td>
                              <td>{item?.mail_type?.name}</td>
                              <td>{item?.weight}g</td>
                              <td>{dateFormat(item?.get_date)}</td>

                              {/* <td class="disableTd">
                                <a class="icon hover-scan-document3">
                                  <img
                                    src={ScanEnvelop}
                                    className="TableIcon  hover-scan-document3"
                                    alt="Img"
                                  />
                                </a>

                                <div class="show-scan-document-over3">
                                  <div class="show-scan-document3">
                                    <h3>Do you want to Scan only Envelop?</h3>
                                    <button type="button" class="btn btn-secondary">
                                      No
                                    </button>
                                    <button
                                      type="button"
                                      class="btn btn-primary"
                                      onClick={handleShow}
                                    >
                                      Yes
                                    </button>
                                  </div>
                                </div>
                              </td> */}

                              <td>
                                <img src={Collect} className="TableIcon" alt="Img" onClick={()=>handleDownload(item?.id)}/>
                              </td>
                              <td>
                                <Form.Select aria-label="Default select example" onChange={(e)=>updateCategoryById(item?.id,e.target.value)}>
                                  {/* <option>Insurance</option> */}
                                  {categories && categories.map((cat_item,cat_index)=>{
                                    return(
                                      <option value={cat_item?.id} selected={cat_item?.id === item?.mail_category?.id ? true : false}>{cat_item?.name}</option>
                                    )
                                  })}
                                </Form.Select>
                              </td>
                              <td className="text-center">
                                <a href="javascript:void(0)" onClick={()=>{delhandleShow();setDeleteRecodId(item?.id)}}>
                                  <img src={Remove} className="TableIcon" alt="Img" />
                                </a>
                              </td>
                            </tr>

                            <tr>
                              <td colspan="11"></td>
                            </tr>
                          </>
                        )
                      })):(
                        <tr className="odd_tr">
                          {/* <td colSpan={7} style={{textAlign:'center'}}>No Envelop Documents Found</td> */}
                          <td colSpan={7} style={{textAlign:'center'}}>{languageJson?.no_envelop_documents_found}</td>
                        </tr>
                    )}
                  </tbody>
                </Table>

                {/* <div className="TablePagination">
                  <Pagination>
                    <Pagination.Item active>{1}</Pagination.Item>
                    <Pagination.Item>{2}</Pagination.Item>
                    <Pagination.Item>{3}</Pagination.Item>
                    <Pagination.Ellipsis />
                    <Pagination.Item>{8}</Pagination.Item>
                  </Pagination>
                </div> */}

                {allEnvArr?.length > 0 ? (
                  <div className="TablePagination">
                      {/* Pagination controls start */}
                      <Pagination>
                        {currentPage >= 1 && (
                          <Pagination.Prev
                            disabled={currentPage === 1}
                            onClick={() => handlePageChange(currentPage - 1)}
                            style={{ cursor: currentPage === 1 ? 'not-allowed' : 'pointer' }}
                          />
                        )}
                        
                        {getPageNumbers().map((pageNumber, index) => (
                          <React.Fragment key={index}>
                            {pageNumber.key === 'ellipsis' ? (
                              <Pagination.Ellipsis key={index} />
                            ) : (
                              <Pagination.Item
                                key={index}
                                active={currentPage === pageNumber}
                                onClick={() => handlePageChange(pageNumber)}
                              >
                                {pageNumber}
                              </Pagination.Item>
                            )}
                          </React.Fragment>
                        ))}

                        {currentPage <= totalPages && (
                          <Pagination.Next
                            disabled={currentPage === totalPages}
                            onClick={() => handlePageChange(currentPage + 1)}
                            style={{ cursor: currentPage === totalPages ? 'not-allowed' : 'pointer' }}
                          />
                        )}
                      </Pagination>


                    {/* Pagination controls end */}

                  </div>
                ):null}

              </div>
            </Col>
          {/* </Row> */}
        {/* </div> */}

        <Modal
          show={show}
          onHide={handleClose}
          backdrop="static"
          keyboard={false}
          centered
          className="CustomModal"
        >
          <Modal.Header closeButton>
            <Modal.Title>Please pay for the scan</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Table bordered>
              <thead>
                <tr>
                  <th>Current Balance:</th>
                  <th>Charges for scan:</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>$573.69</td>
                  <td>$2.00</td>
                </tr>
              </tbody>
            </Table>

            <h3>
              If you want to scan the document then <span>$2.00</span> will be
              deducted from your wallet.
            </h3>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose} className="lftBtn">
              Cancel
            </Button>
            <Button variant="primary" className="rgtBtn">
              Scan Now
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal
          show={payshow}
          onHide={handleClose}
          backdrop="static"
          keyboard={false}
          centered
          className="CustomModal"
        >
          <Modal.Header closeButton>
            <Modal.Title>Please pay for Send</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Table bordered>
              <thead>
                <tr>
                  <th>Current Balance:</th>
                  <th>Charges for scan:</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>$573.69</td>
                  <td>$2.00</td>
                </tr>
              </tbody>
            </Table>

            <h3>
              If you want to items to be sent to you immediately? then{" "}
              <span>$5.00</span> will be deducted from your wallet.
            </h3>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={payhandleClose}
              className="lftBtn"
            >
              No
            </Button>
            <Button
              variant="primary"
              className="rgtBtn"
              onClick={() => {
                addresshandleShow();
                payhandleClose();
              }}
            >
              Yes
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal
          show={addressshow}
          onHide={addresshandleClose}
          backdrop="static"
          keyboard={false}
          centered
          className="CustomModal"
        >
          <Modal.Header closeButton>
            <Modal.Title>
              Do you want this items
              <br /> sent to you?
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <h4>Please select an Address where can sent to this item.</h4>
            <div className="radioGrp">
              <div className="form-check checkFrm">
                <Form.Check
                  id="billingaddress"
                  type="radio"
                  name="address"
                  checked={selectedValue === 1 ? true : false}
                  onClick={() => setSelectedValue(1)}
                />
                <Form.Label className="form-check-label" for="billingaddress">
                  Same as your billing
                  <br /> address
                </Form.Label>
              </div>

              <div className="form-check checkFrm">
                <Form.Check
                  id="newaddress"
                  type="radio"
                  name="address"
                  checked={selectedValue === 2 ? true : false}
                  onClick={() => setSelectedValue(2)}
                />
                <Form.Label className="form-check-label" for="newaddress">
                  New Address
                </Form.Label>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            {selectedValue === 1 ? (
              <Button
                variant="primary"
                className="rgtBtn"
                onClick={() => {
                  thankhandleShow();
                  addresshandleClose();
                }}
              >
                Procced
              </Button>
            ) : (
              <Button variant="primary" className="rgtBtn" href="/NewAddress">
                Procced2
              </Button>
            )}
          </Modal.Footer>
        </Modal>

        <Modal
          show={thankshow}
          onHide={thankhandleClose}
          backdrop="static"
          keyboard={false}
          centered
          className="CustomModal CustomModalTnk"
        >
          <Modal.Header closeButton>
            <Modal.Title></Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <img src={TickBlue} className="TickBlueLogo" alt="logo" />
            <h5>Thank You for requesting</h5>

            <h2>
              Your order is ready for shipment. Please allow 24 hours to track
              your item.
            </h2>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={thankhandleClose}
              className="lftBtn"
            >
              Check Status
            </Button>
            <Button variant="primary" className="rgtBtn" href="/Dashboard">
              Go to dashboard
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal
          show={delshow}
          onHide={delhandleClose}
          backdrop="static"
          keyboard={false}
          centered
          className="CustomModal delCustomModal"
        >
          <Modal.Header closeButton>
            <Modal.Title>Delete</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <h3>
              Do you want this item/mail to be deleted? It will be automatically
              move to the trash for 30 day.
            </h3>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={delhandleClose}
              className="lftBtn"
            >
              No
            </Button>
            <Button variant="primary" className="rgtBtn" onClick={()=>MoveToTrashed()}>
              Yes
            </Button>
          </Modal.Footer>
        </Modal>
      {/* </div> */}
    </>
  );
};

export default EnvelopScan;
