import React, { useState, useEffect } from "react";
import Mainheader from "../../components/mainheader/Mainheader";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Footer from "../../components/footer/Footer";
import "./TaxService.scss";
import Image from "react-bootstrap/Image";
import studentPortalImg from "../../assets/images/StudentImg.jpg";
import { NotificationManager } from "react-notifications";

import {studentPortalData} from "../../api/apiRequest";  
import { useLangContext } from "../../context/languageContext";

const StudentPortal = () => {
  const {hostLanguage} = useLangContext();
  const [contentDetails,setContentDetails] = useState([]);

  // get image link
  // const getImgLink = (img_link) => {
  //   try
  //   {
  //     let base_url = 'https://cleverdomizil.de';
  //     let url = base_url + img_link?.split("httpdocs")[1];
  //     return url
  //   }
  //   catch(error)
  //   {
  //     console.error(error);
  //   }
  // }

  // get page content
  const getAllPageContent = async() => {
    try
    {
      let apiRes = await  studentPortalData(hostLanguage);
      let responseData = await apiRes.json();
      if(responseData.res)
      {
        setContentDetails(responseData.data[0]);
      }
      else
      {
        NotificationManager.error(responseData.msg,'',2000);
      }
    }
    catch(error)
    {
      console.error(error);
    }
  }

  useEffect(()=>{
    getAllPageContent();
  },[hostLanguage]);

  return (
    <div className="WebBody">
      <Mainheader />
      <div className="MainbBody">
        <div className="PricelistBanner MapBanner">
          <Container>
            <Row>
              <Col sm={8}>
                <h3>{contentDetails?.title}</h3>
              </Col>
            </Row>
          </Container>
        </div>
        <div className="PricelistBody">
        <Container>
          <div dangerouslySetInnerHTML={{ __html: contentDetails?.description1}}></div>
          </Container>
          <Col >
            <div style={{width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
              <Image src={studentPortalImg} className="BoxImg" alt="BoxImg" style={{width: '82%', position: 'relative'}}/>
            </div>
          </Col>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default StudentPortal;
