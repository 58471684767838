import React, { useEffect, useState } from "react";
import InnerHeader from "../../components/header/InnerHeader";
import "./Dashboard.scss";
import Form from "react-bootstrap/Form";
import Accordion from "react-bootstrap/Accordion";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import graphic from "../../assets/images/Graphic.svg";
import mailBox from "../../assets/images/MailBox.svg";
import Button from "react-bootstrap/Button";
import cloud from "../../assets/images/CloudIcon.svg";
import phone from "../../assets/images/PhnIcon.svg";
import { useNavigate, Link } from "react-router-dom";
import Table from "react-bootstrap/Table";
import {NotificationManager} from 'react-notifications'; 
import { getPurchasedProductList, getUserDetails, submitTopup, getMailBoxAndScanData, getUserStatus, getPageContent } from "../../api/apiRequest";
import Modal from "react-bootstrap/Modal";
import InputGroup from "react-bootstrap/InputGroup";
import PayPalComponent from "../PaymentPages/PayPalComponent";
import PayPalPayment from "../PaymentPages/PayPalPayment";
import Conditions from "../accept-condition/Conditions";
import { useLangContext } from "../../context/languageContext";

const Dashboard = () => {
  const navigate = useNavigate();
  const {hostLanguage,languageJson} = useLangContext();
  const [userDetails, setUserDetails] = useState();
  const [userData, setLoginData] = useState();
  const [productDetails, setProductDetails] = useState([]);
  const [dashboardData, setDashboardData] = useState(); // collect Mailbox and Envelop details

  const [successMessageTopup, setSuccessMessageTopup] = useState("");
  const [errorMessageTopup, setErrorMessageTopup] = useState("");
  const [checkBoxValue1,setCheckBoxValue1] = useState();
  const [checkBoxValue2,setCheckBoxValue2] = useState();
  
  const [isValid,setIsValid] = useState();
  const [isAddedPrice,setIsAddedPrice] = useState(false);
  const [amount,setAmount] = useState();
  const [payableAmount,setPayableAmount] = useState();
  const [isShow,setIsShow] = useState('1');
  const [errorMessage, setErrorMessage] = useState('');
  const [valCheck,setValCheck] = useState('');
  const [lastStatusData,setLastStatusData] = useState();


  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [show, setShow] = useState(false);
  const [paymentDisabledButton,setPaymentDisabledButton] = useState(false);
  const [contentDetails,setContentDetails] = useState();

  
  const getUserData = async() =>
  {
    try
    {
      let apiRes = await getUserDetails();
      let responseData = await apiRes.json();
      if(responseData)
      { 
        // console.log(responseData.data);
        setUserDetails(responseData.data)
      }
      else
      {
        NotificationManager.error(responseData.msg, '',2000);
      }
    }
    catch(error)
    {
      NotificationManager.error('Something went wrong!', '',2000);
    }
  }

  const PurchasedProductList = async() =>
  {
    try
    {
      let apiRes = await getPurchasedProductList(hostLanguage);
      let responseData = await apiRes.json();
      if(responseData.res)
      {
        console.warn(responseData.data.length);
        // setProductDetails(responseData.data);
        if(responseData.data.length > 0 )
        {
          let tempArr = [];
          responseData.data?.map((item,index)=>{
            item.map((item1,index1)=>{
              tempArr.push(item1)
              
            })
          })
          console.warn(tempArr)
          setProductDetails(tempArr)
        }
        else
        {
          navigate('/LetStarted');
        }
      }
      else
      {
        // NotificationManager.error(responseData.msg, '',2000);
      }
    }
    catch(error)
    {
      NotificationManager.error('Something went wrong!', '',2000);
    }
  }

  // get page content
  const getAllPageContent = async() => {
    try
    {
      let apiRes = await  getPageContent(hostLanguage);
      let responseData = await apiRes.json();
      if(responseData.res)
      {
        setContentDetails(responseData.data);
        // console.warn(responseData.data.header);
      }
      else
      {
        NotificationManager.error(responseData.msg,'',2000);
      }
    }
    catch(error)
    {
      console.error(error);
    }
  }

  // const calculatingVAT = (total_price,vat_percentage) =>{
  //   // let vat = ((parseFloat(total_price)) *(parseFloat(vat_percentage)) / 100);
  //   let vat = ((parseFloat(total_price)) * (parseFloat(vat_percentage)) / (parseFloat(100) + parseFloat(vat_percentage)));
  //   return vat.toFixed(2);
  // }

  // const calculatingPrice = (total_price,vat_percentage) =>{
  //   // VAT = 39,90 x 19 / ( 100 + 19 ) = 6.37   and the Net price is =  39,90 / ( 1+ 19/100 ) = 33,53
  //   let price = ((parseFloat(total_price)) / (parseFloat(1) + ((parseFloat(vat_percentage)) / parseFloat(100))));
  //   return price.toFixed(2);
  // }

  const calculatingVAT = (total_price,vat_percentage) =>{
    // let vat = ((parseFloat(total_price)) *(parseFloat(vat_percentage)) / 100);
    let vat = ((parseFloat(total_price)) * (parseFloat(vat_percentage)) / (parseFloat(100) + parseFloat(vat_percentage)));
    return vat.toFixed(2);
  }
  
  const calculatingPrice = (total_price,vat_percentage) =>{
    // VAT = 39,90 x 19 / ( 100 + 19 ) = 6.37   and the Net price is =  39,90 / ( 1+ 19/100 ) = 33,53
    // let price = ((parseFloat(total_price)) / (parseFloat(1) + ((parseFloat(vat_percentage)) / parseFloat(100))));
    
    // let percent = 39,90 / ( 1+ 19/100 = 1.19 )
    let cal = (vat_percentage / 100);
    let price = parseFloat(total_price / (1 + cal));
  
    return price.toFixed(2);
  }

  const [cartData, setCartData] = useState({
    amount: "",
    card_number: "",
    expiry_date: "",
    name_card: "",
    CVC: "",
  });
  
  const [ErrorsCartData, setErrorsCartData] = useState({
    amount: "",
    card_number: "",
    expiry_date: "",
    name_card: "",
    CVC: "",
  });

  const checkDecimalOrWhole = (num)=> {
    // var result = (n - Math.floor(n)) !== 0; 
    // var val = '';

    // if (result)
    // {
    //   console.log('Number has a decimal place.') ;
    //   // val = 'decimal';
    //   return amount;
    // }
    //  else
    //  {
    //   console.log('It is a whole number.');
    //   // val = 'whole';
    //   return `${amount}.00`;
    // let val = n?.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');

    // console.warn(typeof(val));
    // console.warn(val)
    let val = num.toLocaleString();
    console.log('local'+val);
    return val;
    

    // let val = number?.toFixed(2)?.replace(/\d(?=(\d{3})+\.)/g, '$&,');
    // return val;
  }
    // console.log(val);
    // return val;
 
  // const handleChangeSubmit = e => {
  //   const {name, value} = e.target;
  //   setCartData({...cartData, [name]: value});
  // };

  const handleChangeSubmit = (e) => {
    // const { name, value } = e.target;
    // const formatCardNumber = (cardNumber) => {
    //   return cardNumber.replace(/\D/g, "").replace(/(\d{4})/g, "$1 ").trim();
    // };
    
    // if (name === "expiry_date") {
    //   const numericValue = value.replace(/\D/g, '');
    //   const formattedValue =
    //     numericValue.length >= 2
    //       ? numericValue.substring(0, 2) + '/' + numericValue.substring(2)
    //       : numericValue;
    //   setCartData({ ...cartData, [name]: formattedValue });
    // }else if (name === "CVC") {
    //   const numericValue = value.replace(/\D/g, '');
    //   const formattedValue =
    //     numericValue.length >= 2
    //       ? numericValue.substring(0, 2) + numericValue.substring(2)
    //       : numericValue;
    //   setCartData({ ...cartData, [name]: formattedValue });
    // }else if (name === "amount") {
    //   const numericValue = value.replace(/\D/g, '');
    //   const formattedValue =
    //     numericValue.length >= 2
    //       ? numericValue.substring(0, 2) + numericValue.substring(2)
    //       : numericValue;
    //   setCartData({ ...cartData, [name]: formattedValue });
    // } else if (name === "card_number") {
    //       const numericValue = value.replace(/\D/g, "");
          
    //       const isValidCardNumber = /^\d{4} \d{4} \d{4} \d{4}$/.test(
    //         formatCardNumber(numericValue)
    //       );

    //       if (isValidCardNumber) {
    //         const formattedValue = formatCardNumber(numericValue);
    //         setCartData({ ...cartData, [name]: formattedValue });
    //       } else {
    //         setCartData({ ...cartData, [name]: value });
    //       }
    // }else {
    //   setCartData({ ...cartData, [name]: value });
    // }
  };

 const handleSubmitTopUp = async () => {
  if (validateCardDetails()) {
    try
    {
      let apiRes = await submitTopup(amount);
      let responseData = await apiRes.json();
      if(responseData)
      {
        if(responseData.res==true){
            NotificationManager.success(responseData.msg, '',2000);
            //navigate("/Dashboard");
            console.log(responseData);
            setShow(false);
            getUserData();
          }else{
            NotificationManager.error(responseData.msg, '',2000);
          }
      }
      else
      {
        NotificationManager.error(responseData.msg, '',2000);
      }
    }
    catch(error)
    {
      // NotificationManager.error('Something went wrong!', '',2000);
    }
  }
  }

  const validateCardDetails = () => {
    let isValid = true;
    const errors = {
      amount: "",
      card_number: "",
      expiry_date: "",
      name_card: "",
      CVC: "",
    };
  if (cartData.card_number === "") {
      errors.card_number = "Please enter card number";
      isValid = false;
    }
     if (cartData.amount === "") {
      errors.amount = "Please enter amount";
      isValid = false;
    }
    if (cartData.expiry_date === "") {
      errors.expiry_date = "Please enter expiry date";
      isValid = false;
    }
    
    if (cartData.name_card === "") {
      errors.name_card = "Please enter name of card";
      isValid = false;
    }
  
    if (cartData.CVC === "") {
      errors.CVC = "Please enter CVC";
      isValid = false;
    }
  
  
    setErrorsCartData(errors);
    return isValid;
  };


  const handleRoute = (route) => {
    navigate(route);
  };
  let netTotal = 0;

  // Mail box and Scan data
  const getDashboardData = async() =>
  {
    try
    {
      let apiRes = await getMailBoxAndScanData();
      let responseData = await apiRes.json();
      if(responseData)
      { 
        // console.log(responseData.data);
        setDashboardData(responseData.data)
      }
      else
      {
        NotificationManager.error(responseData.msg, '',2000);
      }
    }
    catch(error)
    {
      NotificationManager.error('Something went wrong!', '',2000);
    }
  }

  // date format
  const dateFormat = (date) => {
    let split_date = date.split('-');
    let final_date = split_date[1] + '.' + split_date[2] + '.' + split_date[0];
    return final_date
  }

  //get user status
const handleUserStatus = async () => {
  try
  {
    let apiRes = await getUserStatus();
    let responseData = await apiRes.json();
    if(responseData.res)
    {
      console.warn(responseData.data)
      setLastStatusData(responseData.data); // last user_status_id
    }
    else
    {
      NotificationManager.error(responseData.msg, '',2000);
    }
  }
  catch(error)
  {
    // NotificationManager.error('Something went wrong!', '',2000);
  }
}

  const handleAmountChange = (e) => {

    // if(Number.isInteger(e.target.value))
    // {
    //   console.log(e.target.value);
    // }
    // else
    // {
    //   console.log(e.target.value);
    // }
    // let value = e.target.value;
    // let total = parseFloat(value) * 100 ;
    //setAmount();
    //setAmount(e.target.value);
    // formatValue = (e.target.value) => {
    //   if (Number.isInteger(e.target.value)) {
    //     return value + '.00';
    //   } else {
    //     return value.toString();
    //   }
    // }
  };

  const handleParentData1 = (e) => {
    setCheckBoxValue1(e);
    console.log(e);
  }

  const handleParentData2 = (e) => {
    setCheckBoxValue2(e);
    console.log(e);
  }

  useEffect(() => {
    //CreateBroadcast();
    //let data = logInDetails();
    let value = JSON.parse(localStorage.getItem("virtualOfficeLoginInfo")); //JSON.parse(secureLocalStorage.getItem('loginInfo'));
    
    // if (value == undefined) {
    //   navigate("/");
    // }
      getUserData();
      PurchasedProductList();
      getDashboardData();
      handleUserStatus();
    
  }, []);

  useEffect(()=>{
    console.warn(amount);
  },[amount])
  
  useEffect(()=>{
    getAllPageContent();
  },[hostLanguage])

  return (
    <div className="lightBlue">
      <InnerHeader />

      <div className="DashboardBox">
        {/* <h2>Hello, {userDetails?.full_name}!</h2> */}
        <h2>{contentDetails?.after_login?.Hello}, {userDetails?.full_name}!</h2>
        {/* <h3>Welcome to your Dashboard</h3> */}
        <h3>{contentDetails?.after_login?.WelcomeToYourDashboard}</h3>
        <Row className="align-items-center MainRow2">
          <Col sm={12} md={12} lg={4}>
            <div className="WhiteBox">
              <img src={graphic} className="graphic" alt="logo" />
            </div>
          </Col>
          <Col sm={12} md={12} lg={4}>
            <Link to="/AllMail">
              <div className="WhiteBox">
                {/* <h3>Mail Box</h3>
                <img src={mailBox} className="MailBoxImg" alt="logo" />  */}
                {/* <h3>Mail Box</h3> */}
                <h3>{contentDetails?.after_login?.MailBox}</h3>
                <div className="MailboxTable">
                  <Table>
                    <thead>
                      <tr>
                        {/* <th>From</th>
                        <th>Type</th>
                        <th>Weight</th>
                        <th>Date</th> */}
                        <th>{contentDetails?.after_login?.From}</th>
                        <th>{contentDetails?.after_login?.Type}</th>
                        <th>{contentDetails?.after_login?.Weight}</th>
                        <th>{contentDetails?.after_login?.Date}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {dashboardData?.mail_box?.length > 0 ? (
                        dashboardData?.mail_box?.map((item,index)=>{
                          return(
                            <tr className="odd_tr">
                              <td>{item?.from}</td>
                              <td>{item?.mail_type?.name}</td>
                              <td>{item?.weight}g</td>
                              <td>{dateFormat(item?.get_date)}</td>
                            </tr>
                          )
                        })
                      ):(
                        <tr>
                          {/* <td colSpan={4} style={{textAlign:'center'}}>No New Mails Found</td> */}
                          <td colSpan={4} style={{textAlign:'center'}}>{contentDetails?.after_login?.NoNewMailsFound}</td>
                        </tr>
                      )}


                      {/* <tr className="odd_tr">
                        <td>Telekom Deutschland Gmbm</td>
                        <td>C5</td>
                        <td>12.00g</td>
                        <td>07.05.2014</td>
                      </tr> */}

                      {/* <tr className="odd_tr">
                        <td>Daimler AG</td>

                        <td>C5</td>
                        <td>12.00g</td>
                        <td>07.05.2014</td>
                      </tr> */}

                    </tbody>
                  </Table>
                </div>
              </div>
            </Link>
          </Col>
          <Col sm={12} md={12} lg={4}>
            <div className="WhiteBox">
              <div className="WalletList">
                <ul>
                  <li>
                    <span>
                      {/* <label>Wallet</label>
                      <p>Your balance</p> */}
                      <label>{contentDetails?.after_login?.Wallet}</label>
                      <p>{contentDetails?.after_login?.YourBalance}</p>
                    </span>
                    <span>
                      <p>{userDetails?.currency} {userDetails?.wallet_balance?userDetails?.wallet_balance:0}</p>
                    </span>
                  </li>
                  {/* <li>
                    <span>
                      <label>Products</label>
                      <p>cleverdomizil Mail</p>
                    </span>
                    <span>
                      <p>$ 9.95</p>
                    </span>
                  </li> */}
                  <li>
                    {/* <span>
                      <Button
                        variant="primary"
                        type="submit"
                        className="payBtn"
                        // href="/Payment"
                        onClick={() => handleRoute("/Payment")}
                      >
                        Pay Now
                      </Button>
                    </span> */}
                    <span>
                      {/* <a href="#"onClick={handleShow}>Top up balance</a><br></br> */}
                      <a href="#"onClick={handleShow}>{contentDetails?.after_login?.TopUpBalance}</a><br></br>
                    </span>
                    {userDetails?.currency_id == 1 && userDetails?.language_id == 1 ?(
                      lastStatusData?.product_id == 1 && lastStatusData?.go_to_step == 3 ? (
                        <span>
                          {/* <Link to={"/YourDetails"}>Go to Clevver Store</Link> */}
                          {/* <Link to={"/SelectProduct"}>Go to Clevver Store</Link> */}
                          <Link to={"/YourDetails"}>{contentDetails?.after_login?.GoToClevverStore}</Link>
                        </span>
                      ):
                      lastStatusData?.product_id == 2 && lastStatusData?.go_to_step == 3 ? (
                        <span>
                          {/* <Link to={"/SelectCountryPhoneNumber"}>Go to Clevver Store</Link> */}
                          {/* <Link to={"/SelectProduct"}>Go to Clevver Store</Link> */}
                          <Link to={"/SelectCountryPhoneNumber"}>{contentDetails?.after_login?.GoToClevverStore}</Link>
                        </span>
                      ):
                      (
                        <span>
                          {/* <Link to={"/SelectProduct"}>Go to Clevver Store</Link> */}
                          <Link to={"/SelectProduct"}>{contentDetails?.after_login?.GoToClevverStore}</Link>
                        </span>
                      )
                    ):(
                    <span>
                      {/* <Link to={"/LetStarted"}>Go to Clevver Store</Link> */}
                      <Link to={"/LetStarted"}>{contentDetails?.after_login?.GoToClevverStore}</Link>
                    </span>
                  )}
                  </li>
                </ul>
              </div>
            </div>
          </Col>
        </Row>

        <Row className="align-items-center MainRow2">
          <Col sm={12} md={12} lg={6}>
            <Link to={"/EnvelopScan"}>
              <div className="WhiteBox">
                {/* <h3>Your Scan/Envelop Documents</h3> */}
                <h3>{contentDetails?.after_login?.YourScanEnvelopDocuments}</h3>
                <div className="MailboxTable">
                  <Table>
                    <thead>
                      <tr>
                        {/* <th>From</th>
                        <th>Type</th>
                        <th>Weight</th>
                        <th>Date</th> */}
                        <th>{contentDetails?.after_login?.From}</th>
                        <th>{contentDetails?.after_login?.Type}</th>
                        <th>{contentDetails?.after_login?.Weight}</th>
                        <th>{contentDetails?.after_login?.Date}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {dashboardData?.envelope?.length > 0 ?(
                        dashboardData?.envelope?.map((item,index)=>{
                          return(
                            <tr className="odd_tr">
                              <td>{item?.from}</td>
                              <td>{item?.mail_type?.name}</td>
                              <td>{item?.weight}g</td>
                              {/* <td>07.05.2014</td> */}
                              <td>{dateFormat(item?.get_date)}</td>
                            </tr>
                          )
                        })
                      ):(
                        <tr>
                          {/* <td colSpan={4} style={{textAlign:'center'}}>No Scan Doucments Found</td> */}
                          <td colSpan={4} style={{textAlign:'center'}}>{contentDetails?.after_login?.NoScanDoucmentsFound}</td>
                        </tr>
                      )}

                      {/* <tr className="odd_tr">
                        <td>Telekom Deutschland Gmbm</td>
                        <td>C5</td>
                        <td>12.00g</td>
                        <td>07.05.2014</td>
                      </tr> */}

                      {/* <tr className="odd_tr">
                        <td>Daimler AG</td>
                        <td>C5</td>
                        <td>12.00g</td>
                        <td>07.05.2014</td>
                      </tr> */}

                    </tbody>
                  </Table>
                </div>
              </div>
            </Link>
          </Col>
          <Col sm={12} md={12} lg={6}>
            <Link to={"/AccountSettings"}>
              <div className="WhiteBox">
                {/* <h3>Account Details</h3> */}
                <h3>{contentDetails?.after_login?.AccountDetails}</h3>

                <div className="DetailsBox">
                  {/* <Row className="DetailsList">
                    <Col sm={6} className="pr-5">
                      <h5>
                        <em>Account ID:</em>
                        C00125577
                      </h5>
                    </Col>
                    <Col sm={6}>
                      <h5>
                        <em>Account Type:</em>
                        Business
                      </h5>
                    </Col>
                  </Row> */}

                  <Row className="DetailsList">
                    <Col sm={6}>
                      <h5>
                        {/* <em>Current Email:</em> */}
                        <em>{contentDetails?.after_login?.CurrentEmail}</em>
                        {userDetails?.email} 
                      </h5>
                    </Col>
                  </Row>
                </div>
              </div>
            </Link>
          </Col>
          

          {productDetails.length > 0 ? (
            productDetails?.map((item,index)=>{
                //netTotal += parseFloat(item.plan.price);
                //console.log("netTotal inside map:", netTotal);
              return(
                item.product_id == 1 ? (
                  <Col sm={12} md={6} lg={4}>
                  {/* <Link to={"/InvoicesPayments"}> */}
                  <Link to={"/CurrentPlan"}>
                    <div className="WhiteBox">
                      <div className="WhiteBoxPrice">
                        <div className="PriceBox">
                          <Row>
                            <Col sm={8}>
                              <div className="iconBox">
                                <img src={cloud} className="iconImg" alt="Img" />
                                <h4>{item.product.product_name}</h4>
                              </div>
                            </Col>
                            <Col sm={4} className="pr-0">
                              <div className="PriceTxt">
                                {/* <p>Price</p> */}
                                <p>{item?.price_text}</p>
                                {/* <h5>€ {calculatingPrice(item?.plan?.price,item?.plan?.vat_charge)}</h5> */}
                                <h5>€ {item?.plan?.price}</h5>
                              </div>
                            </Col>
                          </Row>
                        </div>

                        <div className="PriceBoxBottom">
                          <ul>
                            <li>
                              {/* <label>Contract Period</label> */}
                              <label>{item?.contract_period_text}</label>
                              <span>
                                {" "}
                                {item.contract_period.name} <br /> {item.duration_start} - {item.duration_end}
                              </span>
                            </li>
                            <li>
                              {/* <label>Net price</label> <span>€ {calculatingPrice(item?.plan?.price,item?.plan?.vat_charge)}</span> */}
                              <label>{item?.net_price_text}</label> <span>€ {calculatingPrice(item?.plan?.price,item?.plan?.vat_charge)}</span>
                            </li>
                            <li>
                              {/* <label>VAT {item?.plan?.vat_charge} %</label> */}
                              <label>{item?.vat_text} {item?.plan?.vat_charge} %</label>
                              <span>€ {calculatingVAT(item?.plan?.price, item?.plan?.vat_charge)}</span>
                              {/* <span>€ {item?.plan?.vat_charge}</span> */}
                            </li>
                            <li>
                              <label>{contentDetails?.after_login?.city_level}</label>
                              <span>{item?.city?.name}</span>
                            </li>
                            <li>
                              <label>{contentDetails?.after_login?.country_level}</label>
                              <span>{item?.country?.name}</span>
                            </li>
                          </ul>
                        </div>

                        <div className="DeleteBottom">
                          <h4>{item?.plan?.title}</h4>
                          <h6>
                            {/* <em>Next Renewal Date</em> */}
                            <em>{item?.next_renewal_date_text}</em>
                            <br />
                            {item.duration_end}
                          </h6>
                        </div>
                      </div>
                    </div>
                  </Link>
                  </Col>
                ):(
                  <Col sm={12} md={6} lg={4}>
                  {/* <Link to={"/InvoicesPayments"}> */}
                  <Link to={"/CurrentPlan"}>
                    <div className="WhiteBox">
                      <div className="WhiteBoxPrice">
                        <div className="PriceBox">
                          <Row>
                            <Col sm={8}>
                              <div className="iconBox">
                                <img src={phone} className="iconImg" alt="Img" />
                                <h4>{item.product.product_name}</h4>
                              </div>
                            </Col>
                            <Col sm={4} className="pr-0">
                              <div className="PriceTxt">
                                {/* <p>Price</p> */}
                                <p>{item?.price_text}</p>
                                {/* <h5>€ {calculatingPrice(item?.phone_area?.total,item.phone_area.vat_charge)}</h5> */}
                                <h5>€ {item?.phone_area?.total}</h5>
                              </div>
                            </Col>
                          </Row>
                        </div>
                        {/* <div className="DeleteBottom">
                          <h4 style={{color: '#155c8f'}}>{item.phone_country.country_code} {item.phone_area.area_code} {item.phone.phone_number}</h4>
                        </div> */}
                        <div className="PriceBoxBottom">
                          <ul>
                            <li>
                              {/* <label>Phone Number</label> */}
                              <label>{item?.phone_number_text}</label>
                              <span>{item.phone_country.country_code} {item.phone_area.area_code} {item.phone.phone_number}</span>
                            </li>
                            <li>
                              {/* <label>Contract Period</label> */}
                              <label>{item?.contract_period_text}</label>
                              <span>
                                {" "}
                                {/* {item.contract_period.name} <br /> {item.duration_start} - {item.duration_end} */}
                                {/* Yearly <br /> {item.duration_start} - {item.duration_end} */}
                                {item?.yearly_text} <br /> {item.duration_start} - {item.duration_end}
                              </span>
                            </li>
                            <li>
                              {/* <label>Net price</label> <span>€ {calculatingPrice(item?.phone_area?.total,item.phone_area.vat_charge)}</span> */}
                              <label>{item?.net_price_text}</label> <span>€ {calculatingPrice(item?.phone_area?.total,item.phone_area.vat_charge)}</span>
                            </li>
                            <li>
                              {/* <label>VAT {item.phone_area.vat_charge} %</label> */}
                              <label>{item?.vat_text} {item.phone_area.vat_charge} %</label>
                              <span>€ {calculatingVAT(item?.phone_area?.total,item.phone_area.vat_charge)}</span>
                              {/* <span>€ {item.phone_area.vat_charge}</span> */}
                            </li>
                            <li>
                              <label>{contentDetails?.after_login?.area_level}</label>
                              <span>{item?.phone_area?.area}</span>
                            </li>
                            <li>
                              <label>{contentDetails?.after_login?.country_level}</label>
                              <span>{item?.phone_country?.name}</span>
                            </li>
                          </ul>
                        </div>

                        <div className="DeleteBottom" style={{marginTop:'-21px'}}>
                          <h4>{item?.phone_area?.area_text}</h4>
                          <h6>
                            {/* <em>Next Renewal Date</em> */}
                            <em>{item?.next_renewal_date_text}</em>
                            <br />
                            {item.duration_end}
                          </h6>
                        </div>
                      </div>
                    </div>
                  </Link>
                  </Col>
                )
                
              )        
            })         
          ):(
            // <p>No Record Found</p>
            <p>{languageJson?.no_record_found}</p>
          )}
        </Row>


        <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        className="CustomModal"
      >
        <Modal.Header closeButton>
          <Modal.Title>Top up</Modal.Title>
          {/* <Modal.Title>{contentDetails?.after_login?.Top_up}</Modal.Title> */}
        </Modal.Header>
        <Modal.Body>
          
          <div className="">
            
          <Accordion.Item >
            <Accordion.Header>Credit Card</Accordion.Header>
            {/* <Accordion.Header>{contentDetails?.after_login?.Credit_Card}</Accordion.Header> */}
              <Accordion.Body>
                <div>
                  <Form.Group className="mb-4 text-left">
                    <Form.Label>Amount</Form.Label>
                    {/* <Form.Label>{contentDetails?.after_login?.Amount}</Form.Label> */}
                    {/* <Form.Control
                      type="text"
                      className="card"
                      name="amount"
                      value={amount}
                      onChange={(e)=>{setAmount(e.target.value);setIsAddedPrice(true)}}
                      placeholder="Enter Amount"
                      maxLength={14}
                    /> */}

                      {/* <Form.Control
                        type="text"
                        className="card"
                        name="amount"
                        value={amount}
                        onChange={(e) => {
                          const newValue = e.target.value.replace(/\D/g, '');
                          setAmount(newValue);
                          // setIsAddedPrice(true);
                        }}
                        placeholder="Enter Amount"
                        maxLength={14}
                      /> */}

                      <Form.Control
                        type="text"
                        id='topup_amount'
                        className="card"
                        name="amount"
                        value={amount}
                        onChange={(e) => {
                          let rawValue = e.target.value;
                          let floatValue = parseFloat(rawValue);
                          setAmount(rawValue);
                          setPayableAmount(floatValue.toFixed(2));
                        }}
                        placeholder="Enter Amount"
                        maxLength={14}
                      />
                    </Form.Group>
                  {/* <Form.Group className="mb-4 text-left">
                    <Form.Label>Card number</Form.Label>
                    <Form.Control
                      className="card"
                      name="card_number"
                      value={cartData.card_number}
                      onChange={handleChangeSubmit}
                      placeholder="***************"
                      inputMode="numeric"
                      maxLength={19} 
                    />
                      {ErrorsCartData.card_number && <span className="error-message">{ErrorsCartData.card_number}</span>}
                  </Form.Group>
                  <Row>
                    <Col sm={6}>
                      <Form.Group className="mb-4 text-left">
                        <Form.Label>Expiry date</Form.Label>
                        <Form.Control 
                        name="expiry_date"
                        keyboardType={'numeric'}
                        onChange={handleChangeSubmit}
                        value={cartData.expiry_date}
                        maxLength={5}
                        />
                          {ErrorsCartData.expiry_date && <span className="error-message">{ErrorsCartData.expiry_date}</span>}
                      </Form.Group>
                    </Col>
                    <Col sm={6}>
                      <Form.Group className="mb-4 text-left">
                        <Form.Label>CVC / CVV</Form.Label>
                        <Form.Control
                          type="text"
                          name="CVC"
                          value={cartData.CVC}
                          onChange={handleChangeSubmit}
                          placeholder="Type vcc number"
                          maxLength={3}
                          keyboardType={'numeric'}
                        />
                          {ErrorsCartData.CVC && <span className="error-message">{ErrorsCartData.CVC}</span>}
                      </Form.Group>
                    </Col>
                  </Row>
                  <Form.Group className="mb-4 text-left">
                    <Form.Label>Name on card</Form.Label>
                    <Form.Control
                    name="name_card"
                    value={cartData.name_card}
                    onChange={handleChangeSubmit}
                      type="text"
                      placeholder="Enter name on card"
                      maxLength={50} 
                    />
                      {ErrorsCartData.name_card && <span className="error-message">{ErrorsCartData.name_card}</span>}
                  </Form.Group> */}
                  
                </div>
              </Accordion.Body>
          </Accordion.Item>
          {/* {isAddedPrice ? */}
          {/* {amount?.endsWith(".") ? (
              <Accordion.Item ></Accordion.Item>
            ):( */}


           {/* {amount > 0 && (
              <div className="MainBodyRgtInner">
                <Accordion defaultActiveKey="0">
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>Credit Card/Debit Card</Accordion.Header>
                    <Accordion.Body>
                      <PayPalComponent productInfo={'Top-up'} productPrice = {payableAmount} type={'Top-up'}/>
                    </Accordion.Body>
                  </Accordion.Item>

                  <Accordion.Item eventKey="1">
                    <Accordion.Header>PayPal Payment</Accordion.Header>
                    <Accordion.Body>
                      <PayPalPayment productInfo={'Top-up'} productPrice = {payableAmount} type={'Top-up'} from={'dashboard'}/>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </div>
            )} */}

            <Conditions propsData1 = {handleParentData1} propsData2 = {handleParentData2} type='topup' amount={amount}/>
            {
              checkBoxValue1 && checkBoxValue1 === 'accept_terms' &&
              checkBoxValue2 && checkBoxValue2 === 'accept_contract' ? (
                <>
                  {amount > 0 && (
                    <>
                      <h3>Payment With</h3>
                      <PayPalComponent productInfo={'Top-up'} productPrice = {amount} type={'Top-up'}/>
                      <PayPalPayment productInfo={'Top-up'} productPrice = {payableAmount} type={'Top-up'}/>
                    </>
                  )}
                </>
              ):(
                <></>
              )
            }
           
          {/* )} */}
          
          </div>
        </Modal.Body>

        <Modal.Footer>
          <Button className="lftBtn btn btn-secondary" onClick={()=>{handleClose();setAmount()}}>
            Cancel
          </Button>
          
          {/* <Button className="rgtBtn btn btn-primary" onClick={handleSubmitTopUp}>Submit</Button> */}
         
        </Modal.Footer>
      </Modal>

      {/* <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        centered
        size="lg"
        className="CustomModal45"
      >
        <Modal.Header closeButton>
          <div className="ModalHr">
            <div className="ModalHrLft">
              <h4>Top Up</h4>
            </div>
            <div className="ModalHrRgt">
              <p>Amount in USD</p>
              <h5>$50.00</h5>
            </div>
          </div>
        </Modal.Header>
        <Modal.Body>
          <div>
            <Form.Group className=" text-left">
              <Form.Label>Amount</Form.Label>
            </Form.Group>

            <InputGroup className="mb-4 text-left">
              <Form.Control
                placeholder="Recipient's username"
                aria-label="Recipient's username"
                aria-describedby="basic-addon2"
              />
              <InputGroup.Text className="USD-left">USD</InputGroup.Text>
            </InputGroup>

            <h4 className="cardh4">Select payment method</h4>
            <h5 className="cardh5">Credit Card</h5>
            <Form.Group className="mb-4 text-left">
              <Form.Label>Card number</Form.Label>
              <Form.Control
                className="card"
                type="password"
                placeholder="***************"
              />
            </Form.Group>

            <Row>
              <Col sm={6}>
                <Form.Group className="mb-4 text-left">
                  <Form.Label>Expiry date</Form.Label>
                  <Form.Control type="text" placeholder="Type first name" />
                </Form.Group>
              </Col>
              <Col sm={6}>
                <Form.Group className="mb-4 text-left">
                  <Form.Label>CVC / CVV</Form.Label>
                  <Form.Control type="text" placeholder="Enter number" />
                </Form.Group>
              </Col>
            </Row>
            <Form.Group className="mb-4 text-left">
              <Form.Label>Name on card</Form.Label>
              <Form.Control type="email" placeholder="Enter name on card" />
            </Form.Group>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose} className="lftBtn">
            Cancel
          </Button>
          <Button variant="primary" className="rgtBtn">
            Recharge
          </Button>
        </Modal.Footer>
      </Modal> */}
        
      </div>
    </div>
  );
};

export default Dashboard;
