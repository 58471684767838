import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { NotificationManager } from 'react-notifications';
import { getPageContent } from "../../api/apiRequest";
import { useLangContext } from "../../context/languageContext";

const StateButton = (props) => {
    const navigate = useNavigate();
    const {hostLanguage,languageJson} = useLangContext();
    
    let userData = JSON.parse(localStorage.getItem("virtualOfficeLoginInfo"));
    let to = props.to;
    if(userData != undefined || userData != null){
        to = props.userTo;
    }
    
    const [contentDetails,setContentDetails] = useState();

    const getAllPageContent = async() => {
        try
        {
            let apiRes = await  getPageContent(hostLanguage);
            let responseData = await apiRes.json();
            if(responseData.res)
            {
                setContentDetails(responseData.data);
                // console.warn(responseData.data);
            }
            else
            {
                NotificationManager.error(responseData.msg,'',2000);
            }
        }
        catch(error)
        {
            console.error(error);
        }
    }

    useEffect(()=>{
        getAllPageContent();
    },[hostLanguage])

    return (
        <Link className={props.className} to={to}>{props.text}</Link>
    );
};

export default StateButton;