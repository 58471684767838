import React, { useEffect, useState } from "react";
import Mainheader from "../../components/mainheader/Mainheader";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Footer from "../../components/footer/Footer";
import "./About.scss";
import Image from "react-bootstrap/Image";
import { Link } from "react-router-dom";
import laptopbig from "../../assets/images/laptopLoc.png";

import { aboutPageData, getCounterInBanner, getMailProductCity, getPageContent } from "../../api/apiRequest";
import { NotificationManager } from "react-notifications";
import { useLangContext } from "../../context/languageContext";

const About = () => {
  const { hostLanguage } = useLangContext();
  const [data, setData] = useState([]);
  const [counterDetails, setcounterDetails] = useState([]);
  const [selectedCities, setSelectedCities] = useState([]);
  const [cities, setCities] = useState([]);
  const [contentDetails, setContentDetails] = useState();

  //details of about page
  const fetchData = async () => {
    try {
      let apiRes = await aboutPageData();
      let responseData = await apiRes.json();

      if (responseData.res) {
        setData(responseData.data[0]);
      } else {
        NotificationManager.error(responseData.msg, "", 2000);
      }
    } catch (error) {
      console.error(error);
    }
  };

  // get page content
  const getAllPageContent = async () => {
    try {
      let apiRes = await getPageContent(hostLanguage);
      let responseData = await apiRes.json();
      if (responseData.res) {
        setContentDetails(responseData.data);
      } else {
        console.error(responseData.msg, "", 2000);
      }
    } catch (error) {
      console.error(error);
    }
  };

  // get banner counter information
  const getBannerCounterInfo = async () => {
    try {
      let apiRes = await getCounterInBanner();
      let responseData = await apiRes.json();
      if (responseData.res) {
        setcounterDetails(responseData.data);
      } else {
        NotificationManager.error(responseData.msg, "", 2000);
      }
    } catch (error) {
      console.error(error);
    }
  };

  // get image link
  const getImgLink = (img_link) => {
    try {
      let base_url = "https://cleverdomizil.de";
      let url = base_url + img_link?.split("httpdocs")[1];
      return url;
    } catch (error) {
      console.error(error);
    }
  };

  //Get All Mail Product City
  const fetchMailProductCity = async (product_id) => {
    try {
      let apiRes = await getMailProductCity(product_id);
      let responseData = await apiRes.json();
      if (responseData.res) {
        setCities(responseData.data);
      } else {
        NotificationManager.error(responseData.msg, "", 2000);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchData();
    getAllPageContent();
    getBannerCounterInfo();
    fetchMailProductCity(1);
  }, [hostLanguage]);

  return (
    <div className="WebBody">
      <Mainheader />
      <div className="MainbBody">
        <div className="AboutBannerScetion">
          <div className="BannerScetionBottom">
            <Container className="">
              <Row>
                <Col sm={12}>
                  <div dangerouslySetInnerHTML={{ __html: contentDetails?.aboutUs_page_data?.banner_text }}></div>
                  <h3>{contentDetails?.aboutUs_page_data?.title}</h3>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
        <div className="BannerScetionBottom BannerScetionBottomAbout">
          <Container className="">
            <Row className="justify-content-md-center">
              <Col sm={12} md={3}>
                <div className="BannerScetionBottomBox">
                  <h4>{contentDetails?.banner_below_text[0]}</h4>
                  <p>
                    {counterDetails?.company_foundation}+ {contentDetails?.banner_below_text["last_text"]}
                  </p>
                </div>
              </Col>
              <Col sm={12} md={3}>
                <div className="BannerScetionBottomBox ">
                  <h4>{contentDetails?.banner_below_text[1]}</h4>
                  <p>
                    {counterDetails?.customer_foundation}+ {contentDetails?.banner_below_text["last_text"]}
                  </p>
                </div>
              </Col>
              <Col sm={12} md={3}>
                <div className="BannerScetionBottomBox ">
                  <h4>{contentDetails?.banner_below_text[2]}</h4>
                  <p>
                    {counterDetails?.number_world_wide}+ {contentDetails?.banner_below_text["last_text"]}
                  </p>
                </div>
              </Col>
              <Col sm={12} md={3}>
                <div className="BannerScetionBottomBox last-child">
                  <h4>{contentDetails?.banner_below_text[3]}</h4>
                  <p>
                    {counterDetails?.register_agents}+ {contentDetails?.banner_below_text["last_text"]}
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <div className="InfoScetion">
          <Container className="InfoScetionContainer">
            <Row className="align-items-center">
              <Col sm={6}>
                <div className="InfoScetionLft">
                  <div dangerouslySetInnerHTML={{ __html: contentDetails?.aboutUs_page_data?.description1 }}></div>
                </div>
              </Col>
              <Col sm={6}>
                <div className="InfoScetionRgt">
                  <Image src={laptopbig} className="laptopbig" alt="LaptopBig" fluid />
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <div className="DigitalmailboxScetion">
          <Container>
            <Row>
              <Col sm={12}>
                <div className="DigitalmailboxScetionInner">
                  <Row className="align-items-center">
                    <Col sm={7}>
                      <div
                        className="DigitalmailboxinLft DigitalmailboxinLftp"
                        dangerouslySetInnerHTML={{ __html: contentDetails?.aboutUs_page_data?.description2 }}
                      ></div>
                    </Col>
                    <Col sm={5}>
                      <div className="DigitalmailboxinRgt">
                        <Image src={getImgLink(contentDetails?.aboutUs_page_data?.image1)} className="BoxImg" alt="BoxImg" />
                      </div>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <div className="FourBoxScetion">
          <Container className="">
            <Row>
              <Col sm={3}>
                <div className="FourBoxScetionBox">
                  <span>
                    {" "}
                    <Image src={getImgLink(contentDetails?.aboutUs_page_data?.image2)} className="BannerImg" alt="BannerImg" fluid />
                  </span>

                  <div dangerouslySetInnerHTML={{ __html: contentDetails?.aboutUs_page_data?.description3 }}></div>
                </div>
              </Col>

              <Col sm={3}>
                <div className="FourBoxScetionBox">
                  <span>
                    {" "}
                    <Image src={getImgLink(contentDetails?.aboutUs_page_data?.image3)} className="BannerImg" alt="BannerImg" fluid />
                  </span>

                  <div dangerouslySetInnerHTML={{ __html: contentDetails?.aboutUs_page_data?.description4 }}></div>
                </div>
              </Col>

              <Col sm={3}>
                <div className="FourBoxScetionBox">
                  <span>
                    {" "}
                    <Image src={getImgLink(contentDetails?.aboutUs_page_data?.image4)} className="BannerImg" alt="BannerImg" fluid />
                  </span>

                  <div dangerouslySetInnerHTML={{ __html: contentDetails?.aboutUs_page_data?.description5 }}></div>
                </div>
              </Col>

              <Col sm={3}>
                <div className="FourBoxScetionBox">
                  <span>
                    {" "}
                    <Image src={getImgLink(contentDetails?.aboutUs_page_data?.image5)} className="BannerImg" alt="BannerImg" fluid />
                  </span>

                  <div dangerouslySetInnerHTML={{ __html: contentDetails?.aboutUs_page_data?.description6 }}></div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <div className="AboutInfoScetion">
          <Container className="">
            <Row>
              <Col sm={6}>
                <div className="AboutInfoScetionLft" dangerouslySetInnerHTML={{ __html: contentDetails?.aboutUs_page_data?.description7 }}></div>
              </Col>
              <Col sm={6}>
                <Image src={getImgLink(contentDetails?.aboutUs_page_data?.image6)} className="BoxImg" alt="BoxImg" fluid />
              </Col>
            </Row>
          </Container>
        </div>

        <div className="AboutInfoScetion2">
          <Container className="">
            <Row>
              <Col sm={6}>
                <Image src={getImgLink(contentDetails?.aboutUs_page_data?.image7)} className="BoxImg" alt="BoxImg" fluid />
              </Col>
              <Col sm={6}>
                <div className="AboutInfoScetionLft" dangerouslySetInnerHTML={{ __html: contentDetails?.aboutUs_page_data?.description8 }}></div>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default About;
