import React, { useState, useEffect } from "react";
import Mainheader from "../../components/mainheader/Mainheader";
import Footer from "../../components/footer/Footer";
import StateButton from "../../components/state-button/StateButton";
import "./Location.scss";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import { useParams, Link } from "react-router-dom";
import { NotificationManager } from "react-notifications";
import { FaHome, FaEnvelope, FaList, FaPlane } from "react-icons/fa";

import { getPageContent, getHomeMailCity, getPricingMailContractPlansById, getCountryByCity } from "../../api/apiRequest";
import { useLangContext } from "../../context/languageContext";

const Location = () => {
    const {hostLanguage,languageJson} = useLangContext();
    const [locationData,setLocationData] = useState();
    const [countryData,setCountryData] = useState();
    const [defaultTyped,setDefaultTyped] = useState('month');
    const [planDetails,setPlanDetails] = useState();

    const [contentDetails,setContentDetails] = useState();

    const { id } = useParams();

    // get city
    const getCity = async(city_id) => {
        try
        {
            let apiRes = await getHomeMailCity(city_id);
            let responseData = await apiRes.json();
            if(responseData.length)
            {
                const dataArr = Object.values(responseData).filter(obj => typeof obj === 'object');
                setLocationData(dataArr[0])
            }
            else
            {
                NotificationManager.error(responseData.msg,'',2000);
            }
        }
        catch(error)
        {
            // NotificationManager.error('Something went wrong!', '',2000);
        }
    }

    const getCountry = async(city_id) => {
        try
        {
            let apiRes = await getCountryByCity(city_id);
            let responseData = await apiRes.json();
            if(responseData.length)
            {
                setCountryData(responseData[0]);
            }
            else
            {
                NotificationManager.error(responseData.msg,'',2000);
            }
        }
        catch(error)
        {
            // NotificationManager.error('Something went wrong!', '',2000);
        }
    }


    // get page content
    const getAllPageContent = async() => {
        try
        {
            let apiRes = await  getPageContent(hostLanguage);
            let responseData = await apiRes.json();
            if(responseData.res)
            {
                setContentDetails(responseData.data);
                // console.warn(responseData.data);
            }
            else
            {
                NotificationManager.error(responseData.msg,'',2000);
            }
        }
        catch(error)
        {
            console.error(error);
        }
    }

    const handleCityData = async(select_type) => {
        try
        {
            console.log('Selected Country id: ');
            console.log(countryData);
            console.log('Selected City id: ' + id);
            console.log('Selected Type: ' + select_type);
            
            let apiRes = await getPricingMailContractPlansById(countryData.id, id, select_type, hostLanguage);
            let responseData = await apiRes.json();
            if(responseData.res)
            {
                setPlanDetails(responseData.data);
            }
            else
            {
                NotificationManager.error(responseData.msg,'',2000);
            }
        }
        catch(error)
        {
            // NotificationManager.error('Something went wrong!', '',2000);
        }
    }

    useEffect(()=>{
        getAllPageContent();
        getCity(id);
    },[hostLanguage])

    useEffect(()=>{
        getCountry(id);
        console.log(locationData);
    },[locationData])

    useEffect(()=>{
        handleCityData(defaultTyped);
    },[countryData])
    
    const iconSize = 50;

    return (
        <div className="WebBody LocationBody">
            <Mainheader />
            <div className="MainbBody">
                <div className="PricelistBanner MapBanner">
                    <div class="container">
                        <div class="row">
                            <div class="col-sm-8">
                                <div>
                                    <h3>{languageJson?.location_detail_title} {locationData?.name}</h3>
                                    <p>{languageJson?.location_detail_subtitle}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="PricelistBody">
                    <div class="container">

                        <div class="imagePreview text-center">
                            <Row bsPrefix="row image">
                                <Col md={6}  p={0}>
                                    <img src={locationData?.image64} alt={locationData?.name}/>
                                </Col>
                                <Col>
                                    <h3>{languageJson?.location_detail_img_title}</h3>
                                    <p>{languageJson?.location_detail_img_subtitle}</p>                                    
                                    <StateButton className="BtnGrpLoc" text={contentDetails?.pricing_mail_box_page_data?.BookNowButton} to='/register' userTo='/LetStarted'/>
                                </Col>
                            </Row>
                        </div>

                        {locationData && (
                            <div id="pricing">
                                <div className="radiobtncenter">
                                <div className="radioGrp">
                                    <div className="form-check checkFrm">
                                    <Form.Check id='Month' type="radio" name="pricing" defaultChecked={defaultTyped} value="month" onChange={(e)=>{handleCityData(e.target.value);setDefaultTyped(e.target.value)}} />
                                        <Form.Label className="form-check-label" for='Month'>
                                        {/* Monatlich */}
                                        {contentDetails?.pricing_mail_box_page_data?.tab_1_monthly_radio_text}
                                        </Form.Label>
                                    <Form.Check id='Year' type="radio" name="pricing"  value="year" onChange={(e)=>{handleCityData(e.target.value);setDefaultTyped(e.target.value)}}/>
                                        <Form.Label className="form-check-label" for='Year'>
                                        {/* Jährlich */}
                                        {contentDetails?.pricing_mail_box_page_data?.tab_1_yearly_radio_text}
                                        </Form.Label>

                                    </div>
                                </div>
                                </div>
                                <div className="radiobtncenter">
                                
                                </div>
                            </div>
                        )}

                        {countryData!==undefined && id!==undefined && (
                            <div className="PlanScetion">
                                <Container className="">
                                    <Row>
                                    <Col sm={12}>
                                        <div className="ScetionTitel">
                                        {/* <p>CleverDomizil Geschäftsadresse</p>
                                        <h2>CleverDomizil Postfach</h2> */}
                                        <div dangerouslySetInnerHTML={{__html: contentDetails?.pricing_mail_box_page_data?.tab_1_product_heading}}></div>
                                        </div>
                                    </Col>
                                    </Row>
                                    <Row className="align-items-center">
                                    <Col sm={12}>
                                        {/* <div className="PlanComparisonDiv">
                                        <table>
                                            <tr>
                                            <th>&nbsp;</th>
                                            {planDetails?.contract_plan_details?.map((item,index)=>{
                                                return(
                                                <th>
                                                    <h2>{item?.title}</h2>
                                                    <h3>€ {item?.price}</h3>
                                                    <span>per month</span>
                                                    <p>{item?.description}</p>
                                                </th>
                                                )
                                            })}
                                            </tr>
                                            <tr>
                                            <td>
                                                Pricing of activities - see full pricing
                                            </td>
                                            <td>High</td>
                                            <td>Medium</td>
                                            <td>Low</td>
                                            </tr>
                                            <tr>
                                            <td>Included incoming items</td>
                                            <td>5</td>
                                            <td>20</td>
                                            <td>50</td>
                                            </tr>
                                            <tr>
                                            <td>Envelope scanning (front)</td>
                                            <td>2</td>
                                            <td>5</td>
                                            <td>10</td>
                                            </tr>
                                            <tr>
                                            <td>Item content scanning</td>
                                            <td>2</td>
                                            <td>5</td>
                                            <td>10</td>
                                            </tr>
                                            <tr>
                                            <td>Free storage days (letters)</td>
                                            <td>5</td>
                                            <td>10</td>
                                            <td>20</td>
                                            </tr>
                                            <tr>
                                            <td>Free storage days (parcels)</td>
                                            <td>2</td>
                                            <td>5</td>
                                            <td>10</td>
                                            </tr>
                                            <tr>
                                            <td>Trashing items</td>
                                            <td>0</td>
                                            <td>0</td>
                                            <td>0</td>
                                            </tr>
                                            <tr>
                                            <td>Dropbox connection</td>
                                            <td>x</td>
                                            <td>x</td>
                                            <td>x</td>
                                            </tr>
                                            <tr>
                                            <td>Additional incoming items</td>
                                            <td>€ 2.00</td>
                                            <td>€ 1.50</td>
                                            <td>€ 1.00</td>
                                            </tr>
                                            <tr>
                                            <td>Envelope scanning</td>
                                            <td>€ 1.50</td>
                                            <td>€ 1.00</td>
                                            <td>€ 0.50</td>
                                            </tr>
                                            <tr>
                                            <td>Item content scanning</td>
                                            <td>€ 2.50</td>
                                            <td>€ 2.00</td>
                                            <td>€ 1.00</td>
                                            </tr>
                                            <tr>
                                            <td>
                                                Direct forwarding (charge per incident)
                                            </td>
                                            <td>
                                                € 4.00 + 20,00% on top of <br /> postage
                                            </td>
                                            <td>
                                                € 3.00 + 15,00% on top of <br /> postage
                                            </td>
                                            <td>
                                                € 2.00 + 10,00% on top of <br />
                                                postage
                                            </td>
                                            </tr>
                                            <tr>
                                            <td>
                                                Collect forwarding (charge per incident)
                                            </td>
                                            <td>
                                                € 6.00 + 20,00% on top of <br /> postage
                                            </td>
                                            <td>
                                                € 4.00 + 15,00% on top of <br /> postage
                                            </td>
                                            <td>
                                                € 2.00 + 10,00% on top of <br /> postage
                                            </td>
                                            </tr>
                                            <tr>
                                            <td>
                                                Storing items over free period (letters)
                                            </td>
                                            <td>€ 0.15</td>
                                            <td>€ 0.10</td>
                                            <td>€ 0.05</td>
                                            </tr>
                                            <tr>
                                            <td>
                                                Storing items over free period (packages)
                                            </td>
                                            <td>€ 1.00</td>
                                            <td>€ 0.75</td>
                                            <td>€ 0.50</td>
                                            </tr>
                                            <tr>
                                            <td>Scan of additional pages</td>
                                            <td>€ 0.88</td>
                                            <td>€ 0.60</td>
                                            <td>€ 0.40</td>
                                            </tr>
                                            <tr>
                                            <td>Pages included in content scan</td>
                                            <td>4</td>
                                            <td>6</td>
                                            <td>8</td>
                                            </tr>
                                            <tr>
                                            <td></td>
                                            <td>
                                                <Link className="TableBtn" to='/register'>
                                                Get Started
                                                </Link>
                                            </td>
                                            <td>
                                                <Link className="TableBtn" to='/register'>
                                                Get Started
                                                </Link>
                                            </td>
                                            <td>
                                                <Link className="TableBtn" to='/register'>
                                                Get Started
                                                </Link>
                                            </td>
                                            </tr>
                                        </table>
                                        </div> */}
                                        <div className="mainTableDiv">
                                        {planDetails?.contract_plan_details?.length > 0 ? (
                                            <div className="PlanComparisonDiv">
                                            <table>
                                                <tr>
                                                <th className="headcol">&nbsp;</th>
                                                {planDetails?.contract_plan_details?.map((item,index)=>{
                                                    return(
                                                    <th>
                                                        <h2>{item?.title}</h2> € {item?.price}
                                                        <br />{" "}
                                                        
                                                        <p>
                                                        {item?.description}
                                                        </p>
                                                        <span>{item?.purpose}</span>
                                                    </th>
                                                    )
                                                })}
                                                </tr>
                                                {/* <tr>
                                                <td>Pricing of activities - see full pricing</td>
                                                <td>High</td>
                                                <td>Medium</td>
                                                <td>Low</td>
                                                </tr> */}
                                                <tr>
                                                {/* <td className="headcol">Enthalten sind eingehende Elemente</td> */}
                                                <td className="headcol">{contentDetails?.pricing_mail_box_page_data?.tab_1_table_text_1}</td>
                                                {planDetails?.contract_plan_details?.map((item,index)=>{
                                                    return(
                                                    <td>
                                                    {item?.included_incoming_items === null ? 'X' : item?.included_incoming_items}
                                                    </td>
                                                    )
                                                })}
                                                
                                                </tr>

                                                <tr>
                                                {/* <td className="headcol">Umschlagscannen (Vorderseite)</td> */}
                                                <td className="headcol">{contentDetails?.pricing_mail_box_page_data?.tab_1_table_text_2}</td>
                                                {planDetails?.contract_plan_details?.map((item,index)=>{
                                                    return(
                                                    <td>
                                                    {item?.envelope_scanning === null ? 'X' : item?.envelope_scanning}
                                                    </td>
                                                    )
                                                })}
                                                {/* <td>5</td> */}
                                                {/* <td>10</td> */}
                                                </tr>

                                                <tr>
                                                {/* <td className="headcol">Inhaltsscannen von Elementen</td> */}
                                                <td className="headcol">{contentDetails?.pricing_mail_box_page_data?.tab_1_table_text_3}</td>
                                                {planDetails?.contract_plan_details?.map((item,index)=>{
                                                    return(
                                                    <td>
                                                    {item?.item_content_scanning === null ? 'X' : item?.item_content_scanning}
                                                    </td>
                                                    )
                                                })}
                                                {/* <td>5</td> */}
                                                {/* <td>10</td> */}
                                                </tr>

                                                <tr>
                                                {/* <td className="headcol">Kostenlose Lagerungstage (Briefe)</td> */}
                                                <td className="headcol">{contentDetails?.pricing_mail_box_page_data?.tab_1_table_text_4}</td>
                                                {planDetails?.contract_plan_details?.map((item,index)=>{
                                                    return(
                                                    <td>
                                                    {item?.free_storage_days_letters === null ? 'X' : item?.free_storage_days_letters}
                                                    </td>
                                                    )
                                                })}
                                                {/* <td>5</td>
                                                <td>10</td>
                                                <td>20</td> */}
                                                </tr>

                                                <tr>
                                                {/* <td className="headcol">Kostenlose Lagerungstage (Pakete)</td> */}
                                                <td className="headcol">{contentDetails?.pricing_mail_box_page_data?.tab_1_table_text_5}</td>
                                                {planDetails?.contract_plan_details?.map((item,index)=>{
                                                    return(
                                                    <td>{item?.free_storage_days_parcels === null ? 'X' : item?.free_storage_days_parcels}</td>
                                                    )
                                                })}
                                                {/* <td>2</td>
                                                <td>5</td>
                                                <td>10</td> */}
                                                </tr>

                                                <tr>
                                                {/* <td className="headcol">Elemente entsorgen</td> */}
                                                <td className="headcol">{contentDetails?.pricing_mail_box_page_data?.tab_1_table_text_6}</td>
                                                {planDetails?.contract_plan_details?.map((item,index)=>{
                                                    return(
                                                    <td>
                                                    {item?.trashing_items === null ? 'X' : item?.trashing_items}
                                                    </td>
                                                    )
                                                })}
                                                {/* <td>0</td>
                                                <td>0</td>
                                                <td>0</td> */}
                                                </tr>

                                                <tr>
                                                {/* <td className="headcol">Dropbox-Verbindung</td> */}
                                                <td className="headcol">{contentDetails?.pricing_mail_box_page_data?.tab_1_table_text_7}</td>
                                                {planDetails?.contract_plan_details?.map((item,index)=>{
                                                    return(
                                                    <td>
                                                    {item?.dropbox_connection === null ? 'X' : item?.dropbox_connection}
                                                        
                                                    </td>
                                                    )
                                                })}
                                                {/* <td>x</td>
                                                <td>x</td>
                                                <td>x</td> */}
                                                </tr>

                                                <tr>
                                                {/* <td className="headcol">Zusätzliche eingehende Elemente</td> */}
                                                <td className="headcol">{contentDetails?.pricing_mail_box_page_data?.tab_1_table_text_8}</td>
                                                {planDetails?.contract_plan_details?.map((item,index)=>{
                                                    return(
                                                    <td>€ 
                                                    {item?.additional_incoming_items === null ? '0.00' : item?.additional_incoming_items}
                                                    </td>
                                                    )
                                                })}

                                                {/* <td>€ 2.00</td>
                                                <td>€ 1.50</td>
                                                <td>€ 1.00</td> */}
                                                </tr>

                                                <tr>
                                                {/* <td className="headcol">Umschlagscannen</td> */}
                                                <td className="headcol">{contentDetails?.pricing_mail_box_page_data?.tab_1_table_text_9}</td>
                                                {planDetails?.contract_plan_details?.map((item,index)=>{
                                                    return(
                                                    <td>€ 
                                                    {item?.envelope_scanning === null ? '0.00' : item?.envelope_scanning}
                                                    </td>
                                                    )
                                                })}

                                                {/* <td>€ 1.50</td>
                                                <td>€ 1.00</td>
                                                <td>€ 0.50</td> */}
                                                </tr>

                                                <tr>
                                                {/* <td className="headcol">Inhaltsscannen von Elementen</td> */}
                                                <td className="headcol">{contentDetails?.pricing_mail_box_page_data?.tab_1_table_text_10}</td>
                                                {planDetails?.contract_plan_details?.map((item,index)=>{
                                                    return(
                                                    <td>€ 
                                                    {item?.item_content_scanning === null ? '0.00' : item?.item_content_scanning}
                                                    </td>
                                                    )
                                                })}
                                                {/* <td>€ 2.50</td>
                                                <td>€ 2.00</td>
                                                <td>€ 1.00</td> */}
                                                </tr>

                                                <tr>
                                                {/* <td className="headcol">Direkte Weiterleitung (Kosten pro Vorfall)</td> */}
                                                <td className="headcol">{contentDetails?.pricing_mail_box_page_data?.tab_1_table_text_11}</td>
                                                {planDetails?.contract_plan_details?.map((item,index)=>{
                                                    return(
                                                    // <td>€ {item?.direct_forwarding_charge_per_incident == null ? '0.00' : item?.direct_forwarding_charge_per_incident} Zusätzlich zu <br /> Porto</td>
                                                    <td>€ {item?.direct_forwarding_charge_per_incident == null ? '0.00' : item?.direct_forwarding_charge_per_incident} {contentDetails?.pricing_mail_box_page_data?.tab_1_table_text_11_after_value_text}</td>
                                                    )
                                                })}
                                                {/* <td>
                                                    € 4.00 + 20,00% on top of <br /> postage
                                                </td>
                                                <td>
                                                    € 3.00 + 15,00% on top of <br /> postage
                                                </td>
                                                <td>
                                                    € 2.00 + 10,00% on top of <br />
                                                    postage
                                                </td> */}
                                                </tr>

                                                <tr>
                                                {/* <td className="headcol">Sammelweiterleitung (Kosten pro Vorfall)</td> */}
                                                <td className="headcol">{contentDetails?.pricing_mail_box_page_data?.tab_1_table_text_12}</td>
                                                {planDetails?.contract_plan_details?.map((item,index)=>{
                                                    return(
                                                    // <td>€ {item?.collect_forwarding_charge_per_incident == null ? '0.00' : item?.collect_forwarding_charge_per_incident} Zusätzlich zu <br /> Porto</td>
                                                    <td>€ {item?.collect_forwarding_charge_per_incident == null ? '0.00' : item?.collect_forwarding_charge_per_incident} {contentDetails?.pricing_mail_box_page_data?.tab_1_table_text_12_after_value_text}</td>
                                                    )
                                                })}
                                                {/* <td>
                                                    € 6.00 + 20,00% on top of <br /> postage
                                                </td>
                                                <td>
                                                    € 4.00 + 15,00% on top of <br /> postage
                                                </td>
                                                <td>
                                                    € 2.00 + 10,00% on top of <br /> postage
                                                </td> */}
                                                </tr>

                                                <tr>
                                                {/* <td className="headcol">Lagerung von Elementen über die kostenlosen Tage hinaus (Briefe)</td> */}
                                                <td className="headcol">{contentDetails?.pricing_mail_box_page_data?.tab_1_table_text_13}</td>
                                                {planDetails?.contract_plan_details?.map((item,index)=>{
                                                    return(
                                                    <td>€ 
                                                    {item?.storing_items_over_free_period_letters === null ? '0.00' : item?.storing_items_over_free_period_letters}
                                                    </td>
                                                    )
                                                })}
                                                {/* <td>€ 0.15</td>
                                                <td>€ 0.10</td>
                                                <td>€ 0.05</td> */}
                                                </tr>

                                                <tr>
                                                {/* <td className="headcol">Lagerung von Elementen über die kostenlosen Tage hinaus (Pakete)</td> */}
                                                <td className="headcol">{contentDetails?.pricing_mail_box_page_data?.tab_1_table_text_14}</td>
                                                {planDetails?.contract_plan_details?.map((item,index)=>{
                                                    return(
                                                    <td>€ 
                                                    {item?.storing_items_over_free_period_packages === null ? '0.00' : item?.storing_items_over_free_period_packages}
                                                    </td>
                                                    )
                                                })}
                                                {/* <td>€ 1.00</td>
                                                <td>€ 0.75</td>
                                                <td>€ 0.50</td> */}
                                                </tr>

                                                <tr>
                                                {/* <td className="headcol">Scannen zusätzlicher Seiten</td> */}
                                                <td className="headcol">{contentDetails?.pricing_mail_box_page_data?.tab_1_table_text_15}</td>
                                                {/* <td>€ 0.88</td>
                                                <td>€ 0.60</td>
                                                <td>€ 0.40</td> */}
                                                {planDetails?.contract_plan_details?.map((item,index)=>{
                                                    return(
                                                    <td>€ 
                                                    {item?.scan_of_additional_pages === null ? '0.00' : item?.scan_of_additional_pages}
                                                    </td>
                                                    )
                                                })}
                                                </tr>

                                                <tr>
                                                {/* <td className="headcol">Seiten, die im Inhalts-Scan enthalten sind</td> */}
                                                <td className="headcol">{contentDetails?.pricing_mail_box_page_data?.tab_1_table_text_16}</td>
                                                {planDetails?.contract_plan_details?.map((item,index)=>{
                                                    return(
                                                    <td>
                                                    {item?.pages_included_in_content_scan === null ? 'X' : item?.pages_included_in_content_scan}
                                                    </td>
                                                    )
                                                })}
                                                {/* <td>4</td>
                                                <td>6</td>
                                                <td>8</td> */}
                                                </tr>
                                                <tr>
                                                    <td></td>
                                                    {planDetails?.contract_plan_details?.map((item,index)=>{
                                                    return(
                                                        <td>
                                                            <StateButton className="BtnGrpLoc" text={contentDetails?.pricing_mail_box_page_data?.BookNowButton} to='/register' userTo='/LetStarted'/>
                                                        </td>
                                                        )
                                                    })}
                                                </tr>
                                            </table>
                                            </div>
                                        ):(
                                            // <div className="PlanComparisonDiv" style={{textAlign:'center'}}>No Plan Available</div>
                                            <div className="PlanComparisonDiv" style={{textAlign:'center'}}>{languageJson?.noPlanAvailable}</div>
                                        )}
                                        </div>
                                    </Col>
                                    </Row>
                                </Container>
                            </div>
                        )}
                        
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default Location;