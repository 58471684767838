import React from "react";
import "./Location.scss";

import "leaflet/dist/leaflet.css";

import WhiteLogo from "../../assets/images/WhiteLogo.png";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Link } from "react-router-dom";
import { useLangContext,languageJson } from "../../context/languageContext";

import { MapContainer, TileLayer, Marker, useMap } from 'react-leaflet'
import MarkerIcon from "./MarkerIcon";

const Location = (props) => {
    const {hostLanguage,languageJson} = useLangContext();

    if(props.position != undefined && props.position != null && props.position){
        return (
            <div class="LocationGrid">
                <Row>
                    <Col sm={12} md={6}>
                        <div class="image">
                            <img src={props.image == ''? WhiteLogo : props.image} />
                        </div>
                    </Col>
                    <Col sm={12} md={6}>
                        <div class="description">
                            <h3>{languageJson?.location_title}<br/><strong>{props.city}</strong></h3>
                            <p>{languageJson?.location_description}</p>
                            <Link to ={`/Location/${props.id}`}>{languageJson?.location_button}{props.city}</Link>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col sm={12}>
                        <MapContainer center={props.position} zoom={13} scrollWheelZoom={false}>
                            <TileLayer
                                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                            />
                            <Marker position={props.position} icon={ MarkerIcon }></Marker>
                        </MapContainer>
                    </Col>
                </Row>
            </div>
        );
    }else{
        return ( 
            <div class="LocationGrid">
                <Row>
                    <Col sm={12} md={6}>
                        <div class="image">
                            <img src={props.image == ''? WhiteLogo : props.image} />
                        </div>
                    </Col>
                    <Col sm={12} md={6}>
                        <div class="description">
                            <h3>{languageJson?.location_title}<br/><strong>{props.city}</strong></h3>
                            <p>{languageJson?.location_description}</p>
                            <Link to ={`/Location/${props.id}`}>{languageJson?.location_button}{props.city}</Link>
                        </div>
                    </Col>
                </Row>
            </div>
        );
    }
};

export default Location;
