import React, { useState, useEffect } from "react";
import { Navigate, Outlet } from "react-router-dom";

const Auth = ({ allowedRoles }) => {
  const [userRole] = useState(
    JSON.parse(localStorage.getItem("virtualOfficeLoginInfo"))?.role != null ? JSON.parse(localStorage.getItem("virtualOfficeLoginInfo"))?.role : null
  );

  return allowedRoles.find((role) => role.includes(userRole)) ? <Outlet /> : <Navigate to="/" />;
};

export default Auth;
