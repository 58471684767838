import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Button from "react-bootstrap/Button";
import "./InvoiceComponent.scss";
import { useLangContext } from "../../context/languageContext";
import { submitStepFour, notPurchasedProductInvoice, sendInvoiceViaMail, getUserDetails, getUserBillingDetails, getCountry } from '../../api/apiRequest';
import { NotificationManager } from 'react-notifications';

const InvoiceComponent = (props) => {
    const {productInfo,productPrice,type,scanData} = props;
    const {hostLanguage,languageJson} = useLangContext();
    const [userDetails, setUserDetails] = useState();
    const [billingDetails, setBillingDetails] = useState();
    const [countryData,setCountryData] = useState();
    const navigate = useNavigate();

    const getUserData = async() =>
    {
        try
        {
            let apiRes = await getUserDetails();
            let responseData = await apiRes.json();
            if(responseData)
            { 
                // console.log(responseData.data);
                setUserDetails(responseData.data)
            }
            else
            {
                NotificationManager.error(responseData.msg, '',2000);
            }
        }
        catch(error)
        {
            NotificationManager.error('Something went wrong!', '',2000);
        }
    }

    const getBillingDetails = async() =>
        {
          try
          {
            let apiRes = await getUserBillingDetails();
            let responseData = await apiRes.json();
            if(responseData)
            { 
              // console.log(responseData.data);
              setBillingDetails(responseData.data[0])
            }
            else
            {
              NotificationManager.error(responseData.msg, '',2000);
            }
          }
          catch(error)
          {
            NotificationManager.error('Something went wrong!', '',2000);
          }
    }

    const getCountryList = async() => {
        let apiRes = await getCountry();
        let responseData = await apiRes.json();
        if(responseData)
        {
            let index = -1;
            let val = billingDetails?.country_id;
            let data = responseData.data.find(function(item, i){if(item.id === val){index = i;return i;}});
            setCountryData(data);
        }
        else
        {
            NotificationManager.error(responseData.msg, '',2000);
        }
    }

    const convert_date_format = (date) => {
        const date_format = new Date(date);
        const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
        const dateString = date_format.toLocaleDateString('en-GB', options).split('/').reverse().join('-');
        return dateString;
    }

    const generateRandomString = (length) => {
        let result = '';
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        const charactersLength = characters.length;
        for ( let i = 0; i < length; i++ ) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
    }

    const createOrder = async (data, actions) => {
        const invoiceId = generateRandomString(10);

        let values = {
            transaction_id: invoiceId,
            transaction_type:'Invoice',
            currency_type: 'EUR',
            // payment_for: type === 'purchase-product' ? productInfo : '' ,
            // amount: data.purchase_units[0].amount.value,
            payer_firstname: billingDetails?.first_name,
            payer_lastname: billingDetails?.last_name,
            payer_email: userDetails?.email,
            payer_country_code: countryData?.shortname,
            payment_time: convert_date_format(new Date()),
            // payment_type: 1
        }
        // let apiRes = await paymentInvoice(values);
        let apiRes = await notPurchasedProductInvoice(values);
        let responseData = await apiRes.json();
    
        if(responseData.res)
        {
            let apiRes1 = await submitStepFour();
            let responseData1 = await apiRes1.json();
            if(responseData1.res)
            {
            NotificationManager.success(responseData1.msg, '',2000);
            // setShow(false);
            // navigate("/Dashboard");
            navigate('/InvoicesPayments');
            
            let apiRes2 = await sendInvoiceViaMail(invoiceId);
            let responseData2 = await apiRes2.json();
            if(responseData2.res)
            {
                console.log('Mail Send.');
            }
            else
            {
                console.log('Mail Not Send.');
            }
            }
            else
            {
            NotificationManager.error(responseData1.msg, '',2000);
            }
            // }
        }
        else
        {
            console.error('Error in purchase-product section');
        }
    };

    useEffect(()=>{
        getUserData();
    },[])
    useEffect(()=>{
        getBillingDetails();
    },[userDetails])
    useEffect(()=>{
        getCountryList();
    },[billingDetails])

    return (
        <div class="InvoiceComponent">
            <Button onClick={createOrder}>{languageJson?.invoice}</Button>
        </div>
    );
};
export default InvoiceComponent;