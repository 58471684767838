import React, { useEffect, useState } from "react";
import Header from "../../components/header/Header";
import "./LetStarted.scss";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Step from "../../components/step/Step";
import Form from "react-bootstrap/Form";
import euro from "../../assets/images/Euro.svg";
import chf from "../../assets/images/chf.svg";
import dollor from "../../assets/images/Dollor.svg";
import Button from "react-bootstrap/Button";
import { HiArrowNarrowRight } from "react-icons/hi";
import { useNavigate, Link } from "react-router-dom";
import  axios  from "axios";
import { API_BASE_URL } from "../../app_url";
import {NotificationManager} from 'react-notifications'; 


import { getUserDetails, getCurrency, getLanguages, completeStep1, getPurchasedProductList, getPageContent } from "../../api/apiRequest";
import { useLangContext } from "../../context/languageContext";

const LetStarted = () => {
  const navigate = useNavigate();
  const {hostLanguage,languageJson} = useLangContext();
  const [showAllLanguages,setShowAllLanguages] = useState([]);
  const [showAllCurrencies,setShowAllCurrencies] = useState([]);
  const [selectedLanguage,setSelectedLanguage] = useState();
  const [selectedCurrency,setSelectedCurrency] = useState();
  const [showError,setShowError] = useState(false);
  const [contentDetails,setContentDetails] = useState();

  const [loginData, setLoginData] = useState();
  const [userDetails, setUserDetails] = useState();
  // let errors ={
  //   language: 'Please select a language',
  //   currency: 'Please select a currency'
  // }

  useEffect(() => {
    //CreateBroadcast();
    //let data = logInDetails();
    let value = JSON.parse(localStorage.getItem("virtualOfficeLoginInfo")); //JSON.parse(secureLocalStorage.getItem('loginInfo'));
    console.warn(value)
    getUserData();
    // PurchasedProductList();
  }, []);

  const PurchasedProductList = async() =>
  {
    try
    {
      let apiRes = await getPurchasedProductList();
      let responseData = await apiRes.json();
      if(responseData)
      { console.log(responseData.res);
        //setProductDetails(responseData.data)
        if(responseData.res==true){
          navigate("/Login");
        }
        //LetStarted
      }
      else
      {
        //NotificationManager.error(responseData.msg, '',2000);
      }
    }
    catch(error)
    {
      //NotificationManager.error('Something went wrong!', '',2000);
    }
  }

  // get page content
  const getAllPageContent = async() => {
    try
    {
      let apiRes = await  getPageContent(hostLanguage);
      let responseData = await apiRes.json();
      if(responseData.res)
      {
        setContentDetails(responseData.data);
        // console.warn(responseData.data.header);
      }
      else
      {
        NotificationManager.error(responseData.msg,'',2000);
      }
    }
    catch(error)
    {
      console.error(error);
    }
  }

  //get user details
  const getUserData = async() =>
  {
    try
    {
      let apiRes = await getUserDetails();
      let responseData = await apiRes.json();
      if(responseData)
      { console.log(responseData.data);
        setUserDetails(responseData.data)
      }
      else
      {
        NotificationManager.error(responseData.msg, '',2000);
      }
    }
    catch(error)
    {
      console.error('Something went wrong!', '',2000);
    }
  }


  //getting all languages
  const handleGetLanguages = async() =>
  {
    try
    {
      let apiRes = await getLanguages();
      let responseData = await apiRes.json();
      if(responseData)
      {
        setShowAllLanguages(responseData.data)
      }
      else
      {
        NotificationManager.error(responseData.msg, '',2000);
      }
    }
    catch(error)
    {
      NotificationManager.error('Something went wrong!', '',2000);
    }
  }

  //getting all currencies
  const handleGetCurrencies = async() =>
    {
      try
      {
        let apiRes = await getCurrency();
        let responseData = await apiRes.json();
        if(responseData)
        {
          setShowAllCurrencies(responseData.data)
        }
        else
        {
          NotificationManager.error(responseData.msg, '',2000);
        }
      }
      catch(error)
      {
        // NotificationManager.error('Something went wrong!', '',2000);
      }
  }

  // complete step1
  const handleCompleteStep1 = async() => {
    try
    {
      // if(selectedLanguage == undefined || selectedCurrency == undefined)
      if(selectedCurrency == undefined)
      {
        setShowError(true)
      }
      else
      {
        setShowError(false);
        // let apiRes = await completeStep1(selectedLanguage,selectedCurrency);
        let apiRes = await completeStep1(1,selectedCurrency,hostLanguage);
        let responseData = await apiRes.json();
        console.warn(responseData.res)
        if(responseData.res)
        {
          navigate("/SelectProduct");
        }
        else
        {
          NotificationManager.error(responseData.msg, '',2000);
        }
      }
    }
    catch(error)
    {
      // NotificationManager.error('Something went wrong!', '',2000);
    }
  }

  // const googleTranslateElementInit = () => {
  //   new window.google.translate.TranslateElement(
  //     {
  //       pageLanguage: "en",
  //       autoDisplay: false
  //     },
  //     "google_translate_element"
  //   );
  // };
  
  useEffect(()=>{
    // if((userDetails?.currency_id!='' || userDetails?.language_id!='') && userDetails?.go_to_step == 1)
    // {
    //   navigate('/LetStarted');
    // }
    // else if((userDetails?.currency_id!='' || userDetails?.language_id!='') && userDetails?.go_to_step == 2)
    // {
    //   navigate('/SelectProduct');
    // }
    // else if((userDetails?.currency_id!='' || userDetails?.language_id!='') && userDetails?.go_to_step == 3)
    // {
    //   navigate('/YourDetails');
    // }
    // else if((userDetails?.currency_id!='' || userDetails?.language_id!='') && userDetails?.go_to_step == 4)
    // {
    //   navigate('/Payment');
    // }
    // else
    // {
    //   navigate('/LetStarted');
    // }
    
    handleGetLanguages();
    handleGetCurrencies();
    getAllPageContent();
  // },[userDetails?.go_to_step]);

  console.warn(hostLanguage)
  },[hostLanguage])

  // useEffect(() => {
  //   var addScript = document.createElement("script");
  //   addScript.setAttribute(
  //     "src",
  //     "//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit"
  //   );
  //   document.body.appendChild(addScript);
  //   window.googleTranslateElementInit = googleTranslateElementInit;
  // }, []);

  // useEffect(() => {
  //   const addScript = document.createElement("script");
  //   addScript.src =
  //     "//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit";
  //   document.body.appendChild(addScript);
  //   window.googleTranslateElementInit = googleTranslateElementInit;
  // }, []);

  // useEffect(() => {
  //   // Load Google Translate script dynamically
  //   const script = document.createElement('script');
  //   script.src = '//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit';
  //   script.async = true; // Set async attribute to true
  //   document.body.appendChild(script);

  //   // Initialize Google Translate
  //   window.googleTranslateElementInit = () => {
  //     new window.google.translate.TranslateElement({ pageLanguage: 'en' }, 'google_translate_element');
  //   };

  //   return () => {
  //     // Clean up function
  //     document.body.removeChild(script);
  //     delete window.googleTranslateElementInit;
  //   };
  // }, []);

  // useEffect(() => {
  //   // Load Google Translate script dynamically
  //   const script = document.createElement('script');
  //   script.src = '//translate.google.com/translate_a/element.js';
  //   script.async = true;

  //   script.addEventListener('load', () => {
  //     // Initialize Google Translate
  //     if (window.google.translate.TranslateElement) {
  //       new window.google.translate.TranslateElement({ pageLanguage: 'en' }, 'google_translate_element');
  //     } else {
  //       console.error('Failed to load Google Translate widget.');
  //     }
  //   });

  //   document.body.appendChild(script);

  //   return () => {
  //     // Clean up function
  //     if (script.parentNode) {
  //       script.parentNode.removeChild(script);
  //     }
  //   };
  // }, []);

  // useEffect(() => {
  //   const initializeGoogleTranslate = () => {
  //     // Check if google.translate.TranslateElement is available
  //     if (window.google && window.google.translate && window.google.translate.TranslateElement) {
  //       // Initialize Google Translate
  //       new window.google.translate.TranslateElement({ pageLanguage: 'en' }, 'google_translate_element');
  //     } else {
  //       console.error('Google Translate widget initialization failed: TranslateElement not available.');
  //     }
  //   };

  //   // Wait for DOM content to load before initializing Google Translate
  //   document.addEventListener('DOMContentLoaded', initializeGoogleTranslate);

  //   return () => {
  //     // Clean up event listener
  //     document.removeEventListener('DOMContentLoaded', initializeGoogleTranslate);
  //   };
  // }, []);

  // useEffect(() => {
  //   const addScript = document.createElement("script");
  //   addScript.src =
  //     "//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit";
  //   document.body.appendChild(addScript);
  //   window.googleTranslateElementInit = googleTranslateElementInit;
  // }, []);

  // const googleTranslateElementInit = () => {
  //   new window.google.translate.TranslateElement(
  //     {
  //       pageLanguage: "en",
  //       autoDisplay: false,
  //     },
  //     "google_translate_element"
  //   );
  // };

  // working
  useEffect(() => {
    // Load Google Translate script dynamically
    const script = document.createElement('script');
    script.src = 'https://translate.google.com/translate_a/element.js?cb=googleTranslateElementInit';
    script.async = true; // Set async attribute to true
    script.crossOrigin = "anonymous"
    document.body.appendChild(script);

    // Initialize Google Translate
    window.googleTranslateElementInit = () => {
      new window.google.translate.TranslateElement({ pageLanguage: 'en' }, 'google_translate_element');
    };

    return () => {
      // Clean up function
      document.body.removeChild(script);
      delete window.googleTranslateElementInit;
    };
  }, []);

  // working2
  // useEffect(() => {
  //   try {
  //     // Load Google Translate script dynamically
  //     const script = document.createElement('script');
  //     script.src = '//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit';
  //     script.async = true; // Set async attribute to true
  //     script.crossOrigin = 'anonymous'
  //     document.body.appendChild(script);
  
  //     // Initialize Google Translate
  //     window.googleTranslateElementInit = () => {
  //       new window.google.translate.TranslateElement({ pageLanguage: 'en' }, 'google_translate_element');
  //     };
  
  //     return () => {
  //       // Clean up function
  //       if (script && script.parentNode) {
  //         script.parentNode.removeChild(script);
  //       }
  //       if (window.googleTranslateElementInit) {
  //         delete window.googleTranslateElementInit;
  //       }
  //     };
  //   } catch (error) {
  //     // Handle error gracefully
  //     alert('100')
  //     console.error('Error in useEffect:', error);
  //   }
  //   // var liElements = document.querySelectorAll("li[id^='uploadNameSpan']"); // this returns an empty NodeList

  //   // var nonExistentFirstElement = liElements[0]; // this is undefined, there's nothing at the first position

  //   // nonExistentFirstElement.remove();
  // }, []);


  // useEffect(() => {
  //   // Load Google Translate script dynamically
  //   const script = document.createElement('script');
  //   script.src = '//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit';
  //   script.async = true; // Set async attribute to true
  //   document.body.appendChild(script);

  //   // Initialize Google Translate
  //   window.googleTranslateElementInit = () => {
  //     new window.google.translate.TranslateElement({ pageLanguage: 'en' }, 'google_translate_element');
  //   };

  //   return () => {
  //     // Check if script exists and is a child of document.body
  //     if (script && script.parentNode === document.body) {
  //       // Remove script if it exists and is a child of document.body
  //       document.body.removeChild(script);
  //     }
  //     // Clean up other resources if needed
  //     delete window.googleTranslateElementInit;
  //   };
  // }, []);

  // useEffect(() => {
  //   // Create a container div for Google Translate element
  //   const translateContainer = document.createElement('div');
  //   translateContainer.id = 'google_translate_container';
  //   document.body.appendChild(translateContainer);

  //   // Load Google Translate script dynamically
  //   const script = document.createElement('script');
  //   script.src = '//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit';
  //   script.async = true; // Set async attribute to true
  //   translateContainer.appendChild(script);

  //   // Initialize Google Translate
  //   window.googleTranslateElementInit = () => {
  //     new window.google.translate.TranslateElement({ pageLanguage: 'en' }, 'google_translate_element');
  //   };

  //   return () => {
  //     // Clean up function
  //     document.body.removeChild(translateContainer);
  //     delete window.googleTranslateElementInit;
  //   };
  // }, []);

  // useEffect(() => {
  //   const loadGoogleTranslateScript = () => {
  //     const script = document.createElement('script');
  //     script.src = '//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit';
  //     script.async = true;
  //     script.onload = initializeGoogleTranslate;
  //     script.onerror = () => {
  //       console.error('Error loading Google Translate script.');
  //     };
  //     document.body.appendChild(script);
  //   };

  //   const initializeGoogleTranslate = () => {
  //     if (window.google && window.google.translate) {
  //       try {
  //         new window.google.translate.TranslateElement({ pageLanguage: 'en' }, 'google_translate_element');
  //       } catch (error) {
  //         console.error('Error initializing Google Translate:', error);
  //       }
  //     } else {
  //       console.error('Google Translate API not available.');
  //     }
  //   };

  //   // Load Google Translate script dynamically
  //   loadGoogleTranslateScript();

  //   // Clean up function
  //   return () => {
  //     // Remove script element from DOM
  //     const script = document.querySelector('script[src*="translate.google.com"]');
  //     if (script) {
  //       script.parentNode.removeChild(script);
  //     }
  //   };
  // }, []);



// Ensure the Google Translate widget container exists in the HTML
// <div id="google_translate_element"></div>



  return (
    <div className="lightBlue">
      <Header />

      <div className="Hrbottom">
        <div className="container">
          <Row className="align-items-center">
            <Col sm={12} md={5}>
              {/* <h2>Set up your account</h2> */}
              <h2>{contentDetails?.lets_start_page_data?.set_up_your_account}</h2>
            </Col>
            <Col sm={12} md={7}>
              <div class="form-steps">
                <div class="form-steps__step form-steps__step--active">
                  <div class="form-steps__step-circle">
                    <span class="form-steps__step-number">01</span>
                  </div>
                  {/* <span class="form-step__step-name">Let's Started</span> */}
                  <span class="form-step__step-name">{contentDetails?.lets_start_page_data?.lets_started}</span>
                </div>

                <div class="form-steps__step form-steps__step--incomplete">
                  <div class="form-steps__step-circle">
                    <span class="form-steps__step-number">02</span>
                  </div>
                  {/* <span class="form-step__step-name">Select Product</span> */}
                  <span class="form-step__step-name">{contentDetails?.lets_start_page_data?.select_product}</span>
                </div>

                <div class="form-steps__step form-steps__step--incomplete">
                  <div class="form-steps__step-circle">
                    <span class="form-steps__step-number">03</span>
                  </div>
                  {/* <span class="form-step__step-name">Your Details</span> */}
                  <span class="form-step__step-name">{contentDetails?.lets_start_page_data?.your_details}</span>
                </div>

                <div class="form-steps__step form-steps__step--last">
                  <div class="form-steps__step-circle">
                    <span class="form-steps__step-number">04</span>
                  </div>
                  {/* <span class="form-step__step-name">Payment</span> */}
                  <span class="form-step__step-name">{contentDetails?.lets_start_page_data?.payment}</span>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </div>
      <div className="MainBody">
        <div className="container">
          {/* <div className="FrmBody">
            <Row className="align-items-center">
              <Col sm={6}>
                <h2>
                  Select language for account setup process The account language
                  can be changed any time
                </h2>
              </Col>
              <Col sm={6}>
                <div>
                  <Form.Group className="FrmRgt">
                    <Form.Label>Language</Form.Label>
                    <Form.Select aria-label="Default select example" onChange={(e)=>setSelectedLanguage(e.target.value)}>
                      <option>Choose an language</option>
                      {showAllLanguages.map((item,index)=>{
                        return(
                          <option value={item.id}>{item?.language}</option>
                        )
                      })}
                    </Form.Select>
                    {showError && selectedLanguage == undefined && <span className="error">{errors.language}</span>}
                  </Form.Group>
                </div>
              </Col>
            </Row>
          </div> */}

          {/* <div className="FrmBody">
            <Row className="align-items-center">
              <Col sm={6}>
                <h2>
                  Select language for account setup process The account language
                  can be changed any time
                </h2>
              </Col>
              <Col sm={6}>
                  <div id="google_translate_element"></div>
              </Col>
            </Row>
          </div> */}

          <div className="FrmBody">
            <Row className="align-items-center">
              <Col sm={12} md={6}>
                <h2>
                  {/* Select your account currency This cannot be changed later! */}
                  {contentDetails?.lets_start_page_data?.euro_description}
                </h2>
              </Col>
              <Col sm={12} md={6}>
                <div className="check-div check-divRadio">
                  {showAllCurrencies?.map((item,index)=>{
                    return(
                    <div className="form-check mr-3">
                      <Form.Check type="radio" name='currency' 
                        aria-label="option 1" 
                        id={`flexCheckChecked${index}`} 
                        value={index + 1} 
                        onChange={(e)=>setSelectedCurrency(e.target.value)}
                        // onChange={(e) => {
                        //   if (item.id !== 1) {
                        //     setSelectedCurrency(e.target.value);
                        //   }
                        // }}

                        // onClick={(e) => item.id === 1 && e.preventDefault()}
                      />
                      <Form.Label
                        className="form-check-label"
                        for={`flexCheckChecked${index}`}
                      >
                        {/* <img src={item?.currency=='Euro' ? euro : item?.currency=='US-Dollar' ? dollor : ''} className="checkImg" alt="Img" /> */}
                        <img src={item?.currency=='Euro' ? euro : item?.currency=='US-Dollar' ? dollor : item?.currency=='CHF' ? chf :  ''} className="checkImg" alt="Img" />
                        <h3>{item?.currency}</h3>
                        <p>
                          {/* {item?.details} */}
                          {contentDetails?.lets_start_page_data?.euro_text}
                        </p>
                      </Form.Label>
                    </div>
                    )
                  })}
                </div>
              </Col>
            </Row>
            {showError && selectedCurrency == undefined && <span className="error" style={{paddingLeft:'252px'}}>{languageJson.currencyValidation}</span>}
          </div>

          <div className="BtnBottom">
            <Button
              variant="primary"
              type="submit"
              className="BtnSubmit"
              onClick={() => handleCompleteStep1()}
            >
              {/* Proceed  */}
              {contentDetails?.lets_start_page_data?.proceed_button_text}
            <HiArrowNarrowRight />
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LetStarted;
