import React, { useEffect, useState, useRef } from "react";
import Header from "../../components/header/Header";
import "./YourDetails.scss";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Step from "../../components/step/Step";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import email from "../../assets/images/EMailIcon.svg";
import phone from "../../assets/images/PhnIcon.svg";
import Button from "react-bootstrap/Button";
import { HiArrowNarrowRight, HiArrowNarrowLeft, HiCheck } from "react-icons/hi";
import { useLocation, useNavigate } from "react-router-dom";
import {NotificationManager} from 'react-notifications';
import Modal from "react-bootstrap/Modal";
import Remove from "../../assets/images/Remove.svg";
import SmllTick from "../../assets/images/SmllTick.svg";
import SmllCross from "../../assets/images/SmllCross.svg";
import RedCross from "../../assets/images/RedCross.svg";

import { 
  getLocationCountries, getCityByCountryId, getLocationAddressbyId, getLoacationFeaturesById, getContractPeriods, 
  getUserStatus, getContractPlans,getNumberOfLetters,
  getLegalCountries, getLegalRegionByCountryId, getLegalFormByCountryId, checkVATNumber,
  completeStep3, getProductContractPlans, DeleteProductListStep4, getPageContent

} from "../../api/apiRequest";

import {getProductListStep4, BillingAddressUpdate, getCityByRegionId,  getCountry, getStateByCountryId, getUserBillingDetails, getUserDetails } from "../../api/apiRequest";

import { useLangContext } from "../../context/languageContext";

const YourDetails = () => {
  const {hostLanguage,languageJson} = useLangContext();
  const navigate = useNavigate();
  let location = useLocation();
  
  let value = JSON.parse(localStorage.getItem("virtualOfficeLoginInfo"));

  const addressRef = useRef(null);
  const countryRef = useRef(null);

  const [selectedCountryById, setSelectedCountryById] = useState();
  const [selectedCountryName, setSelectedCountryName] = useState();
  const [selectedPhoneCode, setSelectedPhoneCode] = useState();
  const [billingPhoneCode, setBillingPhoneCode] = useState(); // this is for billing country code
  const [forwardAddressPhoneCode, setForwardAddressPhoneCode] = useState(); // this is for forward billing address
  const [selectedCityId, setSelectedCityId] = useState();
  const [selectedCityWithId, setSelectedCityWithId] = useState();
  const [selectedCityName, setSelectedCityName] = useState();
  const [selectedAddressId, setSelectedAddressId] = useState();
  const [selectedCustomerType, setSelectedCustomerType] = useState(1);
  const [showContractPeriods, setShowContractPeriods] = useState([]);
  const [contractPeriodId, setContractPeriodId] = useState();
  const [showContractPlans, setShowContractPlans]= useState([]);
  const [productId,setProductId] = useState();
  const [radioValue, setRadioValue] = useState();
  const [showAllCountries,setShowAllCountries] = useState([]);
  const [showCities,setShowCities] = useState([]);
  const [showAddress,setShowAddress] = useState([]);
  const [showFeatures,setShowFeatures] = useState([]);
  const [buttonActive,setButtonActive] = useState(false);
  const [checkVatValue, setCheckVatValue] = useState();
  const [sameAsBillingValue, setSameAsBillingValue] = useState(true);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [isRadioChecked,setIsRadioChecked] = useState('1');
  const [showError,setShowError] = useState(false);
  const [purposeType, setPurposeType] = useState('Private');
  const [numberOfLetters, setNumberOfLetters] = useState('');
  const [lastUserStatus,setLastUserStatus] = useState();
  const [contractPlanId,setContractPlanId] = useState();
  const [planCompShow, setPlanCompShow] = useState(false);
  const [isChecked,setIsClicked] = useState(false);
  const [btnValue,setBtnValue] = useState('1');

  // -- Recipient's section start --
  const [recipientName,setRecipientName] = useState();
  const [companyName,setCompanyNameName] = useState();
  const [selectedLegalCountries,setSelectedLegalCountries] = useState([]);
  const [selectedLegalRegionByCountryId,setLegalRegionByCountryId] = useState([]);
  const [selectedLegalFormByCountryId,setLegalFormByCountryId] = useState([]);
  const [recipientCountryId,setRecipientCountryId] = useState();
  const [recipientRegionId,setRecipientRegionId] = useState();
  const [recipientLegalFormId,setRecipientLegalFormId] = useState();
  // -- Recipient's section end --

  const [forwardAddressError,setForwardAddressError] = useState(false);
  const [forwardingAddressTitle,setForwardingAddressTitle] = useState();
  const [forwardingAddressFirstName,setForwardingAddressFirstName] = useState();
  const [forwardingAddressLastName,setForwardingAddressLastName] = useState();
  const [forwardingAddressCompanyName,setForwardingAddressCompanyName] = useState();
  const [forwardingAddress,setForwardingAddress] = useState();
  const [forwardingAddressCountry,setForwardingAddressCountry] = useState();
  const [forwardingAddressRegion,setForwardingAddressRegion] = useState();
  const [forwardingAddressCity,setForwardingAddressCity] = useState();
  const [forwardingAddressPostalCode,setForwardingAddressPostalCode] = useState();
  const [forwardingAddressPhoneNumber,setForwardingAddressPhoneNumber] = useState();
   
  // -- Forwarding address declaration end-- 

  const [billAddError] = useState({
    title: languageJson?.title_validation,
    first_name: languageJson?.first_name_validation,
    last_name: languageJson?.last_name_validation,
    company_name: languageJson?.company_name_validation,
    address: languageJson?.address_validation,
    country: languageJson?.select_country_validation,
    region: languageJson?.select_region_validation,
    city: languageJson?.select_city_validation,
    postal_code: languageJson?.postal_code_validation,
    phone_number: languageJson?.phone_number_validation
  })

  const [fwdAddError] = useState({
    title: languageJson?.title_validation,
    first_name: languageJson?.first_name_validation,
    last_name: languageJson?.last_name_validation,
    company_name: languageJson?.company_name_validation,
    address: languageJson?.address_validation,
    country: languageJson?.select_country_validation,
    region: languageJson?.select_region_validation,
    city: languageJson?.select_city_validation,
    postal_code: languageJson?.postal_code_validation,
    phone_number: languageJson?.phone_number_validation
  })

  const [errors] = useState({
    country: languageJson?.select_country_validation,
    city: languageJson?.select_city_validation,
    r_full_name: languageJson?.name_validation,
    r_company_name: languageJson?.company_name_validation,
    r_country: languageJson?.select_country_validation,
    r_region: languageJson?.select_region_validation,
    r_legal_form: languageJson?.select_legal_form_validation
  })

  const [formDataBillingAddress, setformDataBillingAddress] = useState({
    first_name: "",
    last_name: "",
    company_name: "",
    title: "",
    address: "",
    city_id: "",
    country_id: "",
    postal_code: "",
    phone_number: "",
    region_id: "",
    vat_number:"",
  });

  const [formErrorsBillingAddress, setFormErrorsBillingAddress] = useState({
    first_name: "",
    last_name: "",
    company_name: "",
    title: "",
    address: "",
    city_id: "",
    country_id: "",
    postal_code: "",
    phone_number: "",
    region_id: "",
    vat_number:"",
  });

const [showAllCountries2,setShowAllCountries2] = useState([]);
const [showForwardCountries,setShowForwardCountries] = useState([]);
const [vatNumber, setVatNumber] = useState('');
const [isValid, setIsValid] = useState(null);
const [selectedLegalRegionByCountryId2,setLegalRegionByCountryId2] = useState([]);
const [selectedLegalRegionByCountryId3,setLegalRegionByCountryId3] = useState([]);
const [selectedCityWithId2, setSelectedCityWithId2] = useState([]);
const [selectedCityWithId3, setSelectedCityWithId3] = useState([]);
const [userDetails, setUserDetails] = useState();

const [radioCityId,setRadioCityId] = useState();
const [radioId,setRadioId] = useState();
const [contentDetails,setContentDetails] = useState();

  // get page content
  const getAllPageContent = async() => {
    try
    {
      let apiRes = await  getPageContent(hostLanguage);
      let responseData = await apiRes.json();
      if(responseData.res)
      {
        setContentDetails(responseData.data);
        // console.warn(responseData.data.header);
      }
      else
      {
        NotificationManager.error(responseData.msg,'',2000);
      }
    }
    catch(error)
    {
      console.error(error);
    }
  }

const handleRoute = (route) => {
navigate(route);
};

const deleteRecord = async() =>{
    try
    {
      let apiRes = await DeleteProductListStep4(lastUserStatus);
      let responseData = await apiRes.json();
      if(responseData.res)
      {
        navigate('/Dashboard')
      }
      else
      {
        navigate('/Dashboard');
      }
    }
    catch(error)
    {
      // NotificationManager.error('Something went wrong!', '',2000);
    }
}

//getting location countries
const handleCountryList = async () => {
try
{
let apiRes = await getLocationCountries(hostLanguage);
let responseData = await apiRes.json();

if (responseData.res && responseData.data)
{
  const countriesArray = Object.values(responseData.data).flat();
  setShowAllCountries(countriesArray);
  // console.warn(responseData.data);
  // console.warn(countriesArray);
}
else
{
  NotificationManager.error('Invalid response format for countries', '', 2000);
}
}
catch (error)
{
// NotificationManager.error('Something went wrong!', '', 2000);
}
};

//country details
const handleCountryDetails = async (e) => {
  setSelectedCityWithId();
  setSelectedAddressId();
// showError(false)
//  let visible_country = true;
// setIsChangedCountry(true);
let data = e.target.value;
console.warn(data);
let split_country = data.split('-');
setSelectedCountryById(split_country[0]);  
setSelectedCountryName(split_country[1]);
setSelectedPhoneCode(split_country[2]);

{/* <option value={`${item.country.id}-${item.country.name}-${item.country.phonecode}`}></option> */}

// console.warn('+' + split_country[2] + formDataBillingAddress.phone_number);


// setSelectedCityId(null);
// setSelectedCityName(null);
// setSelectedAddressId(null);

// if(split_country[0]!=null && selectedCityName == null)
// {
  handleCityByCountryId(split_country[0]);
// }
// else
// {
//   // setShowCities([]);
//   handleCityByCountryId(split_country[0]);
// }

}

//city details
const handleCityDetails = async (e) => {
  // setShowFeatures([]);
  setSelectedAddressId();
  let data = e.target.value;
  let split_city = data.split('-');
  handleLocationAddress(selectedCountryById,split_city[0]);      //(country_id,city_id)
  setSelectedCityWithId(split_city[0]);
  setSelectedCityName(split_city[1]);
  setIsRadioChecked('1');
}

//getting city by country id
const handleCityByCountryId = async (country_id) =>{
  try
  {
    setShowCities([]);
    setShowAddress([]);
    let apiRes = await getCityByCountryId(country_id,hostLanguage);
    let responseData = await apiRes.json();
    if(responseData.res)
    {
      // setShowCities(responseData.data[""]);
      setShowCities(responseData.data);
      console.warn(responseData.data);
    }
    else
    {
      NotificationManager.error(responseData.msg, '',2000);
    }
  }
  catch(error)
  {
  // NotificationManager.error('Something went wrong!', '',2000);
  }
}

//location Address
const handleLocationAddress = async(country_id,city_id) =>{
  try
  {
    let apiRes = await getLocationAddressbyId(country_id,city_id,hostLanguage);
    let responseData = await apiRes.json();
    if(responseData.res)
    {
      console.warn(responseData.data);
      setShowAddress(responseData.data);
      setSelectedCityId(responseData.data[0]?.id);

      // handleContractPeriods(city_id);
      getCityInfoId(city_id);
      
      setRadioCityId(city_id);
    }
    else
    {
      NotificationManager.error(responseData.msg, '',2000);
    }
  }
  catch(error)
  {
    // NotificationManager.error('Something went wrong!', '',2000);
  }
}

//getting location id
const getLocationDetails = async (item) => {
  console.warn(item)
  setShowFeatures([]);
  console.warn(showAddress)
  console.warn(item)
  console.warn(btnValue)
  handleLocationFeaturesById(item?.id);
  setSelectedAddressId(item?.id);
  // alert(item.id);
}

//getting location features by id
const handleLocationFeaturesById = async (location_id) => {
try
{
let apiRes = await getLoacationFeaturesById(location_id,selectedCustomerType,hostLanguage);
let responseData = await apiRes.json();
if(responseData.res)
{
  setShowFeatures(responseData.data);
}
else
{
  NotificationManager.error(responseData.msg, '',2000);
}
}
catch(error)
{
// NotificationManager.error('Something went wrong!', '',2000);
}
}

const getCityInfoId = (city_id) => {
  // handleContractPeriods(city_id);
  setRadioCityId(city_id);
}

//contract period
const handleContractPeriods = async (radio_id) => {
try
{
  // product_id, location_country_id, location_city_id, location_id

  // let location_id = showAddress.find(item => item.id === radioId)
  // console.warn(showAddress)
  // console.warn(radioId)
  // console.warn(location_id);
  // return
  // product_id, location_country_id, location_city_id, location_id

  let apiRes = await getContractPeriods(productId,selectedCountryById,radioCityId,radio_id,hostLanguage);
  let responseData = await apiRes.json();
  if(responseData.res)
  {
    setShowContractPeriods(responseData.data);
    console.warn(responseData.data);

    handleContractPlan(responseData.data[0]?.id,responseData.data[0]?.product_id,responseData.data[0]?.location_id); // for initial call
  }
  else
  {
    NotificationManager.error(responseData.msg, '',2000);
  }
}
catch(error)
{
// NotificationManager.error('Something went wrong!', '',2000);
}
}

//contract plan
const handleContractPlan = async (contract_period_id,product_id,location_id) => {
try
{
  // showContractPeriods
  // const foundItem = items.find(item => item.id === idToFind);
  //   setFoundItem(foundItem);
    if(contract_period_id == '')
    {
      // alert('0');
      let contractPeroidId = showContractPeriods.find(item => item.location_id === location_id)
      // console.warn(location_id);
      // console.warn(showContractPeriods);
      // console.warn(contractPeroidId);
      var apiRes = await getContractPlans(product_id,location_id,contractPeroidId?.id,purposeType,hostLanguage);
    }
    else
    {

    // alert('1')

// product_id, location_country_id, location_city_id
// let apiRes = await getContractPlans(product_id,location_id,productId,contract_period_id);
setContractPeriodId(contract_period_id);

if(product_id == '')
{
  // alert('1');
  var apiRes = await getContractPlans(productId,location_id,contract_period_id,purposeType,hostLanguage);
  console.warn(100);
  // handleProductContractPlans(productId,contract_period_id,purposeType);   //product_id, contract_period_id,purpose
}
else
{
  // alert('2');
    var apiRes = await getContractPlans(product_id,location_id,contract_period_id,purposeType,hostLanguage);
    console.warn(200)
    console.warn(product_id);
    console.warn(contract_period_id);
    // handleProductContractPlans(product_id,contract_period_id,purposeType);   //product_id, contract_period_id,purpose
}
}

let responseData = await apiRes.json();
if(responseData.res)
{
  setShowContractPlans(responseData.data);
  setRadioValue(responseData.data[0].title.toLowerCase());

  // console.warn(responseData.data);
  // console.warn(responseData.data[0].id);
  setContractPlanId(responseData.data[0].id);

  // setSelectedPlanId(responseData.data[0].id);
  // alert(responseData.data[0].id)
  // console.warn(responseData.data)
  // console.warn(responseData.data[0].id)
  // setDefaultSelectedContractPlan()
}
else
{
  NotificationManager.error(responseData.msg, '',2000);
}
}
catch(error)
{
// NotificationManager.error('Something went wrong!', '',2000);
}
}
  
//Get Product Contract Plans   product_id, contract_period_id,purpose
const handleProductContractPlans = async (product_id,contract_period_id,purpose) => {
  try
  {
    let apiRes = await getProductContractPlans(product_id,contract_period_id,purpose,hostLanguage);
    let responseData = await apiRes().json();
    
    if(responseData.res == true)
    {
      console.warn(responseData.data);
    }
    else
    {
      NotificationManager.error(responseData.msg);
    }
  }
  catch(error)
  {
    console.error(error);
  }
}

//get user status
const handleUserStatus = async () => {
  try
  {
    let apiRes = await getUserStatus();
    let responseData = await apiRes.json();
    if(responseData.res)
    {
      console.warn(responseData.data)
      setLastUserStatus(responseData.data?.id); // last user_status_id
      if(responseData.data?.product_id == 1)
      {
        setProductId(responseData.data?.product_id);
        console.warn(responseData.data?.product_id)
        console.warn(showContractPeriods);
        console.log(showContractPeriods);
        // handleContractPlan(44,responseData.data?.product_id); // for initial call
      }
      else
      {
        navigate('/SelectCountryPhoneNumber')
      }
    }
    else
    {
      NotificationManager.error(responseData.msg, '',2000);
    }
  }
  catch(error)
  {
    // NotificationManager.error('Something went wrong!', '',2000);
  }
}

// handle Legal Countries
const handleLegalCountries = async () => {
try
{
let apiRes = await getLegalCountries();
let responseData = await apiRes.json();
if(responseData.res)
{
setSelectedLegalCountries(responseData.data);
}
else
{
NotificationManager.error(responseData.msg, '',2000);
}
}
catch(error)
{
// NotificationManager.error('Something went wrong!', '',2000);
}
}

// handle Legal Region by Country Id
const handleRegionsByCountryId = async (country_id) => {
try
{
let apiRes = await getLegalRegionByCountryId(country_id);
let responseData = await apiRes.json();
if(responseData)
{
  setLegalRegionByCountryId(responseData);
}
else
{
  NotificationManager.error(responseData.msg, '',2000);
}
}
catch(error)
{
// NotificationManager.error('Something went wrong!', '',2000);
}
}

// handle Legal Forms by Country Id
const handleLegalFormByCountryId = async (country_id) => {
try
{
let apiRes = await getLegalFormByCountryId(country_id);
let responseData = await apiRes.json();
if(responseData)
{
  setLegalFormByCountryId(responseData);
}
else
{
  NotificationManager.error(responseData.msg, '',2000);
}
}
catch(error)
{
// NotificationManager.error('Something went wrong!', '',2000);
}
}

//keep changes
const handleKeepChanges = () =>{
  if(isRadioChecked == '2')
  {
    if(btnValue == 1)
    {
      handleButtonValue(2,'Business');
    }
    else if(btnValue == 2 )
    {
      handleButtonValue(1,'Private');   //suppose click then but apply changes
    }
  }
  else if(isRadioChecked == '1')
  {
    setSelectedAddressId();
  }
  handleClose();
}

// button selection
const handleApplyChange = async () => {
  if(btnValue == '2')
  {
    setSelectedCustomerType(2);
    setPurposeType('Business');
  }
  else if(btnValue == '1')
  {
    setSelectedCustomerType(1);
    setPurposeType('Private');
  }

  setSelectedAddressId();
  setButtonActive(!buttonActive);
  setIsRadioChecked('1');
  setShowError(true);
  handleClose();
}

// button purpose value
const handleButtonValue = async (val,type) => {
  // setSelectedAddressId();
  setPurposeType(type);
  setSelectedCustomerType(val);
  handleShow();
}

// Number of Letters(onchange effect)
const handleNumberOfLetters = (event) => {
const inputValue = event.target.value.replace(/\D/g, '');
if (inputValue !== '' && !isNaN(inputValue))
{
setNumberOfLetters(inputValue);
}
else
{
setNumberOfLetters('');
}
};

// Check number of letters(button clicked)
const handleCheckLetters = async () => {
try
{
let values = {
  product_id : Number(productId),
  contract_period_id: contractPeriodId == null ? 1 : Number(contractPeriodId),
  number_of_letters: Number(numberOfLetters)
}
let apiRes = await getNumberOfLetters(values);
let responseData = await apiRes.json();
if(responseData.res)
{
  // setShowContractPlans(responseData.data);
  setRadioValue(responseData.data.title.toLowerCase())
  console.warn(responseData);
}
else
{
  NotificationManager.error(responseData.msg, '',2000);
}
}
catch(error)
{
// NotificationManager.error('Something went wrong!', '',2000);
}
}

//for Tab and Enter
const handleKeyPress = (e) =>{
  if (e.key === 'Enter' || e.key === 'Tab')
  {
    handleCheckingVATNumber(formDataBillingAddress.vat_number);
  }
}

// Check number of letters(button clicked)
const handleCheckingVATNumber = async (vat_no) => {
  try
  {
    setIsClicked(true);
    let values = {vat_number:vat_no,lang:hostLanguage}    // vat_number:DE254549143 => valid
    
    let apiRes = await checkVATNumber(values);
    let responseData = await apiRes.json();
    if(responseData.res)
    {
      NotificationManager.success(responseData.msg, '',2000);
      setIsClicked(false);
    }
    else
    {
      NotificationManager.error(languageJson?.vat_Number_validation2, '',2000);
      setIsClicked(false);
    }
  }
  catch(error)
  {
    console.error('Something went wrong!');
  }
}

// step3 submission
const handleStep3Submission = async () => {
  if(selectedCountryById == undefined || selectedCityId == undefined)
  {
    setShowError(true);
    // NotificationManager.error('Please Select Country and City', '',2000);
    NotificationManager.error(languageJson?.country_city_validation, '',2000);
  }
  else
  {
    // 'Private' or 'Business'
    if(purposeType === 'Private' && (recipientName == undefined || recipientName == null) || recipientName == '' )
    {
      setShowError(true);
      // NotificationManager.error('Recipients name is required', '',2000);
      NotificationManager.error(languageJson?.recipients_name_validation, '',2000);
    }
    else if(isRadioChecked == '1')
    {
      setShowError(true);
      // NotificationManager.error('Address must be select', '',2000);
      NotificationManager.error(languageJson?.address_select_validation, '',2000);
    }
    else if(purposeType === 'Business' && (companyName == undefined || companyName == null) || companyName == '' )
    {
      setShowError(true);
      // NotificationManager.error('Company Name is required', '',2000);
      NotificationManager.error(languageJson?.company_name_validation, '',2000);
    }
    else if((!sameAsBillingValue) && (
      forwardingAddressTitle == undefined || forwardingAddressFirstName == undefined || 
      forwardingAddressLastName == undefined || forwardingAddressCompanyName == undefined || 
      forwardingAddress == undefined || forwardingAddressCountry == undefined ||
      forwardingAddressRegion == undefined || forwardingAddressCity == undefined ||
      forwardingAddressPostalCode == undefined || forwardingAddressPhoneNumber == undefined))
    {
      setForwardAddressError(true);
    }
    else
    {
      if(validateFormBillingAddress())
      {
        setShowError(false);
        try
        {
          let values = {
            id: lastUserStatus,
            contract_period_id: contractPeriodId,
            plan_id: contractPlanId,
            purpose: purposeType,
            digital_postbox_country_id: selectedCountryById,
            digital_postbox_city_id: selectedCityWithId,
            digital_postbox_location_id: selectedCityId,
            postbox_fullname:'',
            postbox_company_name:'',
            postbox_legal_form:'',
            r_full_name: recipientName ? recipientName : '',
            r_company_name: companyName ? companyName : '',
            r_country_id: recipientCountryId ? recipientCountryId :'',
            r_region_id: recipientRegionId ? recipientRegionId :'',
            r_form_id: recipientLegalFormId ? recipientLegalFormId: '',
            vat_number: formDataBillingAddress.vat_number,
            title: formDataBillingAddress.title,
            first_name: formDataBillingAddress.first_name,
            last_name: formDataBillingAddress.last_name,
            company_name: formDataBillingAddress.company_name,
            address: formDataBillingAddress.address,
            area_code:'',
            country_id: formDataBillingAddress?.country_id,
            region_id: formDataBillingAddress.region_id,
            city_id: formDataBillingAddress.city_id,
            postal_code: formDataBillingAddress.postal_code,
            // phone_number: formDataBillingAddress.phone_number,
            // console.warn('+' + split_country[2] + formDataBillingAddress.phone_number);
            // phone_number: '+' + selectedPhoneCode + formDataBillingAddress.phone_number,
            phone_number: formDataBillingAddress.phone_number,
            phonecode: billingPhoneCode ? billingPhoneCode : '',
            forwarding_address_same_billing: sameAsBillingValue ? 1 : 2,
            f_title: sameAsBillingValue ? formDataBillingAddress.title : forwardingAddressTitle,
            f_first_name: sameAsBillingValue ? formDataBillingAddress.first_name : forwardingAddressFirstName,
            f_last_name: sameAsBillingValue ? formDataBillingAddress.last_name : forwardingAddressLastName,
            f_company_name: sameAsBillingValue ? formDataBillingAddress.company_name : forwardingAddressCompanyName,
            f_address: sameAsBillingValue ? formDataBillingAddress.address : forwardingAddress,
            f_country_id: sameAsBillingValue ? formDataBillingAddress.country_id : forwardingAddressCountry,
            f_city_id: sameAsBillingValue ? formDataBillingAddress.city_id : forwardingAddressCity,
            f_region_id: sameAsBillingValue ? formDataBillingAddress.region_id : forwardingAddressRegion,
            f_postal_code: sameAsBillingValue ? formDataBillingAddress.postal_code : forwardingAddressPostalCode,
            f_area_code: '',
            // f_phone_number: sameAsBillingValue ? ('+' + selectedPhoneCode + formDataBillingAddress.phone_number) : ('+' + selectedPhoneCode + forwardingAddressPhoneNumber)
            f_phone_number: sameAsBillingValue ? formDataBillingAddress.phone_number : forwardingAddressPhoneNumber,
            f_phonecode: sameAsBillingValue? billingPhoneCode : forwardAddressPhoneCode
          }
          if(values == '')
          {
            // NotificationManager.error('Please fill out billing address all fields before proceeding!', '',2000);
            NotificationManager.error(languageJson?.billing_address_validation, '',2000);
          }
          else
          {
            console.warn(values)
            let apiRes = await completeStep3(values);
            let responseData = await apiRes.json();
            if(responseData.res)
            {
              navigate('/Payment');
            }
            else
            {
              // NotificationManager.error(responseData.msg, '',2000);
              console.warn(responseData.msg)
            }
          }
        }
        catch(error)
        {
          // NotificationManager.error('Something went wrong!', '',2000);
        }
      }
    }
  }
}

const getUserData = async() =>
{
try
{
let apiRes = await getUserDetails();
let responseData = await apiRes.json();
if(responseData)
{ //console.log(responseData.data);
  setUserDetails(responseData.data)
}
else
{
  NotificationManager.error(responseData.msg, '',2000);
}
}
catch(error)
{
NotificationManager.error('Something went wrong!', '',2000);
}
}

const handleCountryList2 = async() =>
{
  try
  {
    let apiRes = await getCountry();
    let responseData = await apiRes.json();
    if(responseData)
    {
      setShowAllCountries2(responseData.data);
    }
    else
    {
      console.error(responseData.msg);
    }
  }
  catch(error)
  {
    console.error(error);
  }
}

const handleForwardAddressCountryList = async() =>
{
  try
  {
    let apiRes = await getCountry();
    let responseData = await apiRes.json();
    if(responseData)
    {
      setShowForwardCountries(responseData.data);
    }
    else
    {
      console.error(responseData.msg);
    }
  }
  catch(error)
  {
    console.error(error);
  }
}

const UserBillingDetails = async () => {
try
{

let apiRes = await getUserBillingDetails();
let responseData = await apiRes.json();
if(responseData)
{
  if(responseData.data[0].vat_number!= '')
  {
    setCheckVatValue(false)
  }
  else
  {
    setCheckVatValue(true)
  }
  
  console.log(responseData.data[0]);
  setformDataBillingAddress(
    {
      first_name: responseData.data[0].first_name,
      last_name: responseData.data[0].last_name,
      company_name: responseData.data[0].company_name,
      title: responseData.data[0].title,
      address: responseData.data[0].address,
      city_id: responseData.data[0].city_id,
      country_id: responseData.data[0].country_id,
      postal_code: responseData.data[0].postal_code,
      phone_number: responseData.data[0].phone_number,
      region_id: responseData.data[0].region_id,
      vat_number: responseData.data[0].vat_number,
    }
  );
  setBillingPhoneCode(responseData.data[0].phonecode);
  setForwardAddressPhoneCode(responseData.data[0].f_phonecode);

  handleRegionsByCountryId2(responseData.data[0].country_id);
  handleRegionsAddressByCountryId(responseData.data[0].f_country_id);

  getCityByRegionData(responseData.data[0].region_id);
  getCityByRegionData3(responseData.data[0].f_region_id);



  setForwardingAddressTitle(responseData.data[0].f_title);
  setForwardingAddressFirstName(responseData.data[0].f_first_name);
  setForwardingAddressLastName(responseData.data[0].f_last_name);
  setForwardingAddressCompanyName(responseData.data[0].f_company_name);
  setForwardingAddress(responseData.data[0].f_address);
  setForwardingAddressCountry(responseData.data[0].f_country_id);
  setForwardingAddressRegion(responseData.data[0].f_region_id);
  setForwardingAddressCity(responseData.data[0].f_city_id);
  setForwardingAddressPostalCode(responseData.data[0].f_postal_code);
  setForwardingAddressPhoneNumber(responseData.data[0].f_phone_number);
  // console.log(responseData.data[0].f_region_id,responseData.data[0].f_city_id)
  
}
else
{
  NotificationManager.error(responseData.msg, '',2000);
}
}
catch(error)
{
// NotificationManager.error('Something went wrong!', '',2000);
}
}

const handleRegionsByCountryId2 = async (country_id) => {
  try
  {
    if(country_id!=null)
    {
      let apiRes = await getStateByCountryId(country_id);
      let responseData = await apiRes.json();
      if(responseData)
      {
        setLegalRegionByCountryId2(responseData);

        const foundItem = showForwardCountries.find(item => item.id === parseInt(country_id));
        setBillingPhoneCode(foundItem.phonecode); 
      }
      else
      {
        NotificationManager.error(responseData.msg, '',2000);
      }
    }
  }
  catch(error)
  {
    // NotificationManager.error('Something went wrong!', '',2000);
  }
}

const handleRegionsAddressByCountryId = async (country_id) => {
  try
  {
    if(country_id!=null)
    {
      let apiRes = await getStateByCountryId(country_id);
      let responseData = await apiRes.json();
      if(responseData)
      {
        setLegalRegionByCountryId3(responseData);

        const foundItem = showForwardCountries.find(item => item.id === parseInt(country_id));
        setForwardAddressPhoneCode(foundItem.phonecode); 
      }
      else
      {
        NotificationManager.error(responseData.msg, '',2000);
      }
    }
  }
  catch(error)
  {
    // NotificationManager.error('Something went wrong!', '',2000);
  }
}

const getCityByRegionData = async (region_id) => {
  try
  {
    if(region_id!=null)
    {
      let apiRes = await getCityByRegionId(region_id);
      let responseData = await apiRes.json();
      if(responseData)
      {
        setSelectedCityWithId2(responseData);
      }
      else
      {
        NotificationManager.error(responseData.msg, '',2000);
      }
    }
  }
  catch(error)
  {
  // NotificationManager.error('Something went wrong!', '',2000);
  }
}

const getCityByRegionData3 = async (region_id) => {
  try
  {
    if(region_id!=null)
    {
      let apiRes = await getCityByRegionId(region_id);
      let responseData = await apiRes.json();
      if(responseData)
      {
        setSelectedCityWithId3(responseData);
      }
      else
      {
        NotificationManager.error(responseData.msg, '',2000);
      }
    }
  }
  catch(error)
  {
    // NotificationManager.error('Something went wrong!', '',2000);
  }
}

const handleChangeBillingAddress = e => {
  const {name, value} = e.target;
  setformDataBillingAddress({...formDataBillingAddress, [name]: value});
  setFormErrorsBillingAddress({...formErrorsBillingAddress, [name]: ''});
};

const handleCountryTOState = e => {

  handleRegionsByCountryId2(e.target.value);
  const {name, value} = e.target;
  setformDataBillingAddress({...formDataBillingAddress, [name]: value});
  setFormErrorsBillingAddress({...formErrorsBillingAddress, [name]: ''});
  const foundItem = showAllCountries2.find(item => item.id === parseInt(e.target.value));
  setBillingPhoneCode(foundItem.phonecode);
};

const handleRegionTOCity = e => {
  getCityByRegionData(e.target.value);
  const {name, value} = e.target;
  setformDataBillingAddress({...formDataBillingAddress, [name]: value});
  setFormErrorsBillingAddress({...formErrorsBillingAddress, [name]: ''});
};

const validateFormBillingAddress = () => {
  let isValid = true;
  const errors = {
    first_name: "",
    last_name: "",
    company_name: "",
    title: "",
    address: "",
    city_id: "",
    country_id: "",
    postal_code: "",
    phone_number: "",
    region_id: "",
    vat_number:"",
  };
  
  if (formDataBillingAddress.first_name === "")
  {
    errors.first_name =  billAddError?.first_name;
    isValid = false;
  }

  if (formDataBillingAddress.last_name === "")
  {
    errors.last_name = billAddError?.last_name;
    isValid = false;
  }

  if (formDataBillingAddress.company_name === "")
  {
    errors.company_name = billAddError?.company_name;
    isValid = false;
  }

  if (formDataBillingAddress.title === "")
  {
    errors.title = billAddError?.title;
    isValid = false;
  }

  if (formDataBillingAddress.city_id === "")
  {
    errors.city_id = billAddError?.city;
    isValid = false;
  }

  if (formDataBillingAddress.region_id === "")
  {
    errors.region_id = billAddError?.region;
    isValid = false;
  }

  if (formDataBillingAddress.country_id === "")
  {
    errors.country_id = billAddError?.country;
    isValid = false;
  }

  if (formDataBillingAddress.postal_code === "")
  {
    errors.postal_code = billAddError?.postal_code;
    isValid = false;
  }

  if (formDataBillingAddress.address === "")
  {
    errors.address = billAddError?.address;
    isValid = false;
  }

  if (formDataBillingAddress.phone_number === "")
  {
    errors.phone_number = billAddError?.phone_number;
    isValid = false;
  }
  setFormErrorsBillingAddress(errors);
  return isValid;
};

  const ProductListStep4 = async() =>
  {
    try
    {
      let apiRes = await getUserStatus();
      let responseData = await apiRes.json();
      if(responseData.res)
      { 
        if(responseData.data == null)
        {
          let apiRes = await getUserDetails();
          let responseData = await apiRes.json();
          if(responseData)
          {
            if(responseData.data.language_id == '' || responseData.data.currency_id == '')
            {
               navigate('/LetStarted');
            }
            else
            {
              navigate('/SelectProduct')
            }
          }
          else
          {
            console.error(responseData.msg, '',2000);
          }
        }
      }
    }
    catch(error)
    {
      console.error('Something went wrong!', '',2000);
    }  
  }

  useEffect (()=>{
    handleCountryList2();
    handleForwardAddressCountryList();
    handleCountryList();
    handleUserStatus();
    handleLegalCountries();
    UserBillingDetails();
    getUserData();
    ProductListStep4();
    getAllPageContent();
  },[hostLanguage])

  
  return (
    <div className="lightBlue">
      <Header />
      <div className="Hrbottom">
        <div className="container">
          <Row className="align-items-center">
            <Col sm={12} md={5}>
              <h2>{contentDetails?.lets_start_page_data?.set_up_your_account}</h2>
            </Col>
            <Col sm={12} md={7}>
              <div class="form-steps">
                <div class="form-steps__step form-steps__step--active">
                  <div class="form-steps__step-circle">
                    <span class="form-steps__step-number">
                      <HiCheck size="20" />
                    </span>
                  </div>
                  <span class="form-step__step-name">{contentDetails?.lets_start_page_data?.lets_started}</span>
                </div>

                <div class="form-steps__step form-steps__step--active">
                  <div class="form-steps__step-circle">
                    <span class="form-steps__step-number">
                      <HiCheck size="20" />
                    </span>
                  </div>
                  <span class="form-step__step-name">{contentDetails?.lets_start_page_data?.select_product}</span>
                </div>

                <div class="form-steps__step form-steps__step--active">
                  <div class="form-steps__step-circle">
                    <span class="form-steps__step-number">03</span>
                  </div>
                  <span class="form-step__step-name">{contentDetails?.lets_start_page_data?.your_details}</span>
                </div>

                <div class="form-steps__step form-steps__step--last">
                  <div class="form-steps__step-circle">
                    <span class="form-steps__step-number">04</span>
                  </div>
                  <span class="form-step__step-name">{contentDetails?.lets_start_page_data?.payment}</span>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </div>
      <div className="MainBody">
        <div className="container">
          <div className="FrmBox">
            <h4>{contentDetails?.your_details_page_data?.specifyCleverdomizilMail}</h4>

            <div className="FrmBody">
              <Row className="align-items-center">
                <Col sm={12} md={6}>
                  <h2>{contentDetails?.your_details_page_data?.choosePurposeOfYourDigitalPostboxAddress}
                  </h2>
                </Col>
                <Col sm={12} md={6}>
                  <div className="BtnDiv">
                  {buttonActive ? (
                      <>
                        <Button variant="primary" type="button" className="BtnBackdis" value ='1' onClick={()=>{setBtnValue(1);setShow(true)}} style={{margin: '4px'}}>
                          {/* Private purpose */}
                          {contentDetails?.your_details_page_data?.choosePurposeOfYourDigitalPostboxAddressButtonText1}
                        </Button>

                        <Button variant="primary" type="button" className="BoxBtn">
                          {/* Business purpose */}
                          {contentDetails?.your_details_page_data?.choosePurposeOfYourDigitalPostboxAddressButtonText2}
                        </Button>
                      </>
                  ):(
                      <>
                        <Button variant="primary" type="button" className="BoxBtn" style={{margin: '4px'}}>
                          {/* Private purpose */}
                          {contentDetails?.your_details_page_data?.choosePurposeOfYourDigitalPostboxAddressButtonText1}
                        </Button>

                        <Button variant="primary" type="button" className="BtnBackdis" value='2' onClick={()=>{setBtnValue(2);setShow(true)}}>
                          {/* Business purpose */}
                          {contentDetails?.your_details_page_data?.choosePurposeOfYourDigitalPostboxAddressButtonText2}
                        </Button>
                      </>
                  )}
                    {/* <Button variant="primary" type="button" className="BtnBackdis" onClick={handleShow}>
                      Private purpose
                    </Button>

                    <Button variant="primary" type="button" className="BoxBtn">
                      Business purpose
                    </Button> */}
                  </div>
                </Col>
              </Row>
            </div>

            <div className="FrmBody">
              <Row className="align-items-center">
                <Col sm={12} md={6}>
                  <h2>
                    {/* Choose purpose of your digital
                    <br /> postbox address */}
                    {contentDetails?.your_details_page_data?.chooseLocationOfYourDigitalPostboxAddress}
                  </h2>
                </Col>
                <Col sm={12} md={6}>
                  <div className="mb-4">
                    <Form.Group
                      className="FrmRgt"
                    >
                      {/* <Form.Label>Country</Form.Label> */}
                      <Form.Label>{contentDetails?.your_details_page_data?.country}</Form.Label>
                        <Form.Select 
                          aria-label="Default select example" 
                          onChange={(e) => handleCountryDetails(e)}
                          onKeyDown={(e)=>{
                            if(e.key === 'Enter' || e.key === 'Tab')
                            {
                              e.preventDefault();
                              document.getElementById("digital_city").focus();
                            }
                          }}
                        >
                          {/* <option selected hidden value={null}>Select Country</option> */}
                          <option selected hidden value={null}>{contentDetails?.your_details_page_data?.countryOptionalSelectBoxDefaultText}</option>
                          {showAllCountries.map((item, index) => (
                            // <option value={item.country.id} key={index}>{item.country.name}</option>
                            // <option value={item[0]} key={index}>{item.country.id}</option>
                            <option value={`${item.country.id}-${item.country.name}-${item.country.phonecode}`} key={index}>{item.country.name}</option>
                          ))}
                        </Form.Select>
                        {showError && selectedCountryById == undefined && <span className="error">{errors.country}</span>}
                      </Form.Group>
                  </div>
                  {selectedCountryById ? (
                    <div>
                      <Form.Group className="FrmRgt">
                        {/* <Form.Label>City</Form.Label> */}
                        <Form.Label>{contentDetails?.your_details_page_data?.city_label}</Form.Label>
                        <Form.Select
                          id="digital_city"
                          aria-label="Default select example" 
                          onChange={(e)=> handleCityDetails(e)}
                        >
                          {/* <option selected hidden value={null}>Select City</option> */}
                          <option selected hidden value={null}>{contentDetails?.your_details_page_data?.citySelectBoxDefaultText}</option>
                          {showCities.map((item,index)=>{
                            return(
                              // <option value={`${item.id}-${item.city_id}-${item.city_name}-${item.address}`} key={index}>{item?.city_name}</option>
                              <option value={`${item.city.id}-${item.city.name}`} key={index}>{item?.city.name}</option>
                            )
                          })}
                        </Form.Select>
                        {showError && selectedCityWithId == undefined && <span className="error">{errors.city}</span>}
                        {/* <p>Mariahilfer Straße 1, Graz, Austria</p> */}

                        {selectedCountryById && selectedCityWithId && (
                          showAddress?.map((item,index)=>{
                            return(
                              <>
                              <div className="form-check checkFrm">
                                <Form.Check
                                  id="newaddress"
                                  type="radio"
                                  name="address"
                                  // checked={selectedAddressId ? true : false}     //contract_period_id,product_id,location_id handleContractPlan('',item?.product_id,item?.id);
                                  // checked={true}
                                  checked={selectedAddressId ? true : false}
                                  onChange={() => {setIsRadioChecked('2');getLocationDetails(item);handleContractPeriods(item?.id)}}
                                />
                                <Form.Label className="form-check-label" for="newaddress">   
                                  {/* Mariahilfer Straße 1, Graz, Austria */}
                                  {item?.address}, {selectedCityName}, {selectedCountryName}
                                </Form.Label>
                              </div>
                              {/* {showError && isRadioChecked === '1' && <span className="error">Select the address</span>} */}
                              {showError && isRadioChecked === '1' && <span className="error">{languageJson?.select_the_address}</span>}
                              </>
                            )
                          })
                        )}



                        {/* {selectedCityWithId && (
                          // <p>{selectedAddress}, {selectedCityName}, {selectedCountryName}</p>
                          showAddress?.map((item,index)=>{
                            return(
                              // <p>{item?.address}, {selectedCityName}, {selectedCountryName}</p>
                              <p>{item?.address}</p>
                            )
                          })

                        )} */}

                      </Form.Group>
                    </div>
                  ) : null}
                </Col>
              </Row>
              {/* {selectedCountryById && selectedCityWithId && selectedAddressId? */}
              {selectedAddressId?
                showFeatures?.length > 0 ? (
                  <div className="Postbox">
                    {/* <h3>You can use your Digital Postbox Address for:</h3> */}
                    <h3>{contentDetails?.your_details_page_data?.you_can_use_your_Digital_Postbox_Address_for}</h3>
                    <ul>
                      {showFeatures?.map((item,index)=>{
                        return(
                          <>
                            {item?.used_purpose == 1 ? (
                              <li><img src={SmllTick} alt="Img" />{item?.purpose}</li>
                            ):
                            item?.used_purpose == 2 ? (
                              <li><img src={RedCross} alt="Img" />{item?.purpose}</li>
                            ):null}
                          </>
                        )
                      })}
                    </ul>
                  </div>
                ):(
                  <div className="Postbox">
                    {/* <h3>No Digital Postbox Address Found.</h3> */}
                    <h3>{contentDetails?.your_details_page_data?.noDigitalPostboxAddressFound}</h3>
                  </div>
                ):(
                  <></>
              )}
            </div>
          </div>
          {/* {selectedCountryById && selectedCityId ? ( */}
          {selectedAddressId ? (
            <div className="FrmBox">
              {/* <h4>Specify your Postbox</h4> */}
              <h4>{contentDetails?.your_details_page_data?.specifyYourPostbox}</h4>

              <div className="FrmBody">
                <Row className="mb-5">
                  {/* <Col sm={6}>
                    <h2>Your optimal tariff is LITE</h2>
                    <p>
                      You can change your tariff at any time, if
                      <br />
                      your letter volume changes.
                    </p>
                  </Col> */}
                  <Col sm={12} md={6}></Col>
                </Row>

                <Row className="PeriodDiv">
                  <Col sm={12} md={6}>
                    <h2>
                      {/* <strong>Contract Period</strong> */}
                      <strong>{contentDetails?.your_details_page_data?.contractPeriod}</strong>
                    </h2>
                  </Col>

                  {/* <Col sm={6}>
                    <div className="radioGrp">
                      <div className="form-check checkFrm">
                        <Form.Check id="monthly" type="radio" name="address" />
                        <Form.Label className="form-check-label" for="monthly">
                          Monthly
                        </Form.Label>
                      </div>

                      <div className="form-check checkFrm">
                        <Form.Check id="yearly" type="radio" name="address" />
                        <Form.Label className="form-check-label" for="yearly">
                          Yearly
                          <p>1 months for free</p>
                        </Form.Label>
                      </div>

                      <div className="form-check checkFrm">
                        <Form.Check id="biennial" type="radio" name="address" />
                        <Form.Label className="form-check-label" for="biennial">
                          Biennial
                          <p>3 months for free</p>
                        </Form.Label>
                      </div>
                    </div>
                  </Col> */}

                  
                  <Col sm={12} md={6}>
                    <div className="radioGrp">
                    {showContractPeriods?.map((item,index)=>{
                      return(
                        <div className="form-check checkFrm">
                          {/* contract_period_id,product_id,location_ids */}
                          <Form.Check id={item.name.toLowerCase()} defaultChecked={index === 0 ? true : false} type="radio" name="contract"  value={item.id}  onChange={(e)=>{handleContractPlan(item?.id,item?.product_id,item?.location_id);setContractPeriodId(e.target.value);setRadioValue('')}}/>
                          {/* <Form.Check id={item.name.toLowerCase()} type="radio" name="address"  value={item.id} onChange={(e)=>{handleContractPlan(e.target.value,'');setContractPeriodId(e.target.value);setRadioValue('')}}/>   */}
                          <Form.Label className="form-check-label" for={item.name.toLowerCase()}>
                          {item.name.toLowerCase()}
                          </Form.Label>
                        </div>
                      )
                    })}
                    </div>
                  </Col>
                </Row>
                 {/* New Section Added start */}

                 <div className="PlanComparisonBtn">
                  <span onClick={() => setPlanCompShow(!planCompShow)}>
                    {/* Plan Comparison */}
                    {contentDetails?.your_details_page_data?.planComparison}
                  </span>
                </div>
                
                {planCompShow ? (
                  <div className="PlanComparisonDiv">
                    <table>
                          <tr>
                            <th>&nbsp;</th>
                            
                            {showContractPlans?.map((item,index)=>{
                              return(
                                <th>
                                  <h2>{item?.title}</h2> € {item?.price}
                                  <br />{" "}
                                  <span>per {item?.period}</span>
                                  <p>
                                    {item?.description}
                                  </p>
                                </th>
                              )
                            })}
                            {/* <th>
                              <h2>LITE</h2>$ 9.95 <br />{" "}
                              <span> per month</span>
                              <p>
                                When you expect around 10 letters/ packages a month.
                              </p>
                            </th> */}
                             {/* <th>
                              <h2>STANDARD</h2>$ 45.95 <br />{" "}
                              <span> per month</span>
                              <p>
                                When you expect around 10 letters/ packages a month.
                              </p>
                            </th> */}
                            {/* <th>
                              <h2>PRO</h2>$ 64.95 <br /> <span> per month</span>
                              <p>For high postal traffic at lowest cost</p>
                            </th> */}
                          </tr>
                        {/* )
                      })} */}
                     

                      {/* <tr>
                        <td>Pricing of activities - see full pricing</td>
                        <td>High</td>
                        <td>Medium</td>
                        <td>Low</td>
                      </tr> */}
                      <tr>
                        {/* <td>Included incoming items</td> */}
                        <td>{contentDetails?.your_details_page_data?.Included_incoming_items}</td>
                        {showContractPlans?.map((item,index)=>{
                          return(
                            <td>{item?.included_incoming_items}</td>
                          )
                        })}
                        {/* <td>20</td> */}
                        {/* <td>50</td> */}
                      </tr>

                      <tr>
                        {/* <td>Envelope scanning (front)</td> */}
                        <td>{contentDetails?.your_details_page_data?.Envelope_scanning_front}</td>
                        {showContractPlans?.map((item,index)=>{
                          return(
                            <td>{item?.envelope_scanning}</td>
                          )
                        })}
                        {/* <td>5</td> */}
                        {/* <td>10</td> */}
                      </tr>

                      <tr>
                        {/* <td>Item content scanning</td> */}
                        <td>{contentDetails?.your_details_page_data?.Item_content_scanning}</td>
                        {showContractPlans?.map((item,index)=>{
                          return(
                            <td>{item?.item_content_scanning}</td>
                          )
                        })}
                        {/* <td>5</td> */}
                        {/* <td>10</td> */}
                      </tr>

                      <tr>
                        {/* <td>Free storage days (letters)</td> */}
                        <td>{contentDetails?.your_details_page_data?.Free_storage_days_letters}</td>
                        {showContractPlans?.map((item,index)=>{
                          return(
                            <td>{item?.free_storage_days_letters}</td>
                          )
                        })}
                        {/* <td>5</td>
                        <td>10</td>
                        <td>20</td> */}
                      </tr>

                      <tr>
                        {/* <td>Free storage days (parcels)</td> */}
                        <td>{contentDetails?.your_details_page_data?.Free_storage_days_parcels}</td>
                        {showContractPlans?.map((item,index)=>{
                          return(
                            <td>{item?.free_storage_days_parcels}</td>
                          )
                        })}
                        {/* <td>2</td>
                        <td>5</td>
                        <td>10</td> */}
                      </tr>

                      <tr>
                        {/* <td>Trashing items</td> */}
                        <td>{contentDetails?.your_details_page_data?.Trashing_items}</td>
                        {showContractPlans?.map((item,index)=>{
                          return(
                            <td>{item?.trashing_items}</td>
                          )
                        })}
                        {/* <td>0</td>
                        <td>0</td>
                        <td>0</td> */}
                      </tr>

                      <tr>
                        {/* <td>Dropbox connection</td> */}
                        <td>{contentDetails?.your_details_page_data?.Dropbox_connection}</td>
                        {showContractPlans?.map((item,index)=>{
                          return(
                            <td>{item?.dropbox_connection}</td>
                          )
                        })}
                        {/* <td>x</td>
                        <td>x</td>
                        <td>x</td> */}
                      </tr>

                      <tr>
                        {/* <td>Additional incoming items</td> */}
                        <td>{contentDetails?.your_details_page_data?.Additional_incoming_items}</td>
                        {showContractPlans?.map((item,index)=>{
                          return(
                            <td>
                              {
                                item?.additional_incoming_items!=null ? `€ ${item?.additional_incoming_items}` : ''
                              }
                            </td>
                          )
                        })}

                        {/* <td>$ 2.00</td>
                        <td>$ 1.50</td>
                        <td>$ 1.00</td> */}
                      </tr>

                      <tr>
                        {/* <td>Envelope scanning</td> */}
                        <td>{contentDetails?.your_details_page_data?.Envelope_scanning}</td>
                        {showContractPlans?.map((item,index)=>{
                          return(
                            <td>
                              {
                                item?.envelope_scanning!=null ? `€ ${item?.envelope_scanning}` : ``
                              }
                            </td>
                          )
                        })}

                        {/* <td>$ 1.50</td>
                        <td>$ 1.00</td>
                        <td>$ 0.50</td> */}
                      </tr>

                      <tr>
                        {/* <td>Item content scanning</td> */}
                        <td>{contentDetails?.your_details_page_data?.Item_content_scanning}</td>
                        {showContractPlans?.map((item,index)=>{
                          return(
                            <td>€ {item?.item_content_scanning}</td>
                          )
                        })}
                        {/* <td>$ 2.50</td>
                        <td>$ 2.00</td>
                        <td>$ 1.00</td> */}
                      </tr>

                      <tr>
                        {/* <td>Direct forwarding (charge per incident)</td> */}
                        <td>{contentDetails?.your_details_page_data?.Direct_forwarding_charge_per_incident}</td>
                        {showContractPlans?.map((item,index)=>{
                          return(
                            // <td>€ {item?.direct_forwarding_charge_per_incident == null ? 0 : item?.direct_forwarding_charge_per_incident} on top of <br /> postage</td>
                            <td>€ {item?.direct_forwarding_charge_per_incident == null ? 0 : item?.direct_forwarding_charge_per_incident} {contentDetails?.your_details_page_data?.on_top_of} <br /> {contentDetails?.your_details_page_data?.postage}</td>
                          )
                        })}
                        {/* <td>
                          $ 4.00 + 20,00% on top of <br /> postage
                        </td>
                        <td>
                          $ 3.00 + 15,00% on top of <br /> postage
                        </td>
                        <td>
                          $ 2.00 + 10,00% on top of <br />
                          postage
                        </td> */}
                      </tr>

                      <tr>
                        {/* <td>Collect forwarding (charge per incident)</td> */}
                        <td>{contentDetails?.your_details_page_data?.Collect_forwarding_charge_per_incident}</td>
                        {showContractPlans?.map((item,index)=>{
                          return(
                            <td>€ {item?.collect_forwarding_charge_per_incident == null ? 0 : item?.collect_forwarding_charge_per_incident} {contentDetails?.your_details_page_data?.on_top_of} <br /> {contentDetails?.your_details_page_data?.postage}</td>
                          )
                        })}
                        {/* <td>
                          $ 6.00 + 20,00% on top of <br /> postage
                        </td>
                        <td>
                          $ 4.00 + 15,00% on top of <br /> postage
                        </td>
                        <td>
                          $ 2.00 + 10,00% on top of <br /> postage
                        </td> */}
                      </tr>

                      <tr>
                        {/* <td>Storing items over free period (letters)</td> */}
                        <td>{contentDetails?.your_details_page_data?.Storing_items_over_free_period_letters}</td>
                        {showContractPlans?.map((item,index)=>{
                          return(
                            <td>€ {item?.storing_items_over_free_period_letters}</td>
                          )
                        })}
                        {/* <td>$ 0.15</td>
                        <td>$ 0.10</td>
                        <td>$ 0.05</td> */}
                      </tr>

                      <tr>
                        {/* <td>Storing items over free period (packages)</td> */}
                        <td>{contentDetails?.your_details_page_data?.Storing_items_over_free_period_packages}</td>
                        {showContractPlans?.map((item,index)=>{
                          return(
                            <td>€ {item?.storing_items_over_free_period_packages}</td>
                          )
                        })}
                        {/* <td>$ 1.00</td>
                        <td>$ 0.75</td>
                        <td>$ 0.50</td> */}
                      </tr>

                      <tr>
                        {/* <td>Scan of additional pages</td> */}
                        <td></td>
                        {/* <td>$ 0.88</td>
                        <td>$ 0.60</td>
                        <td>$ 0.40</td> */}
                        {showContractPlans?.map((item,index)=>{
                          return(
                            <td>€ {item?.scan_of_additional_pages}</td>
                          )
                        })}
                      </tr>

                      <tr>
                        {/* <td>Pages included in content scan</td> */}
                        <td>{contentDetails?.your_details_page_data?.Pages_included_in_content_scan}</td>
                        {showContractPlans?.map((item,index)=>{
                          return(
                            <td>{item?.pages_included_in_content_scan}</td>
                          )
                        })}
                        {/* <td>4</td>
                        <td>6</td>
                        <td>8</td> */}
                      </tr>

                    </table>
                  </div>
                ) : null}

                 {/* New Section Added end*/}
                
                {showContractPlans?.length > 0 ? (
                  <div className="planBox">
                    <div className="radioGrp">
                      {showContractPlans?.map((item,index)=>{
                        return(
                          <div
                            className={
                              radioValue === `${item.title.toLowerCase()}`
                                ? "form-check checkFrm radioActive"
                                : "form-check checkFrm"
                            }
                          >
                            <Form.Check
                              id={item.title.toLowerCase()}
                              type="radio"
                              name="plan"
                              value={item.id}
                              onClick={(e) => {setContractPlanId(e.target.value);setRadioValue(item.title.toLowerCase())}}
                              defaultChecked ={index == 0 ? true: false}
                            />
                            <Form.Label className="form-check-label" for={item.title.toLowerCase()}>
                              {item.title}
                              <p>
                                € {item.price}/{item.period}
                                <br />
                                {/* <span>{item.description}</span> */}
                                {/* <span>{item?.included_incoming_items} items per {item.period} included</span> */}
                                <span>{item?.included_incoming_items} {item?.included_incoming_items_text}</span>
                              </p>
                              <span>{item?.purpose}</span>
                            </Form.Label>
                          </div>
                        )
                      })}
                      
                      {/* <div
                        className={
                          radioValue === "lite"
                            ? "form-check checkFrm radioActive"
                            : "form-check checkFrm"
                        }
                      >
                        <Form.Check
                          id="lite"
                          type="radio"
                          name="plan"
                          onClick={() => setRadioValue("lite")}
                        />
                        <Form.Label className="form-check-label" for="lite">
                          LITE
                          <p>
                            $ 9.95/month
                            <br />
                            <span>5 items per month included</span>
                          </p>
                        </Form.Label>
                      </div>

                      <div
                        className={
                          radioValue === "standard"
                            ? "form-check checkFrm radioActive"
                            : "form-check checkFrm"
                        }
                      >
                        <Form.Check
                          id="stander"
                          type="radio"
                          name="plan"
                          onClick={() => setRadioValue("standard")}
                        />
                        <Form.Label className="form-check-label" for="stander">
                          STANDARD
                          <p>
                            $ 19.95/month
                            <br />
                            <span>20 items per month included</span>
                          </p>
                        </Form.Label>
                      </div>

                      <div
                        className={
                          radioValue === "pro"
                            ? "form-check checkFrm radioActive"
                            : "form-check checkFrm"
                        }
                      >
                        <Form.Check
                          id="pro"
                          type="radio"
                          name="plan"
                          onClick={() => setRadioValue("pro")}
                        />
                        <Form.Label className="form-check-label" for="pro">
                          PRO
                          <p>
                            $ 34.95/month
                            <br />
                            <span>50 items per month included</span>
                          </p>
                        </Form.Label>
                      </div> */}

                    </div>
                  </div>
                 ):(
                  <div className="planBox1">
                    <div className="radioGrp"></div>
                  </div>
                )}

                <Row className="">
                  <Col sm={12} md={6}>
                    <h2>
                      {/* How many letters/packages do
                      <br /> you expect per month? */}
                      {contentDetails?.your_details_page_data?.howManyLettersOrPackages}
                    </h2>
                  </Col>
                  <Col sm={12} md={6}>
                    <div>
                      <Row>
                        <Col sm={12} md={9}>
                          <Form.Group className="mb-4 text-left">
                            {/* <Form.Label>Number of Letters</Form.Label> */}
                            <Form.Label>{contentDetails?.your_details_page_data?.NumberOfLetters_label}</Form.Label>
                            {/* <Form.Control type="text" placeholder="How many letters you want?" value={numberOfLetters} onChange={handleNumberOfLetters}/> */}
                            <Form.Control type="text" placeholder={contentDetails?.your_details_page_data?.NumberOfLetters_placeholder} value={numberOfLetters} onChange={handleNumberOfLetters}/>
                          </Form.Group>
                        </Col>

                        <Col sm={12} md={3}>
                          <Button
                            variant="primary"
                            type="submit"
                            className="ConfirmBtn"
                            onClick={()=>handleCheckLetters()}
                          >
                            {/* Confirm */}
                            {contentDetails?.your_details_page_data?.NumberOfLetters_button_text}
                          </Button>
                        </Col>
                      </Row>
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          ) : null}
          <div className="FrmBox">
            {/* <h4>Specify your Postbox</h4> */}
            <h4>{contentDetails?.your_details_page_data?.specifyYourPostbox}</h4>

            <div className="FrmBody">
              <Row className="">
                <Col sm={12} md={6}>
                  {/* <h2>Recipient's name</h2> */}
                  <h2>{contentDetails?.your_details_page_data?.recipientsName}</h2>
                  <p>
                    {/* Please enter your name or the company
                    <br /> name and legal form. */}
                    {contentDetails?.your_details_page_data?.pleaseEnterYourNameOrTheCompanyNameAndLegalForm}
                  </p>
                </Col>
                <Col sm={12} md={6}>
                {selectedCustomerType == 1 ? (
                  <div>
                    <Form.Group className="mb-4 text-left">
                      {/* <Form.Label>Full Name</Form.Label> */}
                      <Form.Label>{contentDetails?.your_details_page_data?.fullName_label}</Form.Label>
                      <Form.Control
                        id="pp_name"
                        type="text" 
                        // placeholder="Enter your name"
                        placeholder={contentDetails?.your_details_page_data?.fullName_placeholder}
                        name="fullName" 
                        value={recipientName} 
                        onChange={(e)=>setRecipientName(e.target.value)}
                        onKeyDown={(e)=>{
                          if(e.key === 'Enter' || e.key === 'Tab')
                          {
                            e.preventDefault();
                            document.getElementById("pp_company_name").focus();
                          }
                        }}
                      />
                      {showError && (recipientName === undefined || recipientName.trim() === '') && (
                        <span className="error">{errors.r_full_name}</span>
                      )}
                    </Form.Group>
                    
                    <Form.Group className="mb-4 text-left">
                      {/* <Form.Label>Company Name (Optional)</Form.Label> */}
                      <Form.Label>{contentDetails?.your_details_page_data?.companyNameOptional_label}</Form.Label>
                      <Form.Control
                        id='pp_company_name'
                        type="text" 
                        // placeholder="Type your company name"
                        placeholder={contentDetails?.your_details_page_data?.companyNameOptional_placeholder}
                        name="companyName" 
                        value={companyName} 
                        onChange={(e)=>setCompanyNameName(e.target.value)}
                        onKeyDown={(e)=>{
                          if(e.key === 'Enter' || e.key === 'Tab')
                          {
                            e.preventDefault();
                            document.getElementById("pp_country").focus();
                          }
                        }}
                      />
                      {/* {showError && (companyName === undefined || companyName.trim() === '') && (
                        <span className="error">{errors.r_company_name}</span>
                      )} */}
                    </Form.Group>
                    <Row>
                      <Col sm={6}>
                        <Form.Group className="FrmRgt mb-4">
                          {/* <Form.Label>Country (Optional)</Form.Label> */}
                          <Form.Label>{contentDetails?.your_details_page_data?.countryOptional_label}</Form.Label>
                          <Form.Select 
                            id='pp_country'
                            aria-label="Default select example"
                            onChange={(e)=>{handleRegionsByCountryId(e.target.value);handleLegalFormByCountryId(e.target.value);setRecipientCountryId(e.target.value)}}
                            onKeyDown={(e)=>{
                              if(e.key === 'Enter' || e.key === 'Tab')
                              {
                                e.preventDefault();
                                document.getElementById("pp_region").focus();
                              }
                            }}
                          >
                            {/* <option selected hidden>Select Country</option> */}
                            <option selected hidden>{contentDetails?.your_details_page_data?.countryOptional_label}</option>
                            {selectedLegalCountries.length > 0 ? (
                              selectedLegalCountries.map((item,index)=>{
                                return(
                                  <option value={item.id}>{item.name}</option>
                                )
                              })
                            ):(
                              // <option disabled>No Country Found</option>
                              <option disabled>{languageJson?.countryNotFound}</option>
                            )}
                          </Form.Select>
                          {/* {showError && (recipientCountryId === undefined || recipientCountryId.trim() === '') && (
                            <span className="error">{errors.r_country}</span>
                          )} */}
                        </Form.Group>
                      </Col>

                      <Col sm={6}>
                        <Form.Group className="FrmRgt mb-4">
                          {/* <Form.Label>Region (Optional)</Form.Label> */}
                          <Form.Label>{contentDetails?.your_details_page_data?.regionOptional_label}</Form.Label>
                          <Form.Select
                            id="pp_region"
                            aria-label="Default select example"
                            onChange={(e)=>setRecipientRegionId(e.target.value)}
                            onKeyDown={(e)=>{
                              if(e.key === 'Enter' || e.key === 'Tab')
                              {
                                e.preventDefault();
                                document.getElementById("pp_legal_form").focus();
                              }
                            }}
                          >
                            {/* <option selected hidden>Select Region</option> */}
                            <option selected hidden>{contentDetails?.your_details_page_data?.regionOptionalSelectBoxDefaultText}</option>
                            {selectedLegalRegionByCountryId.length > 0 ? (
                              selectedLegalRegionByCountryId.map((item,index)=>{
                                return(
                                  <option value={item.id}>{item.name}</option>
                                )
                              })
                            ):(
                              // <option disabled>No Region Found</option>
                              <option disabled>{languageJson?.noRegionFound}</option>
                            )}
                          </Form.Select>
                          {/* {showError && (recipientRegionId === undefined || recipientRegionId.trim() === '') && (
                            <span className="error">{errors.r_region}</span>
                          )} */}
                        </Form.Group>
                      </Col>
                    </Row>

                    {/* <Form.Group className="FrmRgt">
                      <Form.Label>{contentDetails?.your_details_page_data?.legalFormOptional_label}</Form.Label>
                      
                      <Form.Select
                        id = "pp_legal_form"
                        aria-label="Default select example"
                        onChange={(e)=>setRecipientLegalFormId(e.target.value)}
                        onKeyDown={(e)=>{
                          if(e.key === 'Enter' || e.key === 'Tab')
                          {
                            e.preventDefault();
                            document.getElementById("title").focus();
                          }
                        }}
                      >
                      <option selected hidden>{contentDetails?.your_details_page_data?.legalFormOptionalSelectBoxDefaultText}</option>
                        {selectedLegalFormByCountryId.length > 0 ? (
                          selectedLegalFormByCountryId.map((item,index)=>{
                            return(
                              <option value={item.id}>{item.legal_form}</option>
                            )
                          })
                        ):(
                          <option disabled>{languageJson?.noLegalFormFound}</option>
                        )}
                      </Form.Select>
                    </Form.Group> */}

                  </div>
                ):
                selectedCustomerType == 2 ? (
                  <div>
                    <Form.Group className="mb-4 text-left">
                      <Form.Label>{contentDetails?.your_details_page_data?.fullName_label}(optional)</Form.Label>
                      <Form.Control
                        type="text"
                        // placeholder="Enter your name"
                        placeholder={contentDetails?.your_details_page_data?.fullName_placeholder}
                        name="fullName"
                        value={recipientName} 
                        onChange={(e)=>setRecipientName(e.target.value)}
                        onKeyDown={(e)=>{
                          if(e.key === 'Enter' || e.key === 'Tab')
                          {
                            e.preventDefault();
                            document.getElementById("bp_comapny_name").focus();
                          }
                        }}
                      />
                      {/* {showError && (recipientName === undefined || recipientName.trim() === '') && (
                        <span className="error">{errors.r_full_name}</span>
                      )} */}
                    </Form.Group>
                    
                    <Form.Group className="mb-4 text-left">
                      {/* <Form.Label>Company Name</Form.Label> */}
                      <Form.Label>{contentDetails?.your_details_page_data?.companyName_label}</Form.Label>
                      <Form.Control 
                        id="bp_comapny_name"
                        type="text"
                        // placeholder="Type your company name"
                        placeholder={contentDetails?.your_details_page_data?.companyNameOptional_placeholder}
                        name="companyName" value={companyName}
                        onChange={(e)=>setCompanyNameName(e.target.value)}
                        onKeyDown={(e)=>{
                          if(e.key === 'Enter' || e.key === 'Tab')
                          {
                            e.preventDefault();
                            document.getElementById("bp_country").focus();
                          }
                        }}
                      />
                      {showError && (companyName === undefined || companyName.trim() === '') && (
                        <span className="error">{errors.r_company_name}</span>
                      )}
                    </Form.Group>
                    <Row>
                      <Col sm={6}>
                        <Form.Group className="FrmRgt mb-4">
                          {/* <Form.Label>Country(Optional)</Form.Label> */}
                          <Form.Label>{contentDetails?.your_details_page_data?.countryOptional_label}</Form.Label>
                          <Form.Select
                            id="bp_country"
                            aria-label="Default select example"
                            onChange={(e)=>{handleRegionsByCountryId(e.target.value);handleLegalFormByCountryId(e.target.value);setRecipientCountryId(e.target.value)}}
                            onKeyDown={(e)=>{
                              if(e.key === 'Enter' || e.key === 'Tab')
                              {
                                e.preventDefault();
                                document.getElementById("bp_region").focus();
                              }
                            }}
                          >
                            {/* <option selected hidden>Select Country</option> */}
                            <option selected hidden>{contentDetails?.your_details_page_data?.countryOptional_label}</option>
                            {selectedLegalCountries.length > 0 ? (
                              selectedLegalCountries.map((item,index)=>{
                                return(
                                  <option value={item.id}>{item.name}</option>
                                )
                              })
                            ):(
                              <option disabled>{languageJson?.countryNotFound}</option>
                            )}
                          </Form.Select>
                          {/* {showError && (recipientCountryId === undefined || recipientCountryId.trim() === '') && (
                            <span className="error">{errors.r_country}</span>
                          )} */}
                        </Form.Group>
                      </Col>

                      <Col sm={6}>
                        <Form.Group className="FrmRgt mb-4">
                          {/* <Form.Label>Region (Optional)</Form.Label> */}
                          <Form.Label>{contentDetails?.your_details_page_data?.regionOptional_label}</Form.Label>
                          <Form.Select
                            id="bp_region"
                            aria-label="Default select example"
                            onChange={(e)=>setRecipientRegionId(e.target.value)}
                            onKeyDown={(e)=>{
                              if(e.key === 'Enter' || e.key === 'Tab')
                              {
                                e.preventDefault();
                                document.getElementById("title").focus();
                              }
                            }}
                          >
                            {/* <option selected hidden>Select Region</option> */}
                            <option selected hidden>{contentDetails?.your_details_page_data?.regionOptionalSelectBoxDefaultText}</option>
                            {selectedLegalRegionByCountryId.length > 0 ? (
                              selectedLegalRegionByCountryId.map((item,index)=>{
                                return(
                                  <option value={item.id}>{item.name}</option>
                                )
                              })
                            ):(
                              <option disabled>{languageJson?.noRegionFound}</option>
                            )}
                          </Form.Select>
                          {/* {showError && (recipientRegionId === undefined || recipientRegionId.trim() === '') && (
                            <span className="error">{errors.r_region}</span>
                          )} */}
                        </Form.Group>
                      </Col>
                    </Row>

                    <Form.Group className="FrmRgt">
                      {/* <Form.Label>Legal form (Optional)</Form.Label> */}
                      <Form.Label>{contentDetails?.your_details_page_data?.legalFormOptional_label}</Form.Label>
                      <Form.Select aria-label="Default select example" onChange={(e)=>setRecipientLegalFormId(e.target.value)}>
                      {/* <option selected hidden>Select Legal Form</option> */}
                      <option selected hidden>{contentDetails?.your_details_page_data?.legalFormOptionalSelectBoxDefaultText}</option>
                        {selectedLegalFormByCountryId.length > 0 ? (
                          selectedLegalFormByCountryId.map((item,index)=>{
                            return(
                              <option value={item.id}>{item.legal_form}</option>
                            )
                          })
                        ):(
                          <option disabled>{languageJson?.noLegalFormFound}</option>
                        )}
                      </Form.Select>
                      {/* {showError && (recipientLegalFormId === undefined || recipientLegalFormId.trim() === '') && (
                        <span className="error">{errors.r_legal_form}</span>
                      )} */}
                    </Form.Group>

                  </div>
                ):null}
                </Col>
              </Row>
            </div>

            <div className="FrmBody">
              <Row className="">
                <Col sm={12} md={6}>
                  <h2>{contentDetails?.your_details_page_data?.billingAddress}</h2>
                  <p>{contentDetails?.your_details_page_data?.billingAddress_below_text}</p>
                </Col>
                <Col sm={12} md={6}>
                  <div>
                  <Row className="DetailsList">
                    <Col sm={6} className="pr-5">
                      <Form.Group className="mb-4 text-left">
                        <Form.Label>{contentDetails?.your_details_page_data?.title_label}</Form.Label>
                        <Form.Select 
                          aria-label="Default select example" 
                          id="title" 
                          name="title" 
                          onChange={handleChangeBillingAddress}
                          onKeyDown={(e)=>{
                            if(e.key === 'Enter' || e.key === 'Tab')
                            {
                              e.preventDefault();
                              document.getElementById("first_name").focus();
                            }
                          }}
                        >
                          <option value="" selected hidden>{contentDetails?.your_details_page_data?.Select_Title}</option>
                          <option value="Mr." selected={formDataBillingAddress?.title === "Mr."}>{contentDetails?.your_details_page_data?.Mr}</option>
                          <option value="Mrs." selected={formDataBillingAddress?.title === "Mrs."}>{contentDetails?.your_details_page_data?.Mrs}</option>
                          <option value="Dr." selected={formDataBillingAddress?.title === "Dr."}>{contentDetails?.your_details_page_data?.Dr}</option>
                          <option value="Prof.Dr." selected={formDataBillingAddress?.title === "Prof.Dr."}>{contentDetails?.your_details_page_data?.Prof_Dr}</option>
                        </Form.Select>
                        {formErrorsBillingAddress.title && (<span className="error-message">{formErrorsBillingAddress.title}</span>)}
                      </Form.Group>

                      <Form.Group className="mb-4 text-left">
                        <Form.Label>{contentDetails?.your_details_page_data?.lastName_label}</Form.Label>
                        <Form.Control
                          id="last_name"
                          name="last_name"
                          value={formDataBillingAddress.last_name}
                          onChange={handleChangeBillingAddress}
                          type="text"
                          placeholder={contentDetails?.your_details_page_data?.lastName_placeholder}
                          onKeyDown={(e)=>{
                            if(e.key === 'Enter' || e.key === 'Tab')
                            {
                              e.preventDefault();
                              document.getElementById("company_name").focus();
                            }
                          }}
                        />
                        {formErrorsBillingAddress.last_name && <span className="error-message">{formErrorsBillingAddress.last_name}</span>}
                        
                      </Form.Group>

                      <Form.Group className="mb-4 text-left">
                        <Form.Label>{contentDetails?.your_details_page_data?.address_label}</Form.Label>
                        <Form.Control
                          id="address"
                          name="address"
                          value={formDataBillingAddress.address}
                          onChange={handleChangeBillingAddress}
                            type="text"
                            placeholder={contentDetails?.your_details_page_data?.address_placeholder}
                          onKeyDown={(e)=>{
                            if(e.key === 'Enter' || e.key === 'Tab')
                            {
                              e.preventDefault();
                              document.getElementById("country_id").focus();
                            }
                          }}
                        />
                        {formErrorsBillingAddress.address && <span className="error-message">{formErrorsBillingAddress.address}</span>}
                      </Form.Group>
                      
                      <Form.Group className="mb-4 text-left">
                        <Form.Label>{contentDetails?.your_details_page_data?.region_label}</Form.Label>
                        <Form.Select 
                        aria-label="Default select example" 
                        id="region" 
                        name="region_id"  
                        onChange={handleRegionTOCity} 
                        onKeyDown={(e)=>{
                          if(e.key === 'Enter' || e.key === 'Tab')
                          {
                            e.preventDefault();
                            document.getElementById("city").focus();
                          }
                        }}
                        >
                        <option value="" selected hidden>{contentDetails?.your_details_page_data?.regionSelectBoxDefaultText}</option>
                        {selectedLegalRegionByCountryId2.length > 0 ? (
                          selectedLegalRegionByCountryId2.map((item,index)=>{
                            return(
                              <option value={item.id} selected={formDataBillingAddress?.region_id === item.id}>{item.name}</option>
                            )
                          })
                        ):(
                          <option disabled>{languageJson?.noRegionFound}</option>
                        )}
                        </Form.Select>
                        {formErrorsBillingAddress.region_id && (<span className="error-message">{formErrorsBillingAddress.region_id}</span>)}
                      </Form.Group>

                      <Form.Group className="mb-4 text-left">
                        <Form.Label>{contentDetails?.your_details_page_data?.postalCode_label}</Form.Label>
                        <Form.Control 
                          id="postal_code"
                          name="postal_code"
                          value={formDataBillingAddress.postal_code}
                          onChange={handleChangeBillingAddress}
                          type="number"
                          placeholder={contentDetails?.your_details_page_data?.postalCode_placeholder}
                          onKeyDown={(e)=>{
                            if(e.key === 'Enter' || e.key === 'Tab')
                            {
                              e.preventDefault();
                              document.getElementById("phone_number").focus();
                            }
                          }}
                        />
                        {formErrorsBillingAddress.postal_code && <span className="error-message">{formErrorsBillingAddress.postal_code}</span>}
                      </Form.Group>
                    </Col>

                    <Col sm={6} className="pl-5">
                      <Form.Group className="mb-4 text-left">
                      <Form.Label>{contentDetails?.your_details_page_data?.First_Name}</Form.Label>
                        <Form.Control
                          id="first_name"
                          name="first_name"
                          value={formDataBillingAddress.first_name}
                          onChange={handleChangeBillingAddress}
                          type="text"
                          placeholder={contentDetails?.your_details_page_data?.Enter_First_Name}
                          onKeyDown={(e)=>{
                            if(e.key === 'Enter' || e.key === 'Tab')
                            {
                              e.preventDefault();
                              document.getElementById("last_name").focus();
                            }
                          }}
                        />
                        {formErrorsBillingAddress.first_name && <span className="error-message">{formErrorsBillingAddress.first_name}</span>}
                      </Form.Group>  

                      <Form.Group className="mb-4 text-left">
                        <Form.Label>{contentDetails?.your_details_page_data?.companyName_label}</Form.Label>
                        <Form.Control
                          id="company_name"
                          name="company_name"
                          value={formDataBillingAddress.company_name}
                          onChange={handleChangeBillingAddress}
                          type="text"
                          placeholder={contentDetails?.your_details_page_data?.companyName_placeholder}
                          onKeyDown={(e)=>{
                            if(e.key === 'Enter' || e.key === 'Tab')
                            {
                              e.preventDefault();
                              document.getElementById("address").focus();
                            }
                          }}
                        />
                        {formErrorsBillingAddress.company_name && <span className="error-message">{formErrorsBillingAddress.company_name}</span>}
                      </Form.Group>  

                      <Form.Group className="mb-4 text-left">
                        <Form.Label>{contentDetails?.your_details_page_data?.country_label}</Form.Label>
                        <Form.Select 
                          id="country_id"
                          aria-label="Default select example" 
                          name="country_id" 
                          onChange={handleCountryTOState}
                          onKeyDown={(e)=>{
                            if(e.key === 'Enter' || e.key === 'Tab')
                            {
                              e.preventDefault();
                              document.getElementById("region").focus();
                            }
                          }}
                        >
                        <option value="" selected hidden>{contentDetails?.your_details_page_data?.countrySelectBoxDefaultText}</option>
                        {showAllCountries2.map((item,index)=>{
                          return(
                            <option value={item.id} selected={formDataBillingAddress?.country_id === item.id}>{item.name}</option>
                          )
                        })}
                        </Form.Select>
                        {formErrorsBillingAddress.country_id && <span className="error-message">{formErrorsBillingAddress.country_id}</span>}
                      </Form.Group>

                      <Form.Group className="mb-4 text-left">
                        <Form.Label>{contentDetails?.your_details_page_data?.city_label}</Form.Label>
                        <Form.Select 
                          id="city"
                          aria-label="Default select example" 
                          name="city_id" 
                          onChange={handleChangeBillingAddress}
                          onKeyDown={(e)=>{
                            if(e.key === 'Enter' || e.key === 'Tab')
                            {
                              e.preventDefault();
                              document.getElementById("postal_code").focus();
                            }
                          }}
                        >
                        <option value="" selected hidden>{contentDetails?.your_details_page_data?.citySelectBoxDefaultText}</option>
                        {selectedCityWithId2?.length > 0 ? (
                          selectedCityWithId2?.map((item,index)=>{
                            return(
                              <option value={item.id} selected={formDataBillingAddress?.city_id === item.id}>{item?.name}</option>
                            )})
                        ):(
                          <option disabled>{languageJson?.cityNotFound}</option>
                        )}
                        </Form.Select>
                        {formErrorsBillingAddress.city_id && <span className="error-message">{formErrorsBillingAddress.city_id}</span>}
                      </Form.Group>
                        
                      <Form.Group className="mb-4 text-left">
                        <Form.Label>{contentDetails?.your_details_page_data?.phoneNumber_label}</Form.Label>
                        <InputGroup className="mb-3">
                          <InputGroup.Text id="basic-addon1">
                            {billingPhoneCode? `+${billingPhoneCode}`:null}
                          </InputGroup.Text>
                          <Form.Control
                            id="phone_number"
                            type="number"
                            placeholder={contentDetails?.your_details_page_data?.phoneNumber_placeholder}
                            name="phone_number"
                            aria-label="Username"
                            aria-describedby="basic-addon1"
                            value={formDataBillingAddress.phone_number}
                            onChange={(e) => {
                              if (e.target.value.length <= 12) {
                                handleChangeBillingAddress(e);
                              }
                            }}
                          />
                        </InputGroup>
                        {formErrorsBillingAddress.phone_number && <span className="error-message">{formErrorsBillingAddress.phone_number}</span>}
                      </Form.Group>
                    </Col>
                  </Row>

                    <div className="form-check checkFrm">
                      <Form.Check
                        aria-label="option 1"
                        id="flexCheckChecked2"
                        onChange={(e) => setCheckVatValue(e.target.checked)}
                      />
                      <Form.Label
                        className="form-check-label"
                        for="flexCheckChecked2"
                      >
                        {contentDetails?.your_details_page_data?.addVATNumberOnlyForEUCompany}
                      </Form.Label>
                    </div>

                    {checkVatValue ? (
                      <div className="VatBox">
                        <Row>
                          <Col sm={7}>
                            <Form.Group className="mb-4 text-left">
                              <Form.Control
                                name="vat_number"
                                value={formDataBillingAddress.vat_number}
                                onChange={handleChangeBillingAddress}
                                onKeyDown={handleKeyPress}
                                type="text"
                                placeholder={contentDetails?.your_details_page_data?.addVATNumberOnlyForEUCompany_placeholder}
                              />
                            </Form.Group>
                          </Col>
                          <Col sm={4}>
                            {isChecked ? (    
                              <Button className="lftBtn btn btn-secondary mb-4" disabled>
                                {languageJson?.checking}..
                              </Button>
                            ):(
                              <>
                                <Button className="lftBtn btn btn-secondary mb-4" onClick={()=>handleCheckingVATNumber(formDataBillingAddress.vat_number)}>
                                  {contentDetails?.your_details_page_data?.addVATNumberOnlyForEUCompany_button_text}
                                </Button>
                                <a href="javascript:void(0)" className="TableIcon mb-4 ">
                                  <img src={Remove} alt="Img" onClick={() => setformDataBillingAddress(prevState => ({ ...prevState, vat_number: '' }))} />
                                </a>
                              </>
                            )}
                          </Col>
                        </Row>
                      </div>
                    ) : null}
                  
                  </div>
                </Col>
              </Row>
            </div>


            {/* -- Forwarding address start -- */}

            <div className="FrmBody">
              <Row className="">
                <Col sm={6}>
                  <h2>{contentDetails?.your_details_page_data?.forwardingAddress}</h2>
                  <p>{contentDetails?.your_details_page_data?.forwardingAddress_below_text}</p>
                </Col>
                <Col sm={6}>
                  <div>
                    <div className="form-check checkFrm">
                      <Form.Check
                        aria-label="option 1"
                        id="flexCheckChecked3"
                        checked={sameAsBillingValue}
                        onChange={(e) =>
                          setSameAsBillingValue(e.target.checked)
                        }
                      />
                      <Form.Label
                        className="form-check-label"
                        for="flexCheckChecked3"
                      >
                        {contentDetails?.your_details_page_data?.theSameAsBillingAddress}
                      </Form.Label>
                    </div>
                    {!sameAsBillingValue ? (
                      <div className="mt-4 ">
                        <Row>
                          <Col sm={6}>
                            <Form.Group className="mb-4 text-left">
                              <Form.Label>{contentDetails?.your_details_page_data?.title_label}</Form.Label>
                              <Form.Select 
                                aria-label="Default select example" 
                                name="f_title" 
                                onChange={(e)=>setForwardingAddressTitle(e.target.value)}
                                onKeyDown={(e)=>{
                                  if(e.key === 'Enter' || e.key === 'Tab')
                                  {
                                    e.preventDefault();
                                    document.getElementById("f_first_name").focus();
                                  }
                                }}
                              >
                                <option selected hidden>{contentDetails?.your_details_page_data?.Select_Title}</option>
                                <option value="Mr." selected={forwardingAddressTitle === 'Mr.'}>{contentDetails?.your_details_page_data?.Mr}</option>
                                <option value="Mrs." selected={forwardingAddressTitle === 'Mrs.'}>{contentDetails?.your_details_page_data?.Mrs}</option>
                                <option value="Dr." selected={forwardingAddressTitle === 'Dr.'}>{contentDetails?.your_details_page_data?.Dr}</option>
                                <option value="Prof.Dr." selected={forwardingAddressTitle === 'Prof.Dr.'}>{contentDetails?.your_details_page_data?.Prof_Dr}</option>
                              </Form.Select>
                                {forwardAddressError && forwardingAddressTitle == undefined && (<span className="error">{fwdAddError.title}</span>)}
                            </Form.Group>
                          </Col>
                          <Col sm={6}>
                            <Form.Group className="mb-4 text-left">
                              <Form.Label>{contentDetails?.your_details_page_data?.firstName_label}</Form.Label>
                              <Form.Control
                                id="f_first_name"
                                type="text"
                                placeholder={contentDetails?.your_details_page_data?.firstName_placeholder}
                                name ="f_first_name"
                                value={forwardingAddressFirstName}
                                onChange={(e)=>setForwardingAddressFirstName(e.target.value)}
                                onKeyDown={(e)=>{
                                  if(e.key === 'Enter' || e.key === 'Tab')
                                  {
                                    e.preventDefault();
                                    document.getElementById("f_last_name").focus();
                                  }
                                }}
                              />
                              {forwardAddressError && forwardingAddressFirstName == undefined && (<span className="error">{fwdAddError.last_name}</span>)}
                            </Form.Group>
                          </Col>
                        </Row>
                        <Row>
                          <Col sm={6}>
                            <Form.Group className="mb-4 text-left">
                              <Form.Label>{contentDetails?.your_details_page_data?.lastName_label}</Form.Label>
                              <Form.Control
                                id="f_last_name"
                                type="text"
                                placeholder={contentDetails?.your_details_page_data?.lastName_placeholder}
                                name ="f_last_name"
                                value={forwardingAddressLastName}
                                onChange={(e)=>setForwardingAddressLastName(e.target.value)}
                                onKeyDown={(e)=>{
                                  if(e.key === 'Enter' || e.key === 'Tab')
                                  {
                                    e.preventDefault();
                                    document.getElementById("f_company_name").focus();
                                  }
                                }}
                              /> 
                              {forwardAddressError && forwardingAddressLastName == undefined && (<span className="error">{fwdAddError.last_name}</span>)}
                            </Form.Group>
                          </Col>
                          <Col sm={6}>
                            <Form.Group className="mb-4 text-left">
                              <Form.Label>{contentDetails?.your_details_page_data?.companyName_label}</Form.Label>
                              <Form.Control
                                id="f_company_name"
                                type="text"
                                placeholder={contentDetails?.your_details_page_data?.companyName_placeholder}
                                name ="f_company_name"
                                value={forwardingAddressCompanyName}
                                onChange={(e)=>setForwardingAddressCompanyName(e.target.value)}
                                onKeyDown={(e)=>{
                                  if(e.key === 'Enter' || e.key === 'Tab')
                                  {
                                    e.preventDefault();
                                    document.getElementById("f_address").focus();
                                  }
                                }}
                              />
                              {forwardAddressError && forwardingAddressCompanyName == undefined && (<span className="error">{fwdAddError.company_name}</span>)}
                            </Form.Group>
                          </Col>
                        </Row>
                        <Row>
                          <Col sm={6}>
                            <Form.Group className="mb-4 text-left">
                              <Form.Label>{contentDetails?.your_details_page_data?.address_label}</Form.Label>
                              <Form.Control
                                id="f_address"
                                type="text"
                                placeholder={contentDetails?.your_details_page_data?.address_placeholder}
                                name ="f_address"
                                value={forwardingAddress}
                                onChange={(e)=>setForwardingAddress(e.target.value)}
                                onKeyDown={(e)=>{
                                  if(e.key === 'Enter' || e.key === 'Tab')
                                  {
                                    e.preventDefault();
                                    document.getElementById("f_country").focus();
                                  }
                                }}
                              />
                              {forwardAddressError && forwardingAddress == undefined && (<span className="error">{fwdAddError.address}</span>)}
                            </Form.Group>
                          </Col>
                          <Col sm={6}>
                            <Form.Group className="mb-4 text-left">
                              <Form.Label>{contentDetails?.your_details_page_data?.country_label}</Form.Label>
                              <Form.Select
                                id="f_country"
                                aria-label="Default select example"
                                name ="f_country"
                                onChange={(e)=>{setForwardingAddressCountry(e.target.value);handleRegionsAddressByCountryId(e.target.value)}}
                                onKeyDown={(e)=>{
                                  if(e.key === 'Enter' || e.key === 'Tab')
                                  {
                                    e.preventDefault();
                                    document.getElementById("f_region").focus();
                                  }
                                }}
                              >
                                <option selected hidden>{contentDetails?.your_details_page_data?.countrySelectBoxDefaultText}</option>
                                {showForwardCountries?.map((item,index)=>{
                                  return(
                                    <option value={item.id} selected={item?.id === forwardingAddressCountry}>{item.name}</option>
                                   )
                                })}
                                
                              </Form.Select>
                              {forwardAddressError && forwardingAddressCountry == undefined && (<span className="error">{fwdAddError.country}</span>)}
                            </Form.Group>
                          </Col>
                        </Row>
                        <Row>
                          <Col sm={6}>
                            <Form.Group className="mb-4 text-left">
                              <Form.Label>{contentDetails?.your_details_page_data?.region_label}</Form.Label>
                              <Form.Select 
                                id="f_region"
                                aria-label="Default select example" 
                                name ="f_region" 
                                onChange={(e)=>{setForwardingAddressRegion(e.target.value);getCityByRegionData3(e.target.value)}}
                                onKeyDown={(e)=>{
                                  if(e.key === 'Enter' || e.key === 'Tab')
                                  {
                                    e.preventDefault();
                                    document.getElementById("f_city").focus();
                                  }
                                }}
                              >
                                <option selected hidden>{contentDetails?.your_details_page_data?.regionSelectBoxDefaultText}</option>
                                {selectedLegalRegionByCountryId3.length > 0 ? (
                                  selectedLegalRegionByCountryId3.map((item,index)=>{
                                    return(
                                      <option value={item.id} selected={item?.id === forwardingAddressRegion}>{item.name}</option>
                                    )
                                  })
                                ):(
                                  <option disabled>{languageJson?.noRegionFound}</option>
                                )}
                               
                              </Form.Select>
                              {forwardAddressError && forwardingAddressRegion == undefined && (<span className="error">{fwdAddError.region}</span>)}
                            </Form.Group>
                          </Col>
                          <Col sm={6}>
                            <Form.Group className="mb-4 text-left">
                              <Form.Label>{contentDetails?.your_details_page_data?.city_label}</Form.Label>
                              <Form.Select
                                id="f_city"
                                aria-label="Default select example" 
                                name ="city" 
                                onChange={(e)=>setForwardingAddressCity(e.target.value)}
                                onKeyDown={(e)=>{
                                  if(e.key === 'Enter' || e.key === 'Tab')
                                  {
                                    e.preventDefault();
                                    document.getElementById("f_postal_code").focus();
                                  }
                                }}
                              >
                                <option selected hidden>{contentDetails?.your_details_page_data?.citySelectBoxDefaultText}</option>
                                {selectedLegalRegionByCountryId3.length > 0 ? (
                                  selectedCityWithId3?.map((item,index)=>{
                                  return(
                                    <option value={item.id} selected={item?.id === forwardingAddressCity}>{item?.name}</option>
                                  )})
                                ):(
                                  <option disabled>{languageJson?.cityNotFound}</option>
                                )}
                              </Form.Select>
                              {forwardAddressError && forwardingAddressCity == undefined && (<span className="error">{fwdAddError.city}</span>)}
                            </Form.Group>
                          </Col>
                        </Row>
                        <Row>
                          <Col sm={6}>
                            <Form.Group className="mb-4 text-left">
                              <Form.Label>{contentDetails?.your_details_page_data?.postalCode_label}</Form.Label>
                              <Form.Control
                                id="f_postal_code"
                                type="number"
                                placeholder={contentDetails?.your_details_page_data?.postalCode_placeholder}
                                name="f_postal_code"
                                value={forwardingAddressPostalCode}
                                onChange={(e)=>setForwardingAddressPostalCode(e.target.value)}
                                onKeyDown={(e)=>{
                                  if(e.key === 'Enter' || e.key === 'Tab')
                                  {
                                    e.preventDefault();
                                    document.getElementById("f_phone_number").focus();
                                  }
                                }}
                              />
                              {forwardAddressError && forwardingAddressPostalCode == undefined && (<span className="error">{fwdAddError.postal_code}</span>)}
                            </Form.Group>
                          </Col>
                          <Col sm={6}>
                            <Form.Group className="mb-4 text-left">
                              <Form.Label>{contentDetails?.your_details_page_data?.phoneNumber_label}</Form.Label>
                              <InputGroup className="mb-3">
                                <InputGroup.Text id="basic-addon1">
                                  {forwardAddressPhoneCode? `+${forwardAddressPhoneCode}`:null}
                                </InputGroup.Text>
                                <Form.Control
                                  id="f_phone_number"
                                  type="number"
                                  placeholder={contentDetails?.your_details_page_data?.phoneNumber_placeholder}
                                  name="f_phone_number"
                                  aria-label="Username"
                                  aria-describedby="basic-addon1"
                                  value={forwardingAddressPhoneNumber}
                                  onChange={(e) => {
                                    if (e.target.value.length <= 12) {
                                      setForwardingAddressPhoneNumber(e.target.value);
                                    }
                                  }}
                                />
                              </InputGroup>
                              {forwardAddressError && forwardingAddressPhoneNumber == undefined && (<span className="error">{fwdAddError.phone_number}</span>)}
                            </Form.Group>
                          </Col>

                        </Row>
                        <Row>
                          
                        </Row>
                        
                      </div>
                    ) : null}
                  </div>
                </Col>
              </Row>
            </div>

            {/* -- Forwarding address end -- */}

          </div>

          <div className="BtnBottom">
            <Button
              variant="primary"
              type="button"
              className="BtnBack"
              onClick={() => handleRoute("/SelectProduct")}
              style={{top:'15px'}}
            >
              <HiArrowNarrowLeft /> 
              {contentDetails?.your_details_page_data?.back_button}
            </Button>

            <Button
              variant="primary"
              type="submit"
              className="BtnSubmit"
              onClick={() => deleteRecord()}
              style={{top:'15px'}}
            >
              {contentDetails?.your_details_page_data?.cancel_button}
            </Button>

            <Button
              variant="primary"
              type="submit"
              className="BtnSubmit"
              onClick={() => handleStep3Submission()}
              style={{top:'15px'}}
            >
              {contentDetails?.your_details_page_data?.proceed_button}
              <HiArrowNarrowRight />
            </Button>

          </div>
        </div>
      </div>

      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        className="CustomModal"
      >
        <Modal.Header closeButton>
          <Modal.Title>{contentDetails?.your_details_page_data?.settingPopUp_heading}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h3 className="otptxt">
            {contentDetails?.your_details_page_data?.settingPopUp_description}
          </h3>
        </Modal.Body>

        <Modal.Footer>
          <Button className="lftBtn btn btn-secondary" onClick={()=>handleKeepChanges()}>
            {contentDetails?.your_details_page_data?.settingPopUp_keep_settings_button}
          </Button>
          <Button className="rgtBtn btn btn-primary" onClick={()=>handleApplyChange()}>
            {contentDetails?.your_details_page_data?.settingPopUp_apply_changes_button}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default YourDetails;
