import React, { useState, useEffect } from "react";
import { Navigate, Outlet } from "react-router-dom";

const NotLoginAuth = () => {
  const [userRole] = useState(
    JSON.parse(localStorage.getItem("virtualOfficeLoginInfo"))?.role ? JSON.parse(localStorage.getItem("virtualOfficeLoginInfo"))?.role : null
  );
  return !userRole ? <Outlet /> : <Navigate to="/" />;
};

export default NotLoginAuth;
