import React, { useState, useEffect } from "react";
import "./LocationPhone.scss";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import WhiteLogo from "../../assets/images/WhiteLogo.png";
import { Link } from "react-router-dom";
import { NotificationManager } from "react-notifications";
import { getPageContent } from "../../api/apiRequest";
import { useLangContext,languageJson } from "../../context/languageContext";

const LocationPhone = (props) => {
    const {hostLanguage,languageJson} = useLangContext();
    const [contentDetails,setContentDetails] = useState();

    // get page content
    const getAllPageContent = async() => {
        try
        {
            let apiRes = await  getPageContent(hostLanguage);
            let responseData = await apiRes.json();
            if(responseData.res)
            {
            setContentDetails(responseData.data);
            console.warn(responseData.data);
            }
            else
            {
            }
        }
        catch(error)
        {
            console.error(error);
        }
    }

    useEffect(()=>{
        getAllPageContent();
    },[hostLanguage])

    return (
        <div class="LocationPhoneGrid">
            <div class="description">
                <h3>{props.text}</h3>
                <Row>
                    <Col>
                        <p>{contentDetails?.locations_without_map_page_data?.from} {props.price} €</p>
                    </Col>
                    <Col>
                        <Link to ={`/register`}>{contentDetails?.product_mail_page_data?.banner_button_2}</Link>
                    </Col>
                </Row>
                
            </div>
        </div>
    );
};

export default LocationPhone;
