import React, { useState, useEffect } from "react";
import InnerHeader from "../../components/header/InnerHeader";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Table from "react-bootstrap/Table";
import Form from "react-bootstrap/Form";
import Pagination from "react-bootstrap/Pagination";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import TickBlue from "../../assets/images/TickBlue.svg";
import Nav from "react-bootstrap/Nav";
import Remove from "../../assets/images/Remove.svg";
import Sidebar from "../../components/sidebar/Sidebar";
import Restore from "../../assets/images/restore.png";
import "./Trashed.scss";
import { NotificationManager } from "react-notifications";
import { useNavigate, Link } from "react-router-dom";

import { getTrashedData, getAllMailCategory, deleteTrashedData, retriveDataFromTrashed, getPageContent } from "../../api/apiRequest";
import { useContextValues } from "../../context/commonContext";
import { useLangContext } from "../../context/languageContext";

const Trashed = () => {
  const {setEventFire} = useContextValues();
  const {hostLanguage,languageJson} = useLangContext();

  const [show, setShow] = useState(false);
  const [payshow, paysetShow] = useState(false);
  const [addressshow, addresssetShow] = useState(false);
  const [thankshow, thanksetShow] = useState(false);
  const [delshow, deletShow] = useState(false);
  const [selectedValue, setSelectedValue] = useState(1);
  const [categories,setCategories] = useState([]);

  const [totalPages,setTotalPages] = useState();
  const [pageButtonsToShow,setPageButtonShow] = useState();
  const [allTrashedMailArr,setAllTrashedMailArr] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  // const [eventFire,setEventFire] = useState(false);
  const [selectedRecordId,setSelectedRecordId] = useState();
  const [modalType,setModalType] = useState();
  const [contentDetails,setContentDetails] = useState();

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const payhandleClose = () => paysetShow(false);
  const payhandleShow = () => paysetShow(true);

  const addresshandleClose = () => addresssetShow(false);
  const addresshandleShow = () => addresssetShow(true);

  const thankhandleClose = () => thanksetShow(false);
  const thankhandleShow = () => thanksetShow(true);

  const delhandleClose = () => deletShow(false);
  const delhandleShow = () => deletShow(true);



  // date format
  const dateFormat = (date) => {
    let split_date = date.split('-');
    // let final_date = split_date[1] + '.' + split_date[2] + '.' + split_date[0];
    let final_date = split_date[2] + '.' + split_date[1] + '.' + split_date[0];
    return final_date
  }

  // fetching all mail categories
  const fetchingAllMailCategories = async() => {
    try
    {
      let apiRes = await getAllMailCategory(hostLanguage);
      let responseData = await apiRes.json();
      if(responseData.res)
      {
        setCategories(responseData.data);
      }
      else
      {
        NotificationManager.error(responseData.msg,'',2000);
      }
    }
    catch(error)
    {
        // NotificationManager.error('Something went wrong!', '',2000);
    }
  }

  // fetching trashed data
  const fetchAllTrashedData = async(pageNumber) => {
    try
    {
      let apiRes = await getTrashedData(pageNumber,hostLanguage);
      let responseData = await apiRes.json();
      if(responseData.res)
      {
        // Convert object to array
        const dataArr = Object.values(responseData.data.data).filter(obj => typeof obj === 'object');
        setAllTrashedMailArr(dataArr);
        setPageButtonShow(responseData.data.total);
        const totalPages = Math.ceil(Number(responseData.data.data.total_mail_count) / Number(responseData.data.per_page));
        setTotalPages(totalPages);
      }
      else
      {
        NotificationManager.error(responseData.msg,'',2000);
      }
    }
    catch(error)
    {
        // NotificationManager.error('Something went wrong!', '',2000);
    }
  }

  // get page content
  const getAllPageContent = async() => {
    try
    {
      let apiRes = await  getPageContent(hostLanguage);
      let responseData = await apiRes.json();
      if(responseData.res)
      {
        setContentDetails(responseData.data);
        // console.warn(responseData.data.header);
      }
      else
      {
        NotificationManager.error(responseData.msg,'',2000);
      }
    }
    catch(error)
    {
      console.error(error);
    }
  }

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
    fetchAllTrashedData(newPage);
  };

  const getPageNumbers = () => {
    const pageNumbers = [];
    const ellipsisKey = 'ellipsis';
  
    // console.warn('pages: ' + totalPages)
    // console.warn('button: ' + pageButtonsToShow)
    if (totalPages <= pageButtonsToShow)
    {
      // console.warn(1);
      for (let i = 1; i <= totalPages; i++)
      {
        pageNumbers.push(i);
      }
    }
    else
    {
      console.warn(2);
      if (currentPage <= Math.ceil(pageButtonsToShow / 2))
      {
        console.warn(3);
        for (let i = 1; i <= pageButtonsToShow - 1; i++)
        {
          pageNumbers.push(i);
        }
        pageNumbers.push({ key: ellipsisKey });
        pageNumbers.push(totalPages);
      }
      else if (currentPage >= totalPages - Math.floor(pageButtonsToShow / 2))
      {
        console.warn(4);
        pageNumbers.push(1);
        pageNumbers.push({ key: ellipsisKey });
        for (let i = totalPages - pageButtonsToShow + 2; i <= totalPages; i++)
        {
          pageNumbers.push(i);
        }
      }
      else
      {
        console.warn(5);
        pageNumbers.push(1);
        pageNumbers.push({ key: ellipsisKey });
        for (let i = currentPage - Math.floor(pageButtonsToShow / 2) + 1; i <= currentPage + Math.floor(pageButtonsToShow / 2) - 1; i++) {
          pageNumbers.push(i);
        }
        pageNumbers.push({ key: ellipsisKey });
        pageNumbers.push(totalPages);
      }
      console.warn(6);
    }
    console.log(pageNumbers);
    return pageNumbers;
  };

  //restore record from data
  const restoreDataFromTrashed = async() => {
    try
      {
        setEventFire(true);
        let apiRes = await retriveDataFromTrashed(selectedRecordId);
        let responseData = await apiRes.json();
        if(responseData.res)
        {
          NotificationManager.success(responseData.msg,'',2000);
          delhandleClose();
          fetchingAllMailCategories();
          fetchAllTrashedData(1);
          setEventFire(false);
        }
        else
        {
          NotificationManager.error(responseData.msg,'',2000);
        }
      }
      catch(error)
      {
          // NotificationManager.error('Something went wrong!', '',2000);
      }
  }

  // permanent delete from trash
  const deleteDataFromTrashed = async() => {
    try
    {
      setEventFire(true);
      let apiRes = await deleteTrashedData(selectedRecordId);
      let responseData = await apiRes.json();
      if(responseData.res)
      {
        NotificationManager.success(responseData.msg,'',2000);
        delhandleClose();
        fetchAllTrashedData(1);
        setEventFire(false);
      }
      else
      {
        NotificationManager.error(responseData.msg,'',2000);
      }
    }
    catch(error)
    {
        // NotificationManager.error('Something went wrong!', '',2000);
    }
  }


  useEffect(()=>{
    fetchAllTrashedData(1);
    fetchingAllMailCategories();
    getAllPageContent();
  },[hostLanguage])


  return (
    <>
    {/* <div className="lightBlue"> */}
      {/* <InnerHeader /> */}
      {/* <div className="mail-box-main-body"> */}
        {/* <Row className="mail-box-body"> */}
          {/* <Col sm={2}>
            {eventFire ? (
              <Sidebar countUpdate={1}/>
            ):(
              <Sidebar countUpdate={0}/>
            )}
            
          </Col> */}
          <Col sm={12} md={9} lg={10}>
            <div className="MailboxTable">
              <div className="Titelbox">
                <span>
                  {/* <h4>Trashed</h4> */}
                  <h4>{contentDetails?.after_login?.Trashed}</h4>
                  <h2>
                    {/* If you delete your item/mail. It will be move to the trash
                    but for 30 day only then it will destroyed automatically. */}
                    {contentDetails?.after_login?.IfYouDeleteYourItem}
                  </h2>
                </span>
                <span>
                  <Link to="/Dashboard" className="DashboardBtn">
                    {" "}
                    {/* Go To Dashboard */}
                    {contentDetails?.after_login?.GoToDashboard}
                  </Link>
                </span>
              </div>

              <Table>
                <thead>
                  <tr>
                    {/* <th>
                      <div className="form-check">
                        <Form.Check
                          aria-label="option 1"
                          id="flexCheckChecked2"
                        />
                      </div>
                    </th> */}
                    {/* <th>From</th>
                    <th>Type</th>
                    <th>Weight</th>
                    <th>Date</th>
                    <th>Deleted Date</th>
                    <th>Category</th>
                    <th>Actions</th> */}
                    <th> {contentDetails?.after_login?.From}</th>
                    <th> {contentDetails?.after_login?.Type}</th>
                    <th> {contentDetails?.after_login?.Weight}</th>
                    <th> {contentDetails?.after_login?.Date}</th>
                    <th> {contentDetails?.after_login?.DeletedDate}</th>
                    <th> {contentDetails?.after_login?.Category}</th>
                    <th> {contentDetails?.after_login?.Actions}</th>
                  </tr>
                </thead>
                <tbody>
                  
                    {/* <td>
                      <div className="form-check">
                        <Form.Check
                          aria-label="option 1"
                          id="flexCheckChecked2"
                        />
                      </div>
                    </td> */}
                    {allTrashedMailArr?.length > 0 ? (
                      allTrashedMailArr && allTrashedMailArr.map((item,index)=>{
                        return(
                          <>
                            <tr className="odd_tr">
                              <td>{item?.from}</td>
                              <td>{item?.mail_type?.name}</td>
                              <td>{item?.weight}g</td>
                              <td>{dateFormat(item?.get_date)}</td>
                              <td>{dateFormat(item?.delete_date)}</td>
                              <td>
                                <Form.Select aria-label="Default select example">
                                  {/* <option>Insurance</option> */}
                                  {categories && categories.map((cat_item,cat_index)=>{
                                    return(
                                      <option value={cat_item?.id} selected={cat_item?.id === item?.mail_category?.id ? true : false}>{cat_item?.name}</option>
                                    )
                                  })}
                                </Form.Select>
                              </td>
                              <td>
                                <a href="javascript:void(0)" onClick={()=>{delhandleShow();setSelectedRecordId(item?.id);setModalType('delete')}}>
                                  <img src={Remove} className="TableIcon" alt="Img" />
                                </a> {""}
                                <a href="javascript:void(0)" onClick={()=>{delhandleShow();setSelectedRecordId(item?.id);setModalType('restore')}}>
                                  <img src={Restore} className="TableIcon" alt="Img" style={{height:'25px'}}/>
                                </a>
                              </td>
                            </tr>
                            <tr>
                              <td colspan="11"></td>
                            </tr>
                          </>
                        )
                      })):(
                        <tr className="odd_tr">
                          {/* <td colSpan={7} style={{textAlign:'center'}}>No Records Found</td> */}
                          <td colSpan={7} style={{textAlign:'center'}}>{languageJson?.no_record_found}</td>
                        </tr>
                    )}
                    
                  {/* </tr> */}

                  {/* <tr className="odd_tr">
                    <td>
                      <div className="form-check">
                        <Form.Check
                          aria-label="option 1"
                          id="flexCheckChecked2"
                        />
                      </div>
                    </td>
                    <td>Telekom Deutschland Gmbm</td>
                    <td>C5</td>
                    <td>12.00g</td>
                    <td>07.05.2014</td>
                    <td>25.05.2023</td>
                    <td>
                      <Form.Select aria-label="Default select example">
                        <option>Insurance</option>
                      </Form.Select>
                    </td>

                    <td>
                      <a href="javascript:void(0)" onClick={delhandleShow}>
                        <img src={Remove} className="TableIcon" alt="Img" />
                      </a>
                    </td>
                  </tr> */}

                  {/* <tr>
                    <td colspan="11"></td>
                  </tr> */}

                </tbody>
              </Table>

              {/* <div className="TablePagination">
                <Pagination>
                  <Pagination.Item active>{1}</Pagination.Item>
                  <Pagination.Item>{2}</Pagination.Item>
                  <Pagination.Item>{3}</Pagination.Item>
                  <Pagination.Ellipsis />
                  <Pagination.Item>{8}</Pagination.Item>
                </Pagination>
              </div> */}

                {allTrashedMailArr?.length > 0 ? (
                  <div className="TablePagination">
                      {/* Pagination controls start */}
                      <Pagination>
                        {currentPage >= 1 && (
                          <Pagination.Prev
                            disabled={currentPage === 1}
                            onClick={() => handlePageChange(currentPage - 1)}
                            style={{ cursor: currentPage === 1 ? 'not-allowed' : 'pointer' }}
                          />
                        )}
                        
                        {getPageNumbers().map((pageNumber, index) => (
                          <React.Fragment key={index}>
                            {pageNumber.key === 'ellipsis' ? (
                              <Pagination.Ellipsis key={index} />
                            ) : (
                              <Pagination.Item
                                key={index}
                                active={currentPage === pageNumber}
                                onClick={() => handlePageChange(pageNumber)}
                              >
                                {pageNumber}
                              </Pagination.Item>
                            )}
                          </React.Fragment>
                        ))}

                        {currentPage <= totalPages && (
                          <Pagination.Next
                            disabled={currentPage === totalPages}
                            onClick={() => handlePageChange(currentPage + 1)}
                            style={{ cursor: currentPage === totalPages ? 'not-allowed' : 'pointer' }}
                          />
                        )}
                      </Pagination>


                    {/* Pagination controls end */}

                  </div>
                ):null}

            </div>
          </Col>
        {/* </Row> */}
      {/* </div> */}

      <Modal
        show={delshow}
        onHide={delhandleClose}
        backdrop="static"
        keyboard={false}
        centered
        className="CustomModal delCustomModal"
      >
        <Modal.Header closeButton>
          <Modal.Title> {modalType && modalType == 'delete' ? 'Delete': 'Restore'}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h3>
            {/* Do you want this item/mail to be deleted? It will be automatically
            move to the trash for 30 day. */}
            {modalType && modalType == 'delete' ? 'Do you want this item/mail to be delete permanently?': 'Do you want this item/mail to All Mail?'}
          </h3>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={delhandleClose}
            className="lftBtn"
          >
            No
          </Button>
            {
              modalType && modalType == 'delete' ? 
                <Button variant="primary" className="rgtBtn"  onClick={()=>deleteDataFromTrashed()}> Yes</Button>
              : <Button variant="primary" className="rgtBtn"  onClick={()=>restoreDataFromTrashed()}> Yes</Button>
            }
          </Modal.Footer>
      </Modal>

    {/* </div> */}
    </>
  );
};

export default Trashed;
