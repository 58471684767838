import React, { useEffect, useState } from "react";
import axios from "axios";
import {API_BASE_URL} from "../../app_url";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Carousel from "react-bootstrap/Carousel";
import Form from 'react-bootstrap/Form';
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import { Link } from "react-router-dom";
import { getPageContent } from "../../api/apiRequest";
import { NotificationManager } from "react-notifications";

import { MapContainer, TileLayer, Marker, useMap } from 'react-leaflet'
import MarkerIcon from "../location/MarkerIcon";

import noImage from "../../assets/images/noImg.jpg";
import u_shape from "../../assets/images/u_shape.png";
import block from "../../assets/images/block.png";
import theater from "../../assets/images/theater.png";
import circle from "../../assets/images/circle.png";
import parliamentary from "../../assets/images/parliamentary.png";
import u_shape_dash from "../../assets/images/u_shape_dash.png";

import "./Room.scss";

import { useLangContext } from "../../context/languageContext";
import { useContextValues } from "../../context/commonContext";

const Room = (props) => {
    const {hostLanguage,languageJson} = useLangContext();
    const [contentDetails, setContentDetails] = useState();
    const {currencySymbol} = useContextValues();
    const [images,setImages] = useState([]);
    const [expanded,setExpanded] = useState(0);

    const [catering, setCatering] = useState([]);
    const [services, setServices] = useState([]);
    const [participant, setParticipant] = useState(1);
    const [participantMax, setParticipantMax] = useState((props?.entity?.seating[0]?.max_persons==undefined?9999:props?.entity?.seating[0]?.max_persons));
    const [seating, setSeating] = useState();
    const [dateFrom, setDateFrom] = useState();
    const [dateTo, setDateTo] = useState();
    const [timeFrom, setTimeFrom] = useState();
    const [timeTo, setTimeTo] = useState();
    const [salutation, setSalutation] = useState();
    const [company, setCompany] = useState();
    const [first_name, setFirstName] = useState();
    const [last_name, setLastName] = useState();
    const [street, setStreet] = useState();
    const [housenumber, setHousenumber] = useState();
    const [postcode, setPostcode] = useState();
    const [city, setCity] = useState();
    const [mail, setMail] = useState();
    const [phone, setPhone] = useState();
    const [message, setMessage] = useState();
    const [offers, setOffers] = useState();
    const [privacy, setPrivacy] = useState();

    const [pricing, setPricing] = useState(undefined);
    const [total_additional_equipment, setTotalAdditionalEquipment] = useState(0);
    const [total_room_catering, setTotalRoomCatering] = useState(0);
    const [total_room_rental, setTotalRoomRental] = useState(0);
    const [total, setTotal] = useState(0);



    // get page content
    const getAllPageContent = async () => {
        try {
            let apiRes = await getPageContent(hostLanguage);
            let responseData = await apiRes.json();
            if (responseData.res) {
                setContentDetails(responseData.data);
            } else {
                NotificationManager.error(responseData.msg, "", 2000);
            }
        } catch (error) {
            console.error(error);
        }
    };

    const insertService = (e) => {
        const value = e.target.value;
        const checked = e.target.checked;
        if(checked){
            setServices([...services, value]);
        }else{
            setServices(services.filter(service => service !== value));
        }
    }
    
    const insertCatering = (e) => {
        const value = e.target.value;
        const checked = e.target.checked;
        if(checked){
            setCatering([...catering, value]);
        }else{
            setCatering(catering.filter(cater => cater !== value));
        }
    }

    const getImgLink = (img_link) => {
        try
        {
            let base_url = 'https://cleverdomizil.de';
            let url = base_url + img_link?.split("httpdocs")[1];
            return url
        }
        catch(error)
        {
            console.error(error);
        }
    }

    const setParticipants = (e) => {
        let value = e.target.value;
        if(value < 1){
            value = 1;
        }else if(value > participantMax){
            value = participantMax;

        }
        setParticipant(value);
    }

    const setSeatingAndMax = (e) => {
        let value = e.target.value;
        let maxParticipants = props.entity.seating.find(x => x.type == value).max_persons
        setSeating(value);
        setParticipantMax(maxParticipants);
    }

    const closest = (num, arr) => {
        var curr = arr[0];
        var diff = Math.abs (num - curr);
        for (var val = 0; val < arr.length; val++) {
            var newdiff = Math.abs (num - arr[val]);
            if (newdiff < diff) {
                diff = newdiff;
                curr = arr[val];
            }
        }
        return curr;
    }

    //Calculate
    useEffect(()=>{
        let sum = 0;
        services.map((id) =>{
            let add = props.entity.services.find(x => x.id == id)
            if(add.price_type == 1){
                sum += (add.price*(isNaN(participant)?0:participant));
            }else{
                sum += add.price;
            }
        });
        setTotalAdditionalEquipment(sum);
    },[services, participant])
    useEffect(()=>{
        let sum = 0;
        catering.map((id) =>{
            let add = props.entity.services.find(x => x.id == id)
            if(add.price_type == 1){
                sum += (add.price*(isNaN(participant)?0:participant));
            }else{
                sum += add.price;
            }
        });
        setTotalRoomCatering(sum);
    },[catering])
    //
    useEffect(()=>{
        let calcDateFrom = undefined;
        let calcDateTo = undefined;

        if(dateFrom != undefined)
            calcDateFrom = new Date(dateFrom);

        if(dateTo != undefined)
            calcDateTo = new Date(dateTo);
        
        if(dateFrom != undefined && timeFrom !== undefined)
            calcDateFrom = new Date(dateFrom+'T'+timeFrom);

        if(dateTo != undefined && timeTo !== undefined)
            calcDateTo = new Date(dateTo+'T'+timeTo);

        if(calcDateFrom != undefined && calcDateTo != undefined){
            if(dateTo < dateFrom){
                setDateTo(dateFrom);
            }
            if(dateTo == dateFrom){
                if(timeTo < timeFrom){
                    setTimeTo(timeFrom);
                }
            }

            let diffTime = Math.abs(calcDateTo - calcDateFrom);
            let diffHours = Math.ceil(diffTime / (1000 * 60 * 60));

            //GET PRICE
            let pricing = props.entity.prices.find(x => x.hours == diffHours && x.exact == 1);
            if(pricing == undefined){
                pricing = props.entity.prices.find(x => x.hours >= diffHours && x.exact == 0);
            }

            //Price not found
            if(pricing == undefined){
                pricing = closest(diffHours, props.entity.prices);
            }
            
            if(pricing.hours%24 == 0){
                let diffDays = 0;
                if(Math.floor(diffHours/24) == 0){
                    diffDays = 1;
                }else{
                    diffDays = parseInt(Math.floor(diffHours/24)) + 1;
                }
                setTotalRoomRental(pricing.price * diffDays);
            }else{
                setTotalRoomRental(pricing.price * (diffHours/pricing.hours));
            }
        }else{
            setTotalRoomRental(0);
        }


    },[dateFrom, dateTo, timeFrom, timeTo])
    //Totaling
    useEffect(()=>{
        setTotal(total_additional_equipment+total_room_catering+total_room_rental);
    },[total_additional_equipment, total_room_catering, total_room_rental]);

    //Open / Close contact data.
    useEffect(()=>{
        window.dispatchEvent(new Event("resize"));

    },[expanded]);

    useEffect(() => {
        getAllPageContent();

        if(seating == undefined){
            let seatingE = props?.entity?.seating[0];
            if(seatingE != undefined){
                setSeating(seatingE?.type);
                setParticipantMax(seatingE?.max_persons);
            }
        }
        
        if(pricing == undefined){
            let price = props.entity.prices.find(x => x.hours == 24);
            if(price == undefined){
                price = props.entity.prices.find(x => x.hours == 1);
            }
            setPricing( price );
        }
        console.log(hostLanguage);
    }, [hostLanguage]);

    const handleForm = e => {
        e.preventDefault();

        let additional = []
        services.map((id) =>{
            additional.push(props.entity.services.find(x => x.id == id));
        });
        props.entity.services.map((item)=>{
            if(item.price == 0)
                additional.push(item);
        });
        catering.map((id) =>{
            additional.push(props.entity.services.find(x => x.id == id));
        });
        props.entity.catering.map((item)=>{
            if(item.price == 0)
                additional.push(item);
        });

        let formData = {
            room: props.entity.id,
            title: props.entity.title,
            subtitle: props.entity.subtitle,
            services: additional,
            participant: participant,
            seating: seating,
            date_from: dateFrom,
            date_to: dateTo,
            time_from: timeFrom,
            time_to: timeTo,
            salutation: salutation,
            company: company,
            first_name: first_name,
            last_name: last_name,
            street: street,
            housenumber: housenumber,
            postcode: postcode,
            city: city,
            email: mail,
            phone: phone,
            user_message: message,
            offers: offers,
            privacy: privacy,
            additional_equipment: total_additional_equipment.toFixed(2),
            catering: total_room_catering.toFixed(2),
            room_rental: total_room_rental.toFixed(2),
            total: total.toFixed(2),
            lang: hostLanguage
        };

        axios
        .post(`${API_BASE_URL}conferenceroom/inquire`, JSON.stringify(formData), {
            headers: {
                "Content-Type": "application/json", // Set the content type
            }
        })
        .then(response => {
            if(response.data.res == true){
                alert(response.data.msg);
            }
        })
        .catch(error => {
            if (error.response) {
                alert(error.response.data.error || languageJson?.somethingwentwrongpleasetryagain);
            } else {
                alert(languageJson?.somethingwentwrongpleasetryagain);
            }
        });
    };

    return (
        <Form className={"Room"} onSubmit={handleForm}>
            <Row>
                <Col sm={12} lg={9}>
                    <Row>
                        <Col sm={12}>
                            <h2 className={"RoomTitle"}>{props?.entity?.title}</h2>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={12} lg={4}>
                            {props?.entity?.images?.length > 0 ? (
                                <Carousel>
                                    {props?.entity?.images?.map((item,index)=>{
                                        return(
                                            <Carousel.Item>
                                                <img className="d-block w-100" src={getImgLink(item?.image)} alt="First slide"/>
                                                <Carousel.Caption>
                                                    <div dangerouslySetInnerHTML={{ __html: item?.description }} />
                                                </Carousel.Caption>
                                            </Carousel.Item>
                                        )
                                    })}
                                </Carousel>
                            ):(
                                <img className= {"d-block w-100"} src={noImage}/>
                            )}
                        </Col>
                        <Col sm={12} lg={8} className={"details"}>
                            <p className="room">{props?.entity?.subtitle}</p>
                            <div className="seating">
                                {props?.entity?.seating?.map((item,index)=>{
                                    if(index > 2){
                                        return;
                                    }
                                    let seatingType;
                                    let seatingText;
                                    switch (item?.type){
                                        case 'u_shape':
                                            seatingType = u_shape;
                                            seatingText = contentDetails?.rooms?.seating_u_shape;
                                            break;
                                        case 'block':
                                            seatingType = block;
                                            seatingText = contentDetails?.rooms?.seating_block;
                                            break;
                                        case 'theater':
                                            seatingType = theater;
                                            seatingText = contentDetails?.rooms?.seating_theater;
                                            break;
                                        case 'circle':
                                            seatingType = circle;
                                            seatingText = contentDetails?.rooms?.seating_circle;
                                            break;
                                        case 'parliamentary':
                                            seatingType = parliamentary;
                                            seatingText = contentDetails?.rooms?.seating_parliamentary;
                                            break;
                                        case 'u_shape_dash':
                                            seatingType = u_shape_dash;
                                            seatingText = contentDetails?.rooms?.seating_u_shape_dash;
                                            break;
                                        default:
                                            seatingType = noImage;
                                            seatingText = 'n/A';
                                            break;
                                    }

                                    return (
                                        

                                        <div className="seating_entry">
                                            <img src={seatingType}/>
                                            <p className="title">{seatingText}</p>
                                            <p className="persons">{item?.max_persons} {contentDetails?.rooms?.seating_persons}</p>
                                        </div>
                                    )
                                })}
                            </div>
                            <div className={'services'}>
                                {props?.entity?.services?.map((item,index)=>{
                                    if(item?.price==0){
                                        return (
                                            <div>
                                                &#x2713;
                                                <label htmlFor={"service"+item?.id}>{item?.name}</label>
                                            </div>
                                        )
                                    }else{
                                        if(item?.type=="catering"){
                                            return (
                                                <div>
                                                    <input 
                                                        id={"catering"+item?.id} 
                                                        type="checkbox" 
                                                        value={item?.id} 
                                                        disabled={(item?.price==0?1:0)}
    
                                                        onChange={(e) => {insertCatering(e)}}
                                                    />
                                                    <label htmlFor={"catering"+item?.id}>{item?.name} <span className="price">{item?.price_type==1?contentDetails?.rooms?.seating_pp+' ':''}{(item?.price==0?'':item?.price)} {currencySymbol}</span></label>
                                                </div>
                                            )
                                        }else if(item?.type=="equipment"){
                                            return (
                                                <div>
                                                    <input 
                                                        id={"service"+item?.id} 
                                                        type="checkbox" 
                                                        value={item?.id} 
                                                        disabled={(item?.price==0?1:0)}
    
                                                        onChange={(e) => {insertService(e)}}
                                                    />
                                                    <label htmlFor={"service"+item?.id}>{item?.name} <span className="price">{item?.price_type==1?contentDetails?.rooms?.seating_pp+' ':''}{(item?.price==0?'':item?.price)} {currencySymbol}</span></label>
                                                </div>
                                            )
                                        }else{
                                            return('')
                                        }
                                    }
                                    
                                })}
                            </div>
                            <a href={'#'} onClick={(e) => {e.preventDefault(); setExpanded(!expanded)}}>{(expanded?'▲':'▼')} {contentDetails?.rooms?.expand_link}</a>
                        </Col>
                    </Row>
                </Col>
                <Col sm={12} lg={3}>
                    <Row className={"prices"}>
                        <Col sm={12}>
                            <span className={"price"}>
                                {
                                    (pricing == undefined?'0.00 ' + currencySymbol:
                                        (pricing?.hours == 24 ?
                                            pricing?.price + ' ' + currencySymbol + ' / ' + (contentDetails?.rooms?.day==undefined?'':contentDetails?.rooms?.day)
                                        :
                                            pricing?.price + ' ' + currencySymbol + ' / ' + (pricing?.hours != 1?pricing?.hours:'') + (contentDetails?.rooms?.hour==undefined?'':contentDetails?.rooms?.hour)
                                        )
                                    )
                                }
                            </span>
                        </Col>
                        <Col sm={12}>
                            <hr />
                            <table className={"extras"}>
                                <tr>
                                    <td>{contentDetails?.rooms?.additional_equipment}</td>
                                    <td>{total_additional_equipment.toFixed(2)} {currencySymbol}</td>
                                </tr>
                                <tr>
                                    <td>{contentDetails?.rooms?.total_catering}</td>
                                    <td>{total_room_catering.toFixed(2)} {currencySymbol}</td>
                                </tr>
                                <tr>
                                    <td>{contentDetails?.rooms?.total_room_rental}</td>
                                    <td>{total_room_rental.toFixed(2)} {currencySymbol}</td>
                                </tr>
                            </table>
                            <hr />
                        </Col>
                        <Col sm={12}>
                            <table className={"total"}>
                                <tr>
                                    <td>{contentDetails?.rooms?.total}</td>
                                    <td>{total.toFixed(2)} {currencySymbol}</td>
                                </tr>
                            </table>
                        </Col>
                        <Col sm={12}>
                            <p className={"vatHint"}>
                                {contentDetails?.rooms?.tax_hint}
                                <br />
                                {contentDetails?.rooms?.tax_hint_catering}
                            </p>
                        </Col>
                    </Row>
                    <a className={(!expanded?'d-block':'d-none') + " RoomBtn btn btn-primary"} onClick={() => setExpanded(!expanded)}>{contentDetails?.rooms?.inquire}</a>
                </Col>
            </Row>
            <Row className={(expanded?'d-flex':'d-none')}>
                <Col sm={12}>
                    <hr/>
                </Col>
                <Col sm={12} lg={9}>
                    <Row>
                        <Col sm={12}>
                            <h3>{contentDetails?.rooms?.booking_request}</h3>
                        </Col>
                        <Col sm={12} lg={2}>
                            <Form.Group>
                                <Form.Label>{contentDetails?.rooms?.participants}</Form.Label>
                                <Form.Control type="number" defaultValue="1" value={participant} onChange={setParticipants}/>
                            </Form.Group>
                        </Col>
                        <Col sm={12} lg={2}>
                            <Form.Group>
                                <Form.Label>{contentDetails?.rooms?.seating}</Form.Label>
                                <Form.Select value={seating} onChange={setSeatingAndMax}>
                                    {props?.entity?.seating?.map((item,index)=>{
                                        let seatingType;
                                        let seatingText;
                                        switch (item?.type){
                                            case 'u_shape':
                                                seatingText = contentDetails?.rooms.seating_u_shape;
                                                break;
                                            case 'block':
                                                seatingText = contentDetails?.rooms.seating_block;
                                                break;
                                            case 'theater':
                                                seatingText = contentDetails?.rooms.seating_theater;
                                                break;
                                            case 'circle':
                                                seatingText = contentDetails?.rooms.seating_circle;
                                                break;
                                            case 'parliamentary':
                                                seatingText = contentDetails?.rooms.seating_parliamentary;
                                                break;
                                            case 'u_shape_dash':
                                                seatingText = contentDetails?.rooms.seating_u_shape_dash;
                                                break;
                                            default:
                                                seatingText = 'n/A';
                                                break;
                                        }

                                        return (
                                            <option value={item?.type}>{seatingText}</option>
                                        )
                                    })}
                                </Form.Select>
                            </Form.Group>
                        </Col>
                        <Col sm={12} lg={2}>
                            <Form.Group>
                                <Form.Label>{contentDetails?.rooms?.start_date}*</Form.Label>
                                <Form.Control type="date" value={dateFrom} onChange={(e) => setDateFrom(e.target.value)} required/>
                            </Form.Group>
                        </Col>
                        <Col sm={12} lg={2}>
                            <Form.Group>
                                <Form.Label>{contentDetails?.rooms?.end_date}*</Form.Label>
                                <Form.Control type="date" value={dateTo} onChange={(e) => setDateTo(e.target.value)} required/>
                            </Form.Group>
                        </Col>
                        <Col sm={12} lg={2}>
                            <Form.Group>
                                <Form.Label>{contentDetails?.rooms?.start_time}</Form.Label>
                                <Form.Control type="time" value={timeFrom} onChange={(e) => setTimeFrom(e.target.value)}/>
                            </Form.Group>
                        </Col>
                        <Col sm={12} lg={2}>
                            <Form.Group>
                                <Form.Label>{contentDetails?.rooms?.end_time}</Form.Label>
                                <Form.Control type="time" value={timeTo} onChange={(e) => setTimeTo(e.target.value)}/>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={12}>
                            <h3>{contentDetails?.rooms?.contact_details}</h3>
                        </Col>
                        <Col sm={12} lg={4}>
                            <Form.Group>
                                <Form.Label>{contentDetails?.rooms?.salutation}</Form.Label>
                                <Form.Select value={salutation} onChange={(e) => setSalutation(e.target.value)}>
                                    <option value="women">{contentDetails?.rooms?.women}</option>
                                    <option value="man">{contentDetails?.rooms?.man}</option>
                                    <option value="other">{contentDetails?.rooms?.other}</option>
                                </Form.Select>
                            </Form.Group>
                        </Col>
                        <Col sm={12} lg={8}>
                            <Form.Group>
                                <Form.Label>{contentDetails?.rooms?.company}</Form.Label>
                                <Form.Control type="text" value={company} onChange={(e) => setCompany(e.target.value)} />
                            </Form.Group>
                        </Col>
                        <Col sm={12} lg={4}>
                            <Form.Group>
                                <Form.Label>{contentDetails?.rooms?.first_name}*</Form.Label>
                                <Form.Control type="text" value={first_name}  onChange={(e) => setFirstName(e.target.value)} required/>
                            </Form.Group>
                        </Col>
                        <Col sm={12} lg={8}>
                            <Form.Group>
                                <Form.Label>{contentDetails?.rooms?.last_name}*</Form.Label>
                                <Form.Control type="text" value={last_name}  onChange={(e) => setLastName(e.target.value)} required/>
                            </Form.Group>
                        </Col>
                        <Col sm={12} lg={9}>
                            <Form.Group>
                                <Form.Label>{contentDetails?.rooms?.street}</Form.Label>
                                <Form.Control type="text" value={street}  onChange={(e) => setStreet(e.target.value)}/>
                            </Form.Group>
                        </Col>
                        <Col sm={12} lg={3}>
                            <Form.Group>
                                <Form.Label>{contentDetails?.rooms?.housenumber}</Form.Label>
                                <Form.Control type="text" value={housenumber}  onChange={(e) => setHousenumber(e.target.value)}/>
                            </Form.Group>
                        </Col>
                        <Col sm={12} lg={3}>
                            <Form.Group>
                                <Form.Label>{contentDetails?.rooms?.postcode}</Form.Label>
                                <Form.Control type="text" value={postcode}  onChange={(e) => setPostcode(e.target.value)}/>
                            </Form.Group>
                        </Col>
                        <Col sm={12} lg={9}>
                            <Form.Group>
                                <Form.Label>{contentDetails?.rooms.city}</Form.Label>
                                <Form.Control type="text" value={city}  onChange={(e) => setCity(e.target.value)}/>
                            </Form.Group>
                        </Col>
                        <Col sm={12} lg={6}>
                            <Form.Group>
                                <Form.Label>{contentDetails?.rooms?.mail}*</Form.Label>
                                <Form.Control type="email" value={mail}  onChange={(e) => setMail(e.target.value)} required/>
                            </Form.Group>
                        </Col>
                        <Col sm={12} lg={6}>
                            <Form.Group>
                                <Form.Label>{contentDetails?.rooms?.phone}</Form.Label>
                                <Form.Control type="text" value={phone}  onChange={(e) => setPhone(e.target.value)}/>
                            </Form.Group>
                        </Col>
                        <Col sm={12}>
                            <Form.Group>
                                <Form.Label>{contentDetails?.rooms?.message}</Form.Label>
                                <Form.Control as="textarea" rows={3} cols="30" value={message}  onChange={(e) => setMessage(e.target.value)}/>
                            </Form.Group>
                        </Col>
                        <Col sm={12}>
                            <Form.Check
                                type="checkbox"
                                label={contentDetails?.rooms?.send_me_offers}
                                onChange={(e) => setOffers(e.target.checked)}
                            />
                        </Col>
                        <Col sm={12} className={"privacy"}>
                            <Form.Check
                                type="checkbox"
                                label={contentDetails?.rooms?.accept_privacy}
                                onChange={(e) => setPrivacy(e.target.checked)}
                                required
                            />
                            <Link to="/privacy">{contentDetails?.footer?.parent_6}*</Link>
                        </Col>
                    </Row>
                </Col>
                <Col sm={12} lg={3}>
                    <Tabs 
                        id="position"
                    >
                        <Tab eventKey={contentDetails?.rooms?.tab_drop_off} title={contentDetails?.rooms?.tab_drop_off}>
                            <p><strong>{props?.address?.street} {props?.address?.number}</strong></p>
                            <p><strong>{props?.address?.postcode} {props?.city?.name}</strong></p>
                            
                            {props?.address?.lat != null && props?.address?.lat != undefined &&
                            props?.address?.lon != null && props?.address?.lon != undefined 
                            ? (
                                <MapContainer center={[props?.address?.lat,props?.address?.lon]} zoom={13} scrollWheelZoom={false}>
                                    <TileLayer
                                        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                    />
                                    <Marker position={[props?.address?.lat,props?.address?.lon]} icon={ MarkerIcon }></Marker>
                                </MapContainer>
                            ) : ('')}
                        </Tab>
                        {props?.address?.publicTransport != null ? (
                            <Tab eventKey={contentDetails?.rooms?.tab_bus_train} title={contentDetails?.rooms?.tab_bus_train}>
                                <p dangerouslySetInnerHTML={{ __html: props?.address?.publicTransport }} />
                            </Tab>
                        ) : ('')}
                        {props?.address?.parking != null ? (
                            <Tab eventKey={contentDetails?.rooms?.tab_drive} title={contentDetails?.rooms?.tab_drive}>
                                {props?.address?.parking}
                            </Tab>
                        ) : ('')}
                    </Tabs>
                </Col>
                <Col sm={12}>
                    <button type="submit" className={"RoomBtn btn btn-primary"}>{contentDetails?.rooms?.inquire}</button>
                </Col>
            </Row>
        </Form>
    );
};

export default Room;
