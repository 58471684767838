import React, { useEffect, useState } from "react";
import Mainheader from "../../components/mainheader/Mainheader";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Footer from "../../components/footer/Footer";
import StateButton from "../../components/state-button/StateButton";
import "./PricingMailbox.scss";
import Form from "react-bootstrap/Form";
import Image from "react-bootstrap/Image";
import { Link, useNavigate } from "react-router-dom";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import Button from "react-bootstrap/Button";
import SmllTick from "../../assets/images/SmllTick.svg";
import SmllCross from "../../assets/images/SmllCross.svg";
import RedCross from "../../assets/images/RedCross.svg";
import DefaultIcon from "../../assets/images/block 1.svg";
import TickImg from "../../assets/images/TickTick.svg";
import { NotificationManager } from "react-notifications";

import 
{ 
  getPricingMailCountries, getPricingMailCitiesById, getPricingMailContractPlansById,
  getPricingPhoneCountries, getPricingPhoneAreasById, getPricingPhoneFeaturesById, getPageContent
} from "../../api/apiRequest";
import { useLangContext } from "../../context/languageContext";

const PricingMailbox = () => {
  const navigate = useNavigate();
  const {hostLanguage,languageJson} = useLangContext();
  const [countries,setCountries] = useState([]);
  const [selectedCountryId,setSelectedCountryId] = useState();
  const [cities,setCities] = useState([]);
  const [selectedCityId,setSelectedCityId] = useState();
  const [planDetails,setPlanDetails] = useState();
  const [selectTab,setSelectedTab] = useState('mailbox');
  const [areas,setAreas] = useState([]);
  const [isSelectedArea,setIsSelectedArea] = useState(false);
  const [selectedArea,setSelectedArea] = useState();
  const [features,setFeatures] = useState([]);
  const [defaultTyped,setDefaultTyped] = useState('month');
  const [cityId,setCityId] = useState();
  const [selectedAreaCountryId,setSelectedAreaCountryId] = useState();

  const [contentDetails,setContentDetails] = useState();

  // pricing - all mail-countries
  const getAllCountries = async(type) => {
    try
    {
      let apiRes ='';
      if(type === 1)
      {
        apiRes = await getPricingMailCountries();
      }
      else if(type === 2)
      {
        apiRes = await getPricingPhoneCountries();
      }
      
      let responseData = await apiRes.json();
      if(responseData.res)
      {
        const countriesArray = Object.values(responseData.data).flat(); //Object to Array Conversation
        console.warn(responseData.data);
        console.warn(countriesArray);
        setCountries(countriesArray);
      }
      else
      {
        NotificationManager.error(responseData.msg,'',2000);
      }
    }
    catch(error)
    {
        // NotificationManager.error('Something went wrong!', '',2000);
    }
  }

  // get page content
  const getAllPageContent = async() => {
    try
    {
      let apiRes = await  getPageContent(hostLanguage);
      let responseData = await apiRes.json();
      if(responseData.res)
      {
        setContentDetails(responseData.data);
        // console.warn(responseData.data.header);
      }
      else
      {
        NotificationManager.error(responseData.msg,'',2000);
      }
    }
    catch(error)
    {
      console.error(error);
    }
  }

  //handle cities by country_id
  const handleCities = async(country_id) => {
    setDefaultTyped('month');
    setCityId();
    setSelectedCityId();
    getAllCities(country_id);
    setSelectedCountryId(country_id);
    setCities([]);
    setPlanDetails();
  }

  // pricing - all cities
  const getAllCities = async(country_id) => {
    try
    {
      let apiRes = await getPricingMailCitiesById(country_id);
      let responseData = await apiRes.json();
      if(responseData.res)
      {
        setCities(responseData.data);
      }
      else
      {
        NotificationManager.error(responseData.msg,'',2000);
      }
    }
    catch(error)
    {
        // NotificationManager.error('Something went wrong!', '',2000);
    }
  }

  // mail Contract Plan
  // const handleCityData = async(city_id) => {
  const handleCityData = async(select_type) => {
    try
    {
      console.log('Selected Country id: ' + selectedCountryId);
      console.log('Selected City id: ' + cityId);
      console.log('Selected Type: ' + select_type);
      
      let apiRes = await getPricingMailContractPlansById(selectedCountryId,cityId,select_type,hostLanguage);
      
      let responseData = await apiRes.json();
      if(responseData.res)
      {
        setPlanDetails(responseData.data);
        setSelectedCityId(cityId);
      }
      else
      {
        NotificationManager.error(responseData.msg,'',2000);
      }
    }
    catch(error)
    {
        // NotificationManager.error('Something went wrong!', '',2000);
    }
  }

  const defaultCall = async(city_id) =>{
    try
    {
      let apiRes = await getPricingMailContractPlansById(selectedCountryId,city_id,defaultTyped,hostLanguage);
      
      let responseData = await apiRes.json();
      if(responseData.res)
      {
        setPlanDetails(responseData.data);
        setSelectedCityId(city_id);
      }
      else
      {
        NotificationManager.error(responseData.msg,'',2000);
      }
    }
    catch(error)
    {
        // NotificationManager.error('Something went wrong!', '',2000);
    }
  }

  //tabs selection
  const handleTabSelect =(tab_type) => {
    setSelectedTab(tab_type);
    setCountries([]);
    setCities([]);
    setAreas([]);
    setSelectedCountryId();
    setSelectedCityId();
    setIsSelectedArea(false);
  }

  // Phone areas
  const handlePhoneAreas = async(country_id) => {
    try
    {
      setAreas([]);
      let apiRes = await getPricingPhoneAreasById(country_id);
      let responseData = await apiRes.json();
      if(responseData.res)
      {
        setAreas(responseData.data);
      }
      else
      {
        NotificationManager.error(responseData.msg,'',2000);
      }
    }
    catch(error)
    {
        // NotificationManager.error('Something went wrong!', '',2000);
    }
  }
 
  // fetch area by country_id
  const fetchArea = async (area_id) => {
    try
    {
      const index = areas.findIndex(item => parseInt(item?.id) === parseInt(area_id));
      setSelectedArea(areas[index])
      setIsSelectedArea(true);
      setSelectedAreaCountryId(areas[index]?.country_id);
      featureDetailsByCountryId(areas[index]?.country_id);
    }
    catch(error)
    {
        // NotificationManager.error('Something went wrong!', '',2000);
    }
  }

  // fetures details by country_id
  const featureDetailsByCountryId = async (country_id) => {
    try
    {
      let apiRes = await getPricingPhoneFeaturesById(country_id,hostLanguage);
      let responseData = await apiRes.json();
      if(responseData.res)
      {
        setFeatures(responseData.data);
      }
      else
      {
        NotificationManager.error(responseData.msg, '',2000);
      }
    }
    catch(error)
    {
      // NotificationManager.error('Something went wrong!', '',2000);
    }
  }

  useEffect(()=>{
    if(selectTab === 'mailbox')
    {
      getAllCountries(1);
    }
    else if(selectTab === 'phonenumber')
    {
      getAllCountries(2);
    }
  },[selectTab])

  useEffect(()=>{
    console.warn(selectedCountryId);
    console.warn(selectedCityId);
  },[selectedCountryId, selectedCityId])

  useEffect(()=>{
    getAllPageContent();
  },[hostLanguage])

  useEffect(()=>{
    console.log(selectedAreaCountryId);
    if(selectedAreaCountryId!= undefined)
    {
      featureDetailsByCountryId(selectedAreaCountryId);
    }
  },[hostLanguage])
  
  return (
    <div className="WebBody">
      <Mainheader />
      <div className="MainbBody">
        <div className="PricelistBanner">
          <Container>
            <Row>
              <Col sm={12}>
                {/* <p>CleverDomizil</p> */}
                {/* <h3>Preisliste</h3> */}
                <div dangerouslySetInnerHTML={{__html: contentDetails?.pricing_mail_box_page_data?.banner_text}}></div>
              </Col>
            </Row>
          </Container>
        </div>
        <div className="PricelistBody">
          <Container>
            <Row>
              <Col sm={12}>
                {/* <h3>Wählen Sie Ihr gewünschtes Produkt aus!</h3> */}
                <div dangerouslySetInnerHTML={{__html: contentDetails?.pricing_mail_box_page_data?.page_heading}}></div>
              </Col>
            </Row>
            <Row className="PricelistTab">
              <Col sm={12}>
                <Tabs
                  defaultActiveKey="mailbox"
                  id="uncontrolled-tab-example"
                  className="mb-3"
                  onSelect={handleTabSelect}
                >
                  {/* <Tab eventKey="mailbox" title="Geschäftsadresse"> */}
                  <Tab eventKey="mailbox" title={contentDetails?.pricing_mail_box_page_data?.tab_1_text}>
                    <div className="DigitalmailboxinLftCheckBox DigitalmailboxinLftCheckBox5 NewMB">
                      <span>
                        <Form.Select aria-label="Default select example" onChange={(e)=>handleCities(e.target.value)}>
                          {/* <option selected hidden>Country</option> */}
                          <option selected hidden>{contentDetails?.pricing_mail_box_page_data?.tab_1_select_box_1_default_text}</option>
                            {countries?.length > 0 ? (
                              countries?.map((item,index)=>{
                                return(
                                  <option value={item?.country_id}>{item?.country_name}</option>
                                )
                              })
                            ):(
                              // <option disabled>No Countries Found</option>
                              <option disabled>{languageJson?.countryNotFound}</option>
                            )}
                        </Form.Select>
                      </span>

                      <span>
                        {/* <Form.Select aria-label="Default select example" onChange={(e)=>handleCityData(e.target.value)}> */}
                        <Form.Select aria-label="Default select example" onChange={(e)=>{
                          if(defaultTyped === 'month')
                          {
                            setCityId(e.target.value);
                            defaultCall(e.target.value);
                            setDefaultTyped('month');
                          }
                          else
                          {
                            setCityId(e.target.value);
                            defaultCall(e.target.value);
                            setDefaultTyped('year')}}
                          }
                        >
                          {/* <option selected hidden>City</option> */}
                          <option selected hidden>{contentDetails?.pricing_mail_box_page_data?.tab_1_select_box_2_default_text}</option>
                          {cities?.length > 0 ? (
                            cities?.map((item,index)=>{
                              return(
                                <option value={item?.city?.id} key={index}>{item?.city?.name}</option>
                              )
                            })
                          ):(
                            // <option disabled>No Cities Found</option>
                            <option disabled>{languageJson?.cityNotFound}</option>
                          )}
                        </Form.Select>
                      </span>
                    </div>
                    {cityId && (
                      <div>
                        <div className="radiobtncenter">
                          <div className="radioGrp">
                            <div className="form-check checkFrm">
                              <Form.Check type="radio" name="pricing" defaultChecked={defaultTyped} value="month" onChange={(e)=>{handleCityData(e.target.value);setDefaultTyped(e.target.value)}} />
                                <Form.Label className="form-check-label" for='Month'>
                                {/* Monatlich */}
                                {contentDetails?.pricing_mail_box_page_data?.tab_1_monthly_radio_text}
                                </Form.Label>
                              <Form.Check type="radio" name="pricing"  value="year" onChange={(e)=>{handleCityData(e.target.value);setDefaultTyped(e.target.value)}}/>
                                <Form.Label className="form-check-label" for='Year'>
                                {/* Jährlich */}
                                {contentDetails?.pricing_mail_box_page_data?.tab_1_yearly_radio_text}
                                </Form.Label>

                            </div>
                          </div>
                        </div>
                        <div className="radiobtncenter">
                          <StateButton className="BtnSubmit btn btn-primary" text={contentDetails?.pricing_mail_box_page_data?.BookNowButton} to='/register' userTo='/LetStarted'/>
                        </div>
                      </div>
                    )}

                    {selectedCountryId!=undefined && selectedCityId!=undefined && (
                      <div className="PlanScetion">
                        <Container className="">
                            <Row>
                              <Col sm={12}>
                                <div className="ScetionTitel">
                                  {/* <p>CleverDomizil Geschäftsadresse</p>
                                  <h2>CleverDomizil Postfach</h2> */}
                                  <div dangerouslySetInnerHTML={{__html: contentDetails?.pricing_mail_box_page_data?.tab_1_product_heading}}></div>
                                </div>
                              </Col>
                            </Row>
                            <Row className="align-items-center">
                              <Col sm={12}>
                                {/* <div className="PlanComparisonDiv">
                                  <table>
                                    <tr>
                                      <th>&nbsp;</th>
                                      {planDetails?.contract_plan_details?.map((item,index)=>{
                                        return(
                                          <th>
                                            <h2>{item?.title}</h2>
                                            <h3>€ {item?.price}</h3>
                                            <span>per month</span>
                                            <p>{item?.description}</p>
                                          </th>
                                        )
                                      })}
                                    </tr>
                                    <tr>
                                      <td>
                                        Pricing of activities - see full pricing
                                      </td>
                                      <td>High</td>
                                      <td>Medium</td>
                                      <td>Low</td>
                                    </tr>
                                    <tr>
                                      <td>Included incoming items</td>
                                      <td>5</td>
                                      <td>20</td>
                                      <td>50</td>
                                    </tr>
                                    <tr>
                                      <td>Envelope scanning (front)</td>
                                      <td>2</td>
                                      <td>5</td>
                                      <td>10</td>
                                    </tr>
                                    <tr>
                                      <td>Item content scanning</td>
                                      <td>2</td>
                                      <td>5</td>
                                      <td>10</td>
                                    </tr>
                                    <tr>
                                      <td>Free storage days (letters)</td>
                                      <td>5</td>
                                      <td>10</td>
                                      <td>20</td>
                                    </tr>
                                    <tr>
                                      <td>Free storage days (parcels)</td>
                                      <td>2</td>
                                      <td>5</td>
                                      <td>10</td>
                                    </tr>
                                    <tr>
                                      <td>Trashing items</td>
                                      <td>0</td>
                                      <td>0</td>
                                      <td>0</td>
                                    </tr>
                                    <tr>
                                      <td>Dropbox connection</td>
                                      <td>x</td>
                                      <td>x</td>
                                      <td>x</td>
                                    </tr>
                                    <tr>
                                      <td>Additional incoming items</td>
                                      <td>€ 2.00</td>
                                      <td>€ 1.50</td>
                                      <td>€ 1.00</td>
                                    </tr>
                                    <tr>
                                      <td>Envelope scanning</td>
                                      <td>€ 1.50</td>
                                      <td>€ 1.00</td>
                                      <td>€ 0.50</td>
                                    </tr>
                                    <tr>
                                      <td>Item content scanning</td>
                                      <td>€ 2.50</td>
                                      <td>€ 2.00</td>
                                      <td>€ 1.00</td>
                                    </tr>
                                    <tr>
                                      <td>
                                        Direct forwarding (charge per incident)
                                      </td>
                                      <td>
                                        € 4.00 + 20,00% on top of <br /> postage
                                      </td>
                                      <td>
                                        € 3.00 + 15,00% on top of <br /> postage
                                      </td>
                                      <td>
                                        € 2.00 + 10,00% on top of <br />
                                        postage
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>
                                        Collect forwarding (charge per incident)
                                      </td>
                                      <td>
                                        € 6.00 + 20,00% on top of <br /> postage
                                      </td>
                                      <td>
                                        € 4.00 + 15,00% on top of <br /> postage
                                      </td>
                                      <td>
                                        € 2.00 + 10,00% on top of <br /> postage
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>
                                        Storing items over free period (letters)
                                      </td>
                                      <td>€ 0.15</td>
                                      <td>€ 0.10</td>
                                      <td>€ 0.05</td>
                                    </tr>
                                    <tr>
                                      <td>
                                        Storing items over free period (packages)
                                      </td>
                                      <td>€ 1.00</td>
                                      <td>€ 0.75</td>
                                      <td>€ 0.50</td>
                                    </tr>
                                    <tr>
                                      <td>Scan of additional pages</td>
                                      <td>€ 0.88</td>
                                      <td>€ 0.60</td>
                                      <td>€ 0.40</td>
                                    </tr>
                                    <tr>
                                      <td>Pages included in content scan</td>
                                      <td>4</td>
                                      <td>6</td>
                                      <td>8</td>
                                    </tr>
                                    <tr>
                                      <td></td>
                                      <td>
                                        <Link className="TableBtn" to='/register'>
                                          Get Started
                                        </Link>
                                      </td>
                                      <td>
                                        <Link className="TableBtn" to='/register'>
                                          Get Started
                                        </Link>
                                      </td>
                                      <td>
                                        <Link className="TableBtn" to='/register'>
                                          Get Started
                                        </Link>
                                      </td>
                                    </tr>
                                  </table>
                                </div> */}
                                <div className="mainTableDiv">
                                  {planDetails?.contract_plan_details?.length > 0 ? (
                                    <div className="PlanComparisonDiv">
                                      <table>
                                        <tr>
                                          <th className="headcol">&nbsp;</th>
                                          {planDetails?.contract_plan_details?.map((item,index)=>{
                                            return(
                                              <th>
                                                <h2>{item?.title}</h2> € {item?.price}
                                                <br />{" "}
                                                
                                                <p>
                                                  {item?.description}
                                                </p>
                                                <span>{item?.purpose}</span>
                                              </th>
                                            )
                                          })}
                                        </tr>
                                        {/* <tr>
                                          <td>Pricing of activities - see full pricing</td>
                                          <td>High</td>
                                          <td>Medium</td>
                                          <td>Low</td>
                                        </tr> */}
                                        <tr>
                                          {/* <td className="headcol">Enthalten sind eingehende Elemente</td> */}
                                          <td className="headcol">{contentDetails?.pricing_mail_box_page_data?.tab_1_table_text_1}</td>
                                          {planDetails?.contract_plan_details?.map((item,index)=>{
                                            return(
                                              <td>
                                              {item?.included_incoming_items === null ? 'X' : item?.included_incoming_items}
                                              </td>
                                            )
                                          })}
                                          
                                        </tr>

                                        <tr>
                                          {/* <td className="headcol">Umschlagscannen (Vorderseite)</td> */}
                                          <td className="headcol">{contentDetails?.pricing_mail_box_page_data?.tab_1_table_text_2}</td>
                                          {planDetails?.contract_plan_details?.map((item,index)=>{
                                            return(
                                              <td>
                                              {item?.envelope_scanning === null ? 'X' : item?.envelope_scanning}
                                              </td>
                                            )
                                          })}
                                          {/* <td>5</td> */}
                                          {/* <td>10</td> */}
                                        </tr>

                                        <tr>
                                          {/* <td className="headcol">Inhaltsscannen von Elementen</td> */}
                                          <td className="headcol">{contentDetails?.pricing_mail_box_page_data?.tab_1_table_text_3}</td>
                                          {planDetails?.contract_plan_details?.map((item,index)=>{
                                            return(
                                              <td>
                                              {item?.item_content_scanning === null ? 'X' : item?.item_content_scanning}
                                              </td>
                                            )
                                          })}
                                          {/* <td>5</td> */}
                                          {/* <td>10</td> */}
                                        </tr>

                                        <tr>
                                          {/* <td className="headcol">Kostenlose Lagerungstage (Briefe)</td> */}
                                          <td className="headcol">{contentDetails?.pricing_mail_box_page_data?.tab_1_table_text_4}</td>
                                          {planDetails?.contract_plan_details?.map((item,index)=>{
                                            return(
                                              <td>
                                              {item?.free_storage_days_letters === null ? 'X' : item?.free_storage_days_letters}
                                              </td>
                                            )
                                          })}
                                          {/* <td>5</td>
                                          <td>10</td>
                                          <td>20</td> */}
                                        </tr>

                                        <tr>
                                          {/* <td className="headcol">Kostenlose Lagerungstage (Pakete)</td> */}
                                          <td className="headcol">{contentDetails?.pricing_mail_box_page_data?.tab_1_table_text_5}</td>
                                          {planDetails?.contract_plan_details?.map((item,index)=>{
                                            return(
                                              <td>{item?.free_storage_days_parcels === null ? 'X' : item?.free_storage_days_parcels}</td>
                                            )
                                          })}
                                          {/* <td>2</td>
                                          <td>5</td>
                                          <td>10</td> */}
                                        </tr>

                                        <tr>
                                          {/* <td className="headcol">Elemente entsorgen</td> */}
                                          <td className="headcol">{contentDetails?.pricing_mail_box_page_data?.tab_1_table_text_6}</td>
                                          {planDetails?.contract_plan_details?.map((item,index)=>{
                                            return(
                                              <td>
                                              {item?.trashing_items === null ? 'X' : item?.trashing_items}
                                              </td>
                                            )
                                          })}
                                          {/* <td>0</td>
                                          <td>0</td>
                                          <td>0</td> */}
                                        </tr>

                                        <tr>
                                          {/* <td className="headcol">Dropbox-Verbindung</td> */}
                                          <td className="headcol">{contentDetails?.pricing_mail_box_page_data?.tab_1_table_text_7}</td>
                                          {planDetails?.contract_plan_details?.map((item,index)=>{
                                            return(
                                              <td>
                                              {item?.dropbox_connection === null ? 'X' : item?.dropbox_connection}
                                                
                                              </td>
                                            )
                                          })}
                                          {/* <td>x</td>
                                          <td>x</td>
                                          <td>x</td> */}
                                        </tr>

                                        <tr>
                                          {/* <td className="headcol">Zusätzliche eingehende Elemente</td> */}
                                          <td className="headcol">{contentDetails?.pricing_mail_box_page_data?.tab_1_table_text_8}</td>
                                          {planDetails?.contract_plan_details?.map((item,index)=>{
                                            return(
                                              <td>€ 
                                              {item?.additional_incoming_items === null ? '0.00' : item?.additional_incoming_items}
                                              </td>
                                            )
                                          })}

                                          {/* <td>€ 2.00</td>
                                          <td>€ 1.50</td>
                                          <td>€ 1.00</td> */}
                                        </tr>

                                        <tr>
                                          {/* <td className="headcol">Umschlagscannen</td> */}
                                          <td className="headcol">{contentDetails?.pricing_mail_box_page_data?.tab_1_table_text_9}</td>
                                          {planDetails?.contract_plan_details?.map((item,index)=>{
                                            return(
                                              <td>€ 
                                              {item?.envelope_scanning === null ? '0.00' : item?.envelope_scanning}
                                              </td>
                                            )
                                          })}

                                          {/* <td>€ 1.50</td>
                                          <td>€ 1.00</td>
                                          <td>€ 0.50</td> */}
                                        </tr>

                                        <tr>
                                          {/* <td className="headcol">Inhaltsscannen von Elementen</td> */}
                                          <td className="headcol">{contentDetails?.pricing_mail_box_page_data?.tab_1_table_text_10}</td>
                                          {planDetails?.contract_plan_details?.map((item,index)=>{
                                            return(
                                              <td>€ 
                                              {item?.item_content_scanning === null ? '0.00' : item?.item_content_scanning}
                                              </td>
                                            )
                                          })}
                                          {/* <td>€ 2.50</td>
                                          <td>€ 2.00</td>
                                          <td>€ 1.00</td> */}
                                        </tr>

                                        <tr>
                                          {/* <td className="headcol">Direkte Weiterleitung (Kosten pro Vorfall)</td> */}
                                          <td className="headcol">{contentDetails?.pricing_mail_box_page_data?.tab_1_table_text_11}</td>
                                          {planDetails?.contract_plan_details?.map((item,index)=>{
                                            return(
                                              // <td>€ {item?.direct_forwarding_charge_per_incident == null ? '0.00' : item?.direct_forwarding_charge_per_incident} Zusätzlich zu <br /> Porto</td>
                                              <td>€ {item?.direct_forwarding_charge_per_incident == null ? '0.00' : item?.direct_forwarding_charge_per_incident} {contentDetails?.pricing_mail_box_page_data?.tab_1_table_text_11_after_value_text}</td>
                                            )
                                          })}
                                          {/* <td>
                                            € 4.00 + 20,00% on top of <br /> postage
                                          </td>
                                          <td>
                                            € 3.00 + 15,00% on top of <br /> postage
                                          </td>
                                          <td>
                                            € 2.00 + 10,00% on top of <br />
                                            postage
                                          </td> */}
                                        </tr>

                                        <tr>
                                          {/* <td className="headcol">Sammelweiterleitung (Kosten pro Vorfall)</td> */}
                                          <td className="headcol">{contentDetails?.pricing_mail_box_page_data?.tab_1_table_text_12}</td>
                                          {planDetails?.contract_plan_details?.map((item,index)=>{
                                            return(
                                              // <td>€ {item?.collect_forwarding_charge_per_incident == null ? '0.00' : item?.collect_forwarding_charge_per_incident} Zusätzlich zu <br /> Porto</td>
                                              <td>€ {item?.collect_forwarding_charge_per_incident == null ? '0.00' : item?.collect_forwarding_charge_per_incident} {contentDetails?.pricing_mail_box_page_data?.tab_1_table_text_12_after_value_text}</td>
                                            )
                                          })}
                                          {/* <td>
                                            € 6.00 + 20,00% on top of <br /> postage
                                          </td>
                                          <td>
                                            € 4.00 + 15,00% on top of <br /> postage
                                          </td>
                                          <td>
                                            € 2.00 + 10,00% on top of <br /> postage
                                          </td> */}
                                        </tr>

                                        <tr>
                                          {/* <td className="headcol">Lagerung von Elementen über die kostenlosen Tage hinaus (Briefe)</td> */}
                                          <td className="headcol">{contentDetails?.pricing_mail_box_page_data?.tab_1_table_text_13}</td>
                                          {planDetails?.contract_plan_details?.map((item,index)=>{
                                            return(
                                              <td>€ 
                                              {item?.storing_items_over_free_period_letters === null ? '0.00' : item?.storing_items_over_free_period_letters}
                                              </td>
                                            )
                                          })}
                                          {/* <td>€ 0.15</td>
                                          <td>€ 0.10</td>
                                          <td>€ 0.05</td> */}
                                        </tr>

                                        <tr>
                                          {/* <td className="headcol">Lagerung von Elementen über die kostenlosen Tage hinaus (Pakete)</td> */}
                                          <td className="headcol">{contentDetails?.pricing_mail_box_page_data?.tab_1_table_text_14}</td>
                                          {planDetails?.contract_plan_details?.map((item,index)=>{
                                            return(
                                              <td>€ 
                                              {item?.storing_items_over_free_period_packages === null ? '0.00' : item?.storing_items_over_free_period_packages}
                                              </td>
                                            )
                                          })}
                                          {/* <td>€ 1.00</td>
                                          <td>€ 0.75</td>
                                          <td>€ 0.50</td> */}
                                        </tr>

                                        <tr>
                                          {/* <td className="headcol">Scannen zusätzlicher Seiten</td> */}
                                          <td className="headcol">{contentDetails?.pricing_mail_box_page_data?.tab_1_table_text_15}</td>
                                          {/* <td>€ 0.88</td>
                                          <td>€ 0.60</td>
                                          <td>€ 0.40</td> */}
                                          {planDetails?.contract_plan_details?.map((item,index)=>{
                                            return(
                                              <td>€ 
                                              {item?.scan_of_additional_pages === null ? '0.00' : item?.scan_of_additional_pages}
                                              </td>
                                            )
                                          })}
                                        </tr>

                                        <tr>
                                          {/* <td className="headcol">Seiten, die im Inhalts-Scan enthalten sind</td> */}
                                          <td className="headcol">{contentDetails?.pricing_mail_box_page_data?.tab_1_table_text_16}</td>
                                          {planDetails?.contract_plan_details?.map((item,index)=>{
                                            return(
                                              <td>
                                              {item?.pages_included_in_content_scan === null ? 'X' : item?.pages_included_in_content_scan}
                                              </td>
                                            )
                                          })}
                                          {/* <td>4</td>
                                          <td>6</td>
                                          <td>8</td> */}
                                        </tr>
                                        <tr>
                                          <td></td>
                                          {planDetails?.contract_plan_details?.map((item,index)=>{
                                            return(
                                              <td>
                                                <StateButton className="BtnSubmit btn btn-primary" text={contentDetails?.pricing_mail_box_page_data?.BookNowButton} to='/register' userTo='/LetStarted'/>
                                              </td>
                                            )
                                          })}
                                        </tr>
                                      </table>
                                    </div>
                                  ):(
                                    // <div className="PlanComparisonDiv" style={{textAlign:'center'}}>No Plan Available</div>
                                    <div className="PlanComparisonDiv" style={{textAlign:'center'}}>{languageJson?.noPlanAvailable}</div>
                                  )}
                                </div>
                              </Col>
                            </Row>
                        </Container>
                      </div>
                    )}
                    <div dangerouslySetInnerHTML={{__html: contentDetails?.pricing_mail_box_page_data?.description_1}}>
                      {/* <p>Flexible Vertragslaufzeiten für Ihre Bedürfnisse bei CleverDomizil! </p>
                      <p>Bei CleverDomizil verstehen wir, dass Flexibilität im Geschäftsleben entscheidend ist. Deshalb bieten wir unsere Dienstleistungen in verschiedenen Kategorien mit der Möglichkeit an, Verträge mit 
Laufzeiten von 6, 12 und 24 Monaten abzuschließen.  </p>  */}

{/* Unsere Kunden können aus folgenden Kategorien wählen: */}
                      {/* <p>- Privates Postfach – Nur 23,79 € Inkl. MwSt. monatlich </p>
                      <p>- Geschäftsadresse ohne Eintragung – Nur 24,99 € zzgl. MwSt monatlich </p>
                      <p>- Geschäftsadresse mit Eintragung – Nur 49,99 € zzgl. MwSt monatlich</p> */}

                      {/* <p>Jede dieser Optionen beinhaltet eine Reihe von Leistungen, die darauf ausgelegt sind, Ihre geschäftlichen Anforderungen optimal zu erfüllen. Dazu gehören unter anderem die Möglichkeit, sich bei 
Behörden anzumelden, die Bereitstellung eines Mietvertrags für Notar und Behörden, der Empfang von Paketen und Briefen, Zugang zur CleverDomizil App sowie Dienste für das Scannen und die 
Weiterleitung Ihrer Post. Zudem steht Ihnen unser Kundenservice stets zur Verfügung.</p>
                      <p>Egal, ob Sie ein privates Postfach benötigen oder eine umfassende Geschäftsadresse mit allen Annehmlichkeiten wünschen – bei CleverDomizil finden Sie die passende Lösung. Und mit unseren 
flexiblen Vertragslaufzeiten können Sie sich für die Option entscheiden, die am besten zu Ihrer aktuellen Geschäftssituation und Ihren Zukunftsplänen passt. </p> */}
                    
                    </div>
                  </Tab>
                  {/* <Tab eventKey="phonenumber" title="Rufnummer"> */}
                  <Tab eventKey="phonenumber" title={contentDetails?.pricing_mail_box_page_data?.tab_2_text}>
                    <div className="PlanScetion">
                      <Container className="">
                        <Row>
                          <Col sm={12}>
                            <div className="ScetionTitel">
                              {/* <p>CleverDomizil Telefon App</p>
                              <h2>CleverDomizil lokale Rufnummer</h2> */}
                              <div dangerouslySetInnerHTML={{__html: contentDetails?.pricing_mail_box_page_data?.tab_2_heading}}></div>
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col sm={5}>
                            <div className="DigitalmailboxinLftCheckBox DigitalmailboxinLftCheckBox3">
                              {/* <h5>Wählen Sie ein Land aus!</h5> */}
                              <div dangerouslySetInnerHTML={{__html: contentDetails?.pricing_mail_box_page_data?.tab_2_select_box_1_label_text}}></div>
                              <Form.Select aria-label="Default select example" onChange={(e)=>{handlePhoneAreas(e.target.value);setIsSelectedArea(false)}}>
                                {/* <option selected hidden>Select Country</option> */}
                                <option selected hidden>{contentDetails?.pricing_mail_box_page_data?.tab_2_select_box_1_default_text}</option>
                                {countries?.length > 0 ? (
                                  countries?.map((item,index)=>{
                                  return(
                                    <option value={item?.id}>{item?.name}</option>
                                  )})
                                
                                ):(
                                  // <option disabled>No Countries Found.</option>
                                  <option disabled>{languageJson?.countryNotFound}</option>
                                )}
                              </Form.Select>
                              {/* <h5>Wählen Sie die entsprechende Vorwahl und Tarif aus!</h5> */}
                              <div dangerouslySetInnerHTML={{__html: contentDetails?.pricing_mail_box_page_data?.tab_2_select_box_2_label_text}}></div>
                              <Form.Select aria-label="Default select example" onChange={(e)=>fetchArea(e.target.value)}>
                                {/* <option selected hidden>Select Area</option> */}
                                <option selected hidden>{contentDetails?.pricing_mail_box_page_data?.tab_2_select_box_2_default_text}</option>
                                {areas?.length > 0 ?(
                                  areas?.map((item,index)=>{
                                    return(
                                      <option value={item?.id}>{item?.area_text}</option>
                                    )
                                  })
                                ):(
                                  // <option disabled>No Areas Found</option>
                                  <option disabled>{languageJson?.noAreaFound}</option>
                                )}
                              </Form.Select>
                            </div>
                          </Col>
                          {isSelectedArea && (
                            <Col sm={7} className="Pl-45">
                            <div className="DigitalmailboxinLftCheckBox DigitalmailboxinLftCheckBox3">
                              {/* <h5>Tarifdetails</h5> */}
                              <h5>{contentDetails?.pricing_mail_box_page_data?.tab_2_tarifdetails}</h5>
                            </div>
                            <div className="Txtlist">
                              <ul>
                                <li>
                                  <label>
                                    {/* Einrichting */}
                                    {contentDetails?.pricing_mail_box_page_data?.tab_2_tarifdetails_label_1}</label> {selectedArea?.setup_charges} €
                                </li>
                                <li>
                                  <label>
                                    {contentDetails?.pricing_mail_box_page_data?.tab_2_tarifdetails_label_2}
                                    {/* </label> {selectedArea?.monthly_fee} € / Monatlich */}
                                    </label> {selectedArea?.monthly_fee} € /  {contentDetails?.pricing_mail_box_page_data?.tab_2_tarifdetails_label_2_after_value_text}
                                </li>
                                <li>
                                  {/* <label>Gesprächsgebühr:</label> {selectedArea?.talking_fee} € per min */}
                                  <label>{contentDetails?.pricing_mail_box_page_data?.tab_2_tarifdetails_label_3}</label> {selectedArea?.talking_fee} € {contentDetails?.pricing_mail_box_page_data?.tab_2_tarifdetails_label_3_after_value_text}
                                </li>
                                <li>
                                  {/* <label>Laufzeit:</label> */}
                                  <label>{contentDetails?.pricing_mail_box_page_data?.tab_2_tarifdetails_label_4}</label>
                                  {/* {selectedArea?.contract} Monate */}
                                  {selectedArea?.contract} {contentDetails?.pricing_mail_box_page_data?.tab_2_tarifdetails_label_4_after_value_text}
                                </li>
                                <li>
                                  {/* <label>Total:</label> {selectedArea?.total} € */}
                                  <label>{contentDetails?.pricing_mail_box_page_data?.tab_2_tarifdetails_label_5}</label> {selectedArea?.total} €
                                </li>
                              </ul>
                            </div>

                            <div className="Featuresbox Featuresbox5">
                              {/* <h3>Funktionen:</h3> */}
                              <h3>{contentDetails?.pricing_mail_box_page_data?.tab_2_features}</h3>
                              {/* <ul>
                                <li>
                                  {" "}
                                  <img src={SmllTick} alt="Img" /> Make calls
                                </li>
                                <li>
                                  {" "}
                                  <img src={SmllCross} alt="Img" /> Receive
                                  calls
                                </li>
                                <li>
                                  <img src={SmllTick} alt="Img" /> You can use
                                  any address world-wide to buy phone number
                                </li>
                                <li>
                                  {" "}
                                  <img src={SmllTick} alt="Img" />
                                  Can't receive SMS
                                </li>
                                <li>
                                  {" "}
                                  <img src={SmllTick} alt="Img" />
                                  Can’t receive fax
                                </li>
                                <li>
                                  {" "}
                                  <img src={SmllCross} alt="Img" /> Free test
                                  phone numbers
                                </li>
                              </ul> */}
                                <ul>
                                  {features?.map((item,index)=>{
                                    return(
                                      <li>
                                        {" "}
                                        {item?.access_status == 0 ? (
                                          <img src={RedCross} alt="Img" />
                                        ):
                                        item?.access_status == 1 ? (
                                          <img src={SmllTick} alt="Img" />
                                        ):
                                        item?.access_status == 2 ? (
                                          <img src={DefaultIcon} alt="Img" />
                                        ):null}
                                        {item?.feature}
                                      </li>
                                    )
                                  })}
                                </ul>
                              <StateButton className="BtnSubmit btn btn-primary" text={contentDetails?.pricing_mail_box_page_data?.BookNowButton} to='/register' userTo='/LetStarted'/>
                        
                            </div>
                            </Col>
                          )}

                        </Row>
                      </Container>
                    </div>
                  </Tab>
                </Tabs>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default PricingMailbox;
