import React, { useEffect, useRef, useState } from "react";
import StudentHeader from "../../components/header/StudentHeader";
import "./AccountDetails.scss";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Step from "../../components/step/Step";
import Form from "react-bootstrap/Form";
import SmllTick from "../../assets/images/SmllTick.svg";
import SmllCross from "../../assets/images/SmllCross.svg";
import Modal from "react-bootstrap/Modal";
import TickBlue from "../../assets/images/TickBlue.svg";
import Button from "react-bootstrap/Button";
import { HiArrowNarrowRight, HiArrowNarrowLeft, HiCheck } from "react-icons/hi";
import { useNavigate, Link, useLocation } from "react-router-dom";
import Remove from "../../assets/images/Remove.svg";
import InputGroup from "react-bootstrap/InputGroup";
import {NotificationManager} from 'react-notifications';

import { studentFileListing, studentFileSection, studentInfoPortal, studentRegisterDetails, studentTimeline } from "../../api/apiRequest";
import axios from "axios";
import { useLangContext } from "../../context/languageContext";

const AccountDetails = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const {hostLanguage,languageJson} = useLangContext();
  const fileUniversityRef = useRef(null);
  const fileSchoolRef = useRef(null);
  const filePrevUniversityRef = useRef(null);
  const fileColorPhotoRef = useRef(null);
  const filePassportRef = useRef(null);
  const fileLanguageRef = useRef(null);
  const fileGermanRef = useRef(null);
  // console.log(location)
  // console.log(location?.state)

  const [thanksModal, setThanksModal] = useState(false);
  const thankhandleClose = () => setThanksModal(false);
  const thankhandleShow = () => setThanksModal(true);
  const [isUploadError,setIsUploadError] = useState(false);
  const [loading,setLoading] = useState(false);

  const [fileError] = useState({
    university_file: languageJson?.university_file,
    scchool_leave_file: languageJson?.scchool_leave_file,
    prev_university_file: languageJson?.prev_university_file,
    color_photo_file: languageJson?.color_photo_file,
    passport_file: languageJson?.passport_file,
    language_file: languageJson?.language_file,
    german_file: languageJson?.german_file,
    file_upload_error1: languageJson?.file_upload_error1,
    file_upload_error2: languageJson?.file_upload_error2
  })
  const [allFiles,setAllFiles] = useState([]);
  const [universityPdf,setUniversityPdf] = useState();
  const [schoolPdf,setSchoolPdf] = useState();
  const [prevUniversityPdf,setPrevUniversityPdf] = useState();
  const [colorPhotoPdf,setColorPhotoPdf] = useState();
  const [passportPdf,setPassportPdf] = useState();
  const [languagePdf,setLanguagePdf] = useState();
  const [germanPdf,setGermanPdf] = useState();


  const uploadFileSection = async() => {
    let apiRes = await studentFileListing(hostLanguage);
    let responseData = await apiRes.json();
    if(responseData.res)
    {
      let i = 0;
      let tempArr = [];
      let tempobj = {
        'index': '',
        'name': '',
        'file': '',
        'require': 0
      };
      
      for(i = 0 ; i<responseData?.data?.length; i++)
      {
        tempobj = {
          // index: responseData.data[i].id,
          index: i + 1,
          name: responseData.data[i].upload_file_text,
          file : '',
          require : responseData.data[i].required_status
        }
        tempArr.push(tempobj);
      }
      console.log(tempArr);

      setAllFiles(tempArr);
    }
    else
    {
      console.error(responseData.res);
    }
  }

  const uploadFile = (e,file_index)=>
  {
    const selectedFile = e.target.files[0];
    // console.log(file_index)
    if(selectedFile)
    {
      // console.log(e)
      if(selectedFile?.type === 'application/pdf')
      {
        if (selectedFile?.size > 2 * 1024 * 1024)
        {
          NotificationManager.warning(languageJson?.file_exceeding_msg, '', 2000);
          e.target.value='';
        }
        else
        {
          let i = 0;
          for( i= 0; i<allFiles?.length; i++)
          {
            const foundIndex = allFiles?.findIndex(item => item.index === file_index);
            // console.log(foundIndex)
            allFiles[foundIndex].file = selectedFile
            // console.log(allFiles);
          }
        }
      }
      else
      {
        e.target.value='';
        NotificationManager.warning(languageJson?.file_upload_error1);
      }
    }
    else
    {
      console.log('File selection was canceled or no file was selected.');
      // console.log(selectedFile)
      // console.log(e)
      let i = 0;
        for( i= 0; i<allFiles?.length; i++)
        {
          const foundIndex = allFiles?.findIndex(item => item.index === file_index);
          // console.log(foundIndex)
          allFiles[foundIndex].file = ''
          // console.log(allFiles);
        }
    }
  }

  // const RemoveFile = (remove_index)=>
  // {
  //   let i = 0;
  //   for( i= 0; i<allFiles?.length; i++)
  //   {
  //     const foundIndex = allFiles?.findIndex(item => item.index === remove_index);
  //     allFiles[foundIndex].file = '';
  //     console.log(allFiles);
  //     setIsUploadError(false);
  //   }
  // }

  const submitStudentPortalData = async () => {
    const hasRequiredFiles = allFiles.some(item => item.require === 1 && item.file == '');
    if (hasRequiredFiles) 
    {
      NotificationManager.warning(languageJson?.upload_mandatory_file, '', 2000);
      return;
    }
    else
    {
      setIsUploadError(false);
      setLoading(true);
      const formData = new FormData();
      // formData.append('title', location?.state?.title);
      // formData.append('first_name', location?.state?.first_name);
      // formData.append('last_name', location?.state?.last_name);
      // formData.append('address', location?.state?.address);
      // formData.append('company_name', location?.state?.company_name);
      // formData.append('country', location?.state?.country);
      // formData.append('state', location?.state?.state);
      // formData.append('city', location?.state?.city);
      // formData.append('postcode', location?.state?.postcode);
      // formData.append('phone_number', location?.state?.phone_number);
      formData.append('lang', hostLanguage);
  
      allFiles.forEach((item, index) => {
        formData.append(`pdf${item.index}`, item.file === '' ? '' : item.file);
      });
  
      try
      {
        let apiRes = await studentFileSection(formData);
        if (apiRes)
        {
          setLoading(false);
          setThanksModal(true);
        }
        else
        {
          setLoading(false);
        }
      }
      catch (error)
      {
        setLoading(false);
        console.error('Error submitting data:', error);
      }
    }
  }
  
  const redirectURL = () => {
    thankhandleClose();
    navigate("/student-dashboard");
  };

  const backRedirection = async() =>{
    try
    {
      let apiRes = await studentTimeline(hostLanguage);
      let responseData = await apiRes.json();

      if(responseData.res)
      {
        navigate("/student-dashboard");
      }
      else
      {
        navigate('/student-account-set-up');
        console.error(responseData.msg);
      }
    }
    catch(error)
    {
      console.error(error);
    }
  }

  // student registration checking
  const studentRegisterStep = async() => {
    try
    {
      let apiRes = await  studentRegisterDetails();
      let responseData = await apiRes.json();
      if(responseData.res)
      {
        console.warn(responseData);
        if(responseData?.is_user_data == 0)
        {
          navigate('/student-account-set-up');
        }
        else
        {
          if(responseData?.is_user_data == 1)
          {
            if(responseData?.is_file_upload == 0)
            {
              navigate('/student-account-details');
            }
            else if(responseData?.is_file_upload == 1)
            {
              navigate('/student-dashboard');
            }
          }
          else
          {
            console.error(responseData?.is_user_data);
          }
        }
      }
      else
      {
        NotificationManager.error(responseData.msg,'',2000);
      }
    }
    catch(error)
    {
      console.error(error);
    }
  }

  useEffect(()=>{
    studentRegisterStep();
    uploadFileSection();
    // backRedirection();
  },[hostLanguage])

  return (
    <div className="lightBlue">
      <StudentHeader />
      <div className="Hrbottom">
        <div className="container">
          <Row className="align-items-center">
            <Col sm={5}>
              <h2>{languageJson?.set_up_student_account}</h2>
            </Col>
            <Col sm={7}>
              <div class="form-steps">
                <div class="form-steps__step form-steps__step--active">
                  <div class="form-steps__step-circle">
                    <span class="form-steps__step-number">
                      <HiCheck size="20" />
                    </span>
                  </div>
                  <span class="form-step__step-name">{languageJson?.lets_started}</span>
                </div>

                <div class="form-steps__step form-steps__step--last form-steps__step--active">
                  <div class="form-steps__step-circle">
                    <span class="form-steps__step-number">02</span>
                  </div>
                  <span class="form-step__step-name">{languageJson?.account_setup}</span>
                </div>

                {/* <div class="form-steps__step form-steps__step--incomplete">
                  <div class="form-steps__step-circle">
                    <span class="form-steps__step-number">03</span>
                  </div>
                  <span class="form-step__step-name">Your Details</span>
                </div>

                <div class="form-steps__step form-steps__step--last">
                  <div class="form-steps__step-circle">
                    <span class="form-steps__step-number">04</span>
                  </div>
                  <span class="form-step__step-name">Payment</span>
                </div> */}
              </div>
            </Col>
          </Row>
        </div>
      </div>
      <div className="MainBody">
        <div className="container mb-4">
          <Row className="">
            <Col sm={12}>
              <div className="FrmBody FrmBodyLft">
                <div>
                  <div className="newChwckDiv">
                    <div className="newFrmBoxBottom">
                      <div className="newFrmBoxBottomFrm">
                        <p>
                          <strong>
                            {/* Applicants must have the following documents to apply to a German/Austrian university: */}
                            {languageJson?.account_details_file_heading}
                          </strong>
                          <h6 className="error">*{languageJson?.mandatory_heading}</h6>
                        </p>
                        {allFiles?.length > 0 ? (
                          allFiles?.map((item,index)=>{
                            return(
                              <>
                                <Form.Group className="text-left">
                                  {item?.require == 1 ? (
                                    // <Form.Label className="error">* {item?.index}. {item?.name}</Form.Label>
                                    <>
                                      <span className="error">*</span>
                                      <Form.Label>{item?.index}. {item?.name}</Form.Label>
                                    </>
                                  ):(
                                    <Form.Label className="">{item?.index}. {item?.name}</Form.Label>
                                  )}
                                  {/* && <div className="error">{item?.index} No. field is mandatory</div>) */}
                                  
                                </Form.Group>
                                <InputGroup className="mb-4">
                                  <Form.Control
                                    type="file"
                                    id="fileInput"
                                    // value={item?.file?.name}
                                    // ref={fileUniversityRef}
                                    onChange={(e)=>uploadFile(e,item?.index)}
                                  />
                                    {/* <Button variant="outline-secondary" id="button-addon2" onClick={()=>RemoveFile(item?.index)}>
                                      <img src={Remove} alt="Img" />
                                    </Button> */}
                                </InputGroup>
                                {/* {item?.file == '' && item?.require == 1 && item.showError && (<div className="error">{item?.index} No. field is mandatory</div>)} */}
                              </>
                            )
                          })
                        ):(
                          <>{languageJson?.no_record_found}</>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </div>

        <div className="container">
          <div className="BtnBottom">
            {/* <Button variant="primary" type="button" className="BtnBack" onClick={() => navigate('/student-account-set-up')}>
              <HiArrowNarrowLeft /> {languageJson?.back_buton}
            </Button> */}
              {loading ? (
                <Button variant="primary" type="submit" className="BtnSubmit" disabled>
                  {languageJson?.please_wait} <HiArrowNarrowRight />
                </Button>
              ):(
                <Button variant="primary" type="submit" className="BtnSubmit" onClick={()=>submitStudentPortalData()}>
                  {languageJson?.proceed_button} <HiArrowNarrowRight />
                </Button>
              )}
            
          </div>
        </div>

        <Modal
          show={thanksModal}
          onHide={thankhandleClose}
          backdrop="static"
          keyboard={false}
          // size="lg"
          centered
          className="CustomModal CustomModalTnk"
        >
        <Modal.Header closeButton>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <img src={TickBlue} className="TickBlueLogo" alt="logo" />
          {/* <h5>Thank you for choosing our company. We will contact you shortly.</h5> */}
          <h5>{languageJson?.student_portal_thanks_msg}</h5>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" className="rgtBtn" onClick={()=>redirectURL()}>
            {languageJson?.proceed_button}
          </Button>
        </Modal.Footer>
        </Modal>
      </div>
    </div>
  );
};

export default AccountDetails;
