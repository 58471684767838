import { createContext, useContext, useState } from "react";

const MyContext = createContext();

export function CommonContext({ children }) {
  const [eventFire, setEventFire] = useState(false);
  const [mailCount, setMailCount] = useState(0);
  const [cartCount, setCartCount] = useState(0);
  const [currency, setCurrency] = useState("EUR");
  const [currencySymbol, setCurrencySymbol] = useState("€");


  const updateMailCount = (count) => {
    setMailCount(count);
  };

  const updateCartCount = (count) => {
    setCartCount(count);
  };

  return <MyContext.Provider value={{ eventFire, setEventFire, mailCount, updateMailCount, cartCount, setCartCount, currency, setCurrency, currencySymbol, setCurrencySymbol }}>{children}</MyContext.Provider>;
}

export function useContextValues() {
  return useContext(MyContext);
}
