import { NotificationManager } from "react-notifications";
import secureLocalStorage from "react-secure-storage";
import { googleLogout } from "@react-oauth/google";

export const onLogout = () => {
  localStorage.removeItem("virtualOfficeLoginInfo");
  localStorage.clear("virtualOfficeLoginInfo");
  googleLogout();
  NotificationManager.success("Logout successfully", "", 3000);
};

export const logInDetails = () => {
  let value = JSON.parse(secureLocalStorage.getItem("virtualOfficeLoginInfo"));
  return value;
};

export const dateFormat = (date) => {
  if (date !== undefined) {
    const [year, month, day] = date.split("-");
    const result = [month, day, year].join("-");
    return result;
  } else {
    return 0;
  }
};

export const changeTitle = (newTitle) => {
  // document.title = newTitle;
};
