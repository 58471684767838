// LanguageContext.js

// import React, { createContext, useState } from 'react';

// export const LanguageContext = createContext();

// export const LanguageProvider = ({ children }) => {
//   const [language, setLanguage] = useState('en');

//   const changeLanguage = (newLanguage) => {
//     setLanguage(newLanguage);
//   };

//   return (
//     <LanguageContext.Provider value={{ language, changeLanguage }}>
//       {children}
//     </LanguageContext.Provider>
//   );
// };

// LanguageContext.js

import React, { createContext, useState, useContext, useEffect } from "react";
import EnglishJson from "../language/en.json";
import GermanJson from "../language/de.json";
import SpainJson from "../language/es.json";
import FarsiJson from "../language/fa.json";
import FranceJson from "../language/fr.json";
import PortugalJson from "../language/pt.json";
import RussianJson from "../language/ru.json";
import TurkeyJson from "../language/tr.json";
import ChinaJson from "../language/zh.json";

export const LanguageContext = createContext();

export const LanguageProvider = ({ children }) => {
  const [language, setLanguage] = useState("en");
  const [activeLanguage, setActiveLanguage] = useState(false);
  const [hostLanguage, setHostLanguage] = useState("de");
  const [languageJson, setLanguageJson] = useState(GermanJson);

  const changeLanguage = (newLanguage) => {
    setLanguage(newLanguage);
    // console.warn(newLanguage);
    switch (newLanguage) {
      case "de":
        setHostLanguage("de");
        setLanguageJson(GermanJson);
        break;
      case "en":
        setHostLanguage("en");
        setLanguageJson(EnglishJson);
        break;
      case "es":
        setHostLanguage("es");
        setLanguageJson(SpainJson);
        break;
      case "fa":
        setHostLanguage("fa");
        setLanguageJson(FarsiJson);
        break;
      case "fr":
        setHostLanguage("fr");
        setLanguageJson(FranceJson);
        break;
      case "pt":
        setHostLanguage("pt");
        setLanguageJson(PortugalJson);
        break;
      case "ru":
        setHostLanguage("ru");
        setLanguageJson(RussianJson);
        break;
      case "tr":
        setHostLanguage("tr");
        setLanguageJson(TurkeyJson);
        break;
      case "zh":
        setHostLanguage("zh");
        setLanguageJson(ChinaJson);
        break;

      default:
        setHostLanguage("de");
        setLanguageJson(GermanJson);
        break;
    }
  };

  const setActivateLanguage = (isActive) => {
    setActiveLanguage(isActive);
  };

  return (
    <LanguageContext.Provider value={{ language, changeLanguage, activeLanguage, setActivateLanguage, hostLanguage, setHostLanguage, languageJson }}>
      {children}
    </LanguageContext.Provider>
  );
};

export function useLangContext() {
  return useContext(LanguageContext);
}
