import React, { useState, useEffect } from "react";
import Mainheader from "../../components/mainheader/Mainheader";
import Footer from "../../components/footer/Footer";

import Container from "react-bootstrap/Container";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import "./Rooms.scss";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Room from "../../components/room/Room";

import { useLangContext } from "../../context/languageContext";
import { getRoomCountries, getRoomCitiesByCountry } from "../../api/apiRequest";
import { alertTitleClasses } from "@mui/material";

const Rooms = () => {
    const {hostLanguage,languageJson} = useLangContext();
    const [countries,setCountries] = useState([]);
    const [countryTab,setCountryTab] = useState();

    const [cities,setCities] = useState([]);

    //GET countries
    const getCountries = async() => {
        try
        {
            let apiRes = await getRoomCountries();
            let responseData = await apiRes.json();
            if(responseData.res)
            {
                const dataArr = Object.values(responseData.data).flat();
                setCountries(dataArr);
                
                
            } else {
                //
            }
        }
        catch(error)
        {
            // NotificationManager.error('Something went wrong!', '',2000);
        }
    }
    useEffect(()=>{
        getCountries();
    },[hostLanguage])

    //GET cities
    const getCities = async(country) => {
        try
        {
            let apiRes = await getRoomCitiesByCountry(country);
            let responseData = await apiRes.json();
            if(responseData.res)
            {
                const dataArr = Object.values(responseData.data).flat();
                setCities(dataArr);
            } else {
                //
            }

            
        }
        catch(error)
        {
            // NotificationManager.error('Something went wrong!', '',2000);
        }
    }
    useEffect(()=>{
        if(countries.length > 0){
            if(countryTab == undefined){
                setCountryTab(countries[0]?.id)
            }
        }
    },[countries])
    
    useEffect(()=>{
        if(countryTab !== undefined){
            setCities([]);
            getCities(countryTab);
        }
    },[countryTab])

    return (
        <div className="WebBody">
            <Mainheader />
            <div className="MainbBody ConferenceRoom">
                <Container>
                    <Tabs 
                        id="countries"
                        defaultActiveKey={countryTab}
                        onSelect={setCountryTab}
                    >
                        {countries?.length > 0 ? (
                            countries?.map((country)=>{
                                return(
                                    <Tab eventKey={country?.id} title={country?.name}>
                                        {cities?.length > 0 ? (
                                            cities?.map((city)=>{
                                                return(
                                                    <Row>
                                                        <Col sm={12}>
                                                            <div className="ScetionTitel">
                                                                <h2>{city?.name}</h2>
                                                            </div>
                                                        </Col>
                                                        {city.addresses?.map((address)=>{
                                                            return (
                                                                <Col sm={12}>
                                                                    {address?.rooms?.map((room)=>{
                                                                        return (
                                                                            <Room entity={room} address={address} city={city}/>
                                                                        );
                                                                    })}
                                                                </Col>
                                                            );
                                                        })}
                                                    </Row>
                                                )
                                            })
                                        ):(
                                            ''
                                        )}
                                    </Tab>
                                )
                            })
                        ):(
                            ''
                        )}
                    </Tabs>
                    {countries?.length <= 0 ? (
                        <div>
                            {languageJson?.no_rooms}
                        </div>
                    ):(
                        ''
                    )}
                </Container>
            </div>
            <Footer />
        </div>
    );
}
export default Rooms;