import L from 'leaflet';
import Marker from "leaflet/dist/images/marker-icon.png";

const MarkerIcon = new L.Icon({
    iconUrl: Marker,
    iconRetinaUrl: Marker,
    iconAnchor: null,
    popupAnchor: null,
    shadowUrl: null,
    shadowSize: null,
    shadowAnchor: null,
    iconSize: new L.Point(25, 41),
    className: 'leaflet-icon'
});

export default MarkerIcon;