import React, { useState } from "react";
import "./mailboxright.scss";
import scanDocument from "../../assets/images/scan-document-img.png";
import invoice from "../../assets/images/invoice.png";
import envelopimg from "../../assets/images/envelop-img.png";
import pdfIcon from "../../assets/images/pdf_icon.png";
import noImg from "../../assets/images/noImg.jpg";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import FileSaver from "file-saver";
import { saveAs } from "file-saver";
import { NotificationManager } from "react-notifications";

const Mailboxright = (props) => {
  const { recordId, envelopDoc, scanDoc } = props;
  const [show, setShow] = useState(false);
  const pdfUrls = [];
  pdfUrls.push(envelopDoc);
  pdfUrls.push(scanDoc);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  //file download
  const handleDownloadAllFiles = async () => {
    saveAs(envelopDoc);
    saveAs(scanDoc);

    var blob = new Blob(["Hello, world!"], {
      type: "text/plain;charset=utf-8",
    });
    FileSaver.saveAs(blob, "hello world.txt");
  };

  //download files
  const handleImageDownload = async (type) => {
    try {
      if (type === "envelop") {
        if (envelopDoc === "NoUrl") {
          // console.error("EnvelopDoc is not found: " + envelopDoc);
        } else {
          // NotificationManager.success("File Download has started..",'',2000);
          await downloadFile(envelopDoc, "envelop.png");
        }
      } else if (type === "scan") {
        if (scanDoc === "NoUrl") {
          // console.error("ScanDoc is not found: " + scanDoc);
        } else {
          // NotificationManager.success("File Download has started..",'',2000);
          await downloadFile(scanDoc, "scan_document.pdf");
        }
      } else {
        throw new Error("Error while downloading the file.");
      }
    } catch (error) {
      console.error(error);
    }
  };

  const downloadFile = async (url, fileName) => {
    window.open(url, "_blank");
    // const response = await fetch(url);
    // const blob = await response.blob();

    // const link = document.createElement('a');
    // link.href = window.URL.createObjectURL(blob);
    // link.download = fileName;
    // document.body.appendChild(link);
    // link.click();
    // document.body.removeChild(link);
  };

  return (
    <div className="right-content-mail-box">
      {envelopDoc != "NoUrl" ? (
        <>
          <h3>Envelop</h3>
          <div>
            <img src={envelopDoc} className="popupimg" alt="logo" onClick={() => handleImageDownload("envelop")} style={{ cursor: "pointer" }} />
          </div>
        </>
      ) : (
        <div>
          <img src={noImg} className="popupimg" alt="logo" style={{ cursor: "pointer" }} />
        </div>
      )}

      {scanDoc === "NoUrl" ? (
        <></>
      ) : (
        <>
          <h3>Scan document</h3>
          <div>
            <img src={pdfIcon} className="popupimg" alt="logo" onClick={() => handleImageDownload("scan")} style={{ cursor: "pointer" }} />
          </div>
        </>
      )}

      <Modal show={show} onHide={handleClose} backdrop="static" keyboard={false} centered className="CustomModal" size="lg">
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">Modal heading</Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-center">
          <img src={invoice} className="Modalimg" alt="logo" />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose} className="lftBtn">
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default Mailboxright;
