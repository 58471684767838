import React, { useState, useEffect } from "react";
import Mainheader from "../../components/mainheader/Mainheader";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Footer from "../../components/footer/Footer";
import "./BlogDeatils.scss";
import BlogHero from "../../assets/images/BlogHero.jpg";
import { useNavigate, Link, useLocation } from "react-router-dom";
import Image from "react-bootstrap/Image";
import { NotificationManager } from "react-notifications";
import Header from "../../components/header/Header";
import { getblogsByid } from "../../api/apiRequest";
import { useLangContext } from "../../context/languageContext";

const BlogDeatils = () => {
  const {hostLanguage} = useLangContext();
  const [viewData,setViewData] = useState();
  const navigate = useNavigate();
  const { state } = useLocation();

  
  const handleRecordView = async(record_id) => {
    try
    {
      let apiRes = await getblogsByid(record_id,hostLanguage);
      let responseData = await apiRes.json();
      if(responseData.res)
      {
        setViewData(responseData.data[0]);
      }
      else
      {
        NotificationManager.error(responseData.msg,'',2000);
      }
    }
    catch(error)
    {
        // NotificationManager.error('Something went wrong!', '',2000);
    }
  }

  // const formatDate = (inputDate) => {
  //   const options = { day: 'numeric', month: 'short', year: 'numeric' };
  //   const date = new Date(inputDate);
  //   const formattedDate = date.toLocaleDateString('en-US', options);
  
  //   // Extract day, month, and year
  //   const day = date.getDate();
  //   const month = date.toLocaleDateString('en-US', { month: 'short' });
  //   const year = date.getFullYear();
  
  //   // Add the appropriate suffix to the day
  //   const dayWithSuffix = day + (day % 10 === 1 && day !== 11 ? 'st' : day % 10 === 2 && day !== 12 ? 'nd' : day % 10 === 3 && day !== 13 ? 'rd' : 'th');
  
  //   return `${dayWithSuffix} ${month} ${year}`;
  // };

  const formatDate = (inputDate) => {
    // const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
    // return date.toLocaleDateString('en-GB', options);
  
    // const date = new Date(inputDate);
    // const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
    // return date.toLocaleDateString('en-GB', options);
    
    const date = new Date(inputDate);
    const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
    return date.toLocaleDateString('en-GB', options).replace(/\//g, '.');
  
  }

  useEffect(()=>{
    // console.warn(state?.id)
    if(state?.id!= undefined)
    {
      sessionStorage.setItem('blogId', JSON.stringify(state?.id));
      handleRecordView(state?.id);
    }
    else
    {
      let state_id = sessionStorage.getItem('blogId');
      handleRecordView(parseInt(state_id));
    }
  },[hostLanguage]);

  const getBannerImgLink = (img_link) => {
    try
    {
      let base_url = 'https://cleverdomizil.de';
      let url = base_url + img_link?.split("httpdocs")[1];
      return url
    }
    catch(error)
    {
      console.error(error);
    }
  }
  
  return (
    <div className="WebBody">
      <Mainheader />
      {/* <Header />  */}
      {viewData!=undefined && (
        <div className="BlogDeatilsBody">
          <div className="BlogDeatilsInfo">
            <div className="BlogDeatilsInfoImg">
              <Image src={getBannerImgLink(viewData?.image)} className="BlogHero" alt="BlogHero" fluid />
            </div>

            <div className="BlogDeatilsInfoTxt">
              <Container>
                <Row>
                  <Col sm={12}>
                    <h5>{formatDate(viewData?.created_at)}</h5>
                    <h4>{viewData?.title}
                    </h4>
                    <div dangerouslySetInnerHTML={{ __html: viewData?.description }} />
                  </Col>
                </Row>
              </Container>
            </div>
          </div>
        </div>
      )}
      <Footer />
    </div>
  );
};

export default BlogDeatils;
