import React, { useEffect, useState } from "react";
import InnerHeader from "../../components/header/InnerHeader";
import "./NewAddress.scss";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Sidebar from "../../components/sidebar/Sidebar";
import Button from "react-bootstrap/Button";
import { useLocation, useNavigate } from "react-router-dom";
import {NotificationManager} from 'react-notifications';

import { getCountry, getStateByCountryId, getCityByRegionId, sendMeNewAddress, getPageContent } from "../../api/apiRequest";
import { useLangContext } from "../../context/languageContext";
// /AllMail

const NewAddress = (props) => {
  const {setNewAddressData,paysetShow,id,modalClose} = props;
  const {hostLanguage,languageJson} = useLangContext();
  const navigate = useNavigate();
  let location = useLocation();
  console.warn(location?.state?.id);
  console.warn(props);

  const [sendingAddressError,setSendingAddressError] = useState(false);
  const [sendingAddressTitle,setSendingAddressTitle] = useState();
  const [sendingAddressFirstName,setSendingAddressFirstName] = useState();
  const [sendingAddressLastName,setSendingAddressLastName] = useState();
  const [sendingAddressCompanyName,setSendingAddressCompanyName] = useState();
  const [sendingAddress,setSendingAddress] = useState();
  const [sendingAddressCountry,setSendingAddressCountry] = useState();
  const [sendingAddressRegion,setSendingAddressRegion] = useState();
  const [sendingAddressCity,setSendingAddressCity] = useState();
  const [sendingAddressPostalCode,setSendingAddressPostalCode] = useState();
  const [sendingAddressAreaCode,setSendingAddressAreaCode] = useState();
  const [sendingAddressPhoneNumber,setSendingAddressPhoneNumber] = useState();
  const [showAllCountries2,setShowAllCountries2] = useState([]);
  const [selectedLegalRegionByCountryId2,setLegalRegionByCountryId2] = useState([]);
  const [selectedCityWithId2, setSelectedCityWithId2] = useState([]);
  const [contentDetails,setContentDetails] = useState();

    // -- Forwarding address error variables-- 
    const [sendAddError] = useState({
      // title: 'Enter Title',
      // first_name: 'Enter First Name',
      // last_name: 'Enter Last Name',
      // company_name: 'Enter Company Name',
      // address: 'Enter Address',
      // country: 'Enter Country',
      // region: 'Enter Region',
      // city: 'Enter City',
      // postal_code: 'Enter Postal Code',
      // area_code: 'Enter Area Code',
      // phone_number: 'Enter Phone Number'

      title: languageJson?.title_validation,
      first_name: languageJson?.first_name_validation,
      last_name: languageJson?.last_name_validation,
      company_name: languageJson?.company_name_validation,
      address: languageJson?.address_validation,
      country: languageJson?.select_country_validation,
      region: languageJson?.select_region_validation,
      city: languageJson?.select_city_validation,
      postal_code: languageJson?.postal_code_validation,
      area_code : languageJson?.area_code_validation,
      phone_number: languageJson?.phone_number_validation
    })

const handleCountryList2 = async() =>
{
  try
  {
    let apiRes = await getCountry();
    let responseData = await apiRes.json();
    if(responseData)
    {
      setShowAllCountries2(responseData.data);
    }
    else
    {
      NotificationManager.error(responseData.msg, '',2000);
    }
  }
  catch(error)
  {
    // NotificationManager.error('Something went wrong!', '',2000);
  }
}

  // get page content
  const getAllPageContent = async() => {
    try
    {
      let apiRes = await  getPageContent(hostLanguage);
      let responseData = await apiRes.json();
      if(responseData.res)
      {
        setContentDetails(responseData.data);
        // console.warn(responseData.data.header);
      }
      else
      {
        NotificationManager.error(responseData.msg,'',2000);
      }
    }
    catch(error)
    {
      console.error(error);
    }
  }

const handleRegionsByCountryId2 = async (country_id) => {
  try
  {
    let apiRes = await getStateByCountryId(country_id);
    let responseData = await apiRes.json();
    if(responseData)
    {
      setLegalRegionByCountryId2(responseData);
    }
    else
    {
      NotificationManager.error(responseData.msg, '',2000);
    }
  }
  catch(error)
  {
  // NotificationManager.error('Something went wrong!', '',2000);
  }
}

const getCityByRegionData = async (region_id) => {
  try
  {
    let apiRes = await getCityByRegionId(region_id);
    let responseData = await apiRes.json();
    if(responseData)
    {
      setSelectedCityWithId2(responseData);
    }
    else
    {
      NotificationManager.error(responseData.msg, '',2000);
    }
  }
  catch(error)
  {
    // NotificationManager.error('Something went wrong!', '',2000);
  }
}

// Sending address
const handleSendingAddress = async () => 
{
  if(
    sendingAddressTitle == undefined || sendingAddressFirstName == undefined || sendingAddressLastName == undefined ||
    sendingAddressCompanyName == undefined ||  sendingAddress == undefined || sendingAddressCountry == undefined ||
    sendingAddressCity == undefined || sendingAddressPostalCode == undefined || sendingAddressRegion == undefined ||
    sendingAddressPhoneNumber == undefined
  )
  {
    setSendingAddressError(true);
    NotificationManager.error('Blank fields are not allowed!', '',2000);
  }
  else
  {
    setSendingAddressError(false);
    try
    {
      let values = {
        // id: location?.state?.id,
        // company_name:sendingAddressCompanyName,
        // title:sendingAddressTitle,
        // first_name: sendingAddressFirstName,
        // last_name: sendingAddressLastName,
        // address: sendingAddress,
        // country_id: sendingAddressCountry,
        // city_id: sendingAddressCity,
        // region_id: sendingAddressRegion,
        // postal_code: sendingAddressPostalCode,
        // phone_number: sendingAddressPhoneNumber

          id: id,
          deduct_wallet_balance:0,
          amount:1.00,
          same_as_billing:0,
          company_name:sendingAddressCompanyName,
          title:sendingAddressTitle,
          first_name: sendingAddressFirstName,
          last_name: sendingAddressLastName,
          address: sendingAddress,
          country_id: sendingAddressCountry,
          city_id: sendingAddressCity,
          region_id: sendingAddressRegion,
          postal_code: sendingAddressPostalCode,
          area_code: sendingAddressAreaCode,
          phone_number:sendingAddressPhoneNumber
      }
      if(values == '')
      {
        NotificationManager.error('Please fill out all the required fields before proceeding!', '',2000);
      }
      else
      {
        setNewAddressData(values);
        paysetShow(true);
        modalClose();
        // let apiRes = await sendMeNewAddress(values);
        // let responseData = await apiRes.json();
        // if(responseData.res)
        // {
        //   navigate('/AllMail');
        // }
        // else
        // {
        //   NotificationManager.error(responseData.msg, '',2000);
        // }
      }
    }
    catch(error)
    {
      // NotificationManager.error('Something went wrong!', '',2000);
    }
  }
}

useEffect(()=>{
  handleCountryList2();
  getAllPageContent();
},[hostLanguage])

  return (
    <div className="lightBlue">
      <div className="mail-box-main-body">
        <Row className="mail-box-body">
          <Col sm={12}>
            <div className="NewaddressBox">
              {/* <h2 style={{ textAlign:'center'}}>Sending address</h2> */}
              <h2 style={{ textAlign:'center'}}>{contentDetails?.after_login?.sending_address}</h2>
              <h3>
                {/* We will send your item’s to this address as your requested */}
                {contentDetails?.after_login?.sending_address_desc}
              </h3>

              <div className="NewaddressBoxtInner">
                <div>
                  <Row>
                    <Col sm={6}>
                      <Form.Group className="mb-4 text-left">
                        {/* <Form.Label>Tittle</Form.Label> */}
                        <Form.Label>{contentDetails?.your_details_page_data?.title_label}</Form.Label>
                        <Form.Select aria-label="Default select example" name="title" onChange={(e)=>setSendingAddressTitle(e.target.value)}>
                          {/* <option selected hidden>Select Title</option>
                          <option value="Mr.">Mr.</option>
                          <option value="Mrs.">Mrs.</option>
                          <option value="Dr." >Dr.</option>
                          <option value="Prof.Dr.">Prof.Dr.</option> */}
                          <option selected hidden>{contentDetails?.your_details_page_data?.Select_Title}</option>
                          <option value="Mr.">{contentDetails?.your_details_page_data?.Mr}</option>
                          <option value="Mrs.">{contentDetails?.your_details_page_data?.Mrs}</option>
                          <option value="Dr." >{contentDetails?.your_details_page_data?.Dr}</option>
                          <option value="Prof.Dr.">{contentDetails?.your_details_page_data?.Prof_Dr}</option>
                        </Form.Select>
                        {sendingAddressError && sendingAddressTitle == undefined && (<span className="error">{sendAddError.title}</span>)}
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm={6}>
                      <Form.Group className="mb-4 text-left">
                        {/* <Form.Label>First Name</Form.Label> */}
                        <Form.Label>{contentDetails?.your_details_page_data?.First_Name}</Form.Label>
                        <Form.Control
                          type="text"
                          // placeholder="Enter your first name"
                          placeholder={contentDetails?.your_details_page_data?.Enter_First_Name}
                          name ="first_name"
                          value={sendingAddressFirstName}
                          onChange={(e)=>setSendingAddressFirstName(e.target.value)}
                        />
                       {sendingAddressError && sendingAddressFirstName == undefined && (<span className="error">{sendAddError.last_name}</span>)}
                      </Form.Group>
                    </Col>
                    <Col sm={6}>
                      <Form.Group className="mb-4 text-left">
                        {/* <Form.Label>Last Name</Form.Label> */}
                        <Form.Label>{contentDetails?.your_details_page_data?.lastName_label}</Form.Label>
                        <Form.Control
                          type="text"
                          // placeholder="Enter your last name"
                          placeholder={contentDetails?.your_details_page_data?.lastName_placeholder}
                          name ="last_name"
                          value={sendingAddressLastName}
                          onChange={(e)=>setSendingAddressLastName(e.target.value)}
                        />
                        {sendingAddressError && sendingAddressLastName == undefined && (<span className="error">{sendAddError.last_name}</span>)}
                      </Form.Group>
                    </Col>
                  </Row>

                  <Form.Group className="mb-4 text-left">
                    {/* <Form.Label>Company Name</Form.Label> */}
                    <Form.Label>{contentDetails?.after_login?.company_name}</Form.Label>
                    <Form.Control
                      type="text"
                      // placeholder="Enter company name"
                      placeholder={contentDetails?.after_login?.company_name_placeholder}
                      name ="company_name"
                      value={sendingAddressCompanyName}
                      onChange={(e)=>setSendingAddressCompanyName(e.target.value)}
                    />
                    {sendingAddressError && sendingAddressCompanyName == undefined && (<span className="error">{sendAddError.company_name}</span>)}
                  </Form.Group>

                  <Form.Group className="mb-4 text-left">
                    {/* <Form.Label>Address</Form.Label> */}
                    <Form.Label>{contentDetails?.after_login?.address}</Form.Label>
                    <Form.Control
                      type="text"
                      // placeholder="Enter your address"
                      placeholder={contentDetails?.after_login?.address_placeholder}
                      name ="address"
                      value={sendingAddress}
                      onChange={(e)=>setSendingAddress(e.target.value)}
                    />
                    {sendingAddressError && sendingAddress == undefined && (<span className="error">{sendAddError.address}</span>)}
                  </Form.Group>

                  <Row>
                    <Col sm={6}>
                      <Form.Group className="mb-4 text-left">
                        {/* <Form.Label>Country</Form.Label> */}
                        <Form.Label>{contentDetails?.your_details_page_data?.Country}</Form.Label>
                        <Form.Select aria-label="Default select example" name ="country" onChange={(e)=>{setSendingAddressCountry(e.target.value);handleRegionsByCountryId2(e.target.value)}}>
                          {/* <option selected hidden>Select Country</option> */}
                          <option selected hidden>{contentDetails?.your_details_page_data?.countryOptionalSelectBoxDefaultText}</option>
                          {showAllCountries2.map((item,index)=>{
                            return(
                              <option value={item.id}>{item.name}</option>
                              )
                          })} 
                        </Form.Select>
                        {sendingAddressError && sendingAddressCountry == undefined && (<span className="error">{sendAddError.country}</span>)}
                      </Form.Group>
                    </Col>
                    <Col sm={6}>
                      <Form.Group className="mb-4 text-left">
                        {/* <Form.Label>Region</Form.Label> */}
                        <Form.Label>{contentDetails?.your_details_page_data?.region_label}</Form.Label>
                          <Form.Select aria-label="Default select example" name ="region" onChange={(e)=>{setSendingAddressRegion(e.target.value);getCityByRegionData(e.target.value)}}>
                                {/* <option selected hidden>Select Region</option> */}
                                <option selected hidden>{contentDetails?.your_details_page_data?.regionSelectBoxDefaultText}</option>
                                {selectedLegalRegionByCountryId2.length > 0 ? (
                                  selectedLegalRegionByCountryId2.map((item,index)=>{
                                    return(
                                      <option value={item.id}>{item.name}</option>
                                    )
                                  })
                                ):(
                                  <option disabled>No regions found</option>
                                )}
                               
                          </Form.Select>
                          {sendingAddressError && sendingAddressRegion == undefined && (<span className="error">{sendAddError.region}</span>)}
                      </Form.Group>
                    </Col>
                  </Row>

                  <Row>
                    <Col sm={6}>
                      <Form.Group className="mb-4 text-left">
                        {/* <Form.Label>City</Form.Label> */}
                        <Form.Label>{contentDetails?.your_details_page_data?.city_label}</Form.Label>
                          <Form.Select aria-label="Default select example" name ="city" onChange={(e)=>setSendingAddressCity(e.target.value)}>
                            {/* <option selected hidden>Select City</option> */}
                            <option selected hidden>{contentDetails?.your_details_page_data?.citySelectBoxDefaultText}</option>
                            {selectedLegalRegionByCountryId2.length > 0 ? (
                              selectedCityWithId2?.map((item,index)=>{
                              return(
                                <option value={item.id}>{item?.name}</option>
                              )})
                            ):(
                              <option disabled>No cities found</option>
                            )} 
                          </Form.Select>
                          {sendingAddressError && sendingAddressCity == undefined && (<span className="error">{sendAddError.city}</span>)}
                      </Form.Group>
                    </Col>
                    <Col sm={6}>
                      <Form.Group className="mb-4 text-left">
                        {/* <Form.Label>Postal Code</Form.Label> */}
                        <Form.Label>{contentDetails?.your_details_page_data?.postalCode_label}</Form.Label>
                          <Form.Control
                            type="text"
                            // placeholder="Enter postal code"
                            placeholder={contentDetails?.your_details_page_data?.postalCode_placeholder}
                            name="postal_code"
                            value={sendingAddressPostalCode}
                            // onChange={(e)=>setSendingAddressPostalCode(e.target.value)}

                            onChange={(e)=>{
                              const val = e.target.value.replace(/[^0-9]/g, '').replace(/[eE]/g, '');
                              if (val.length <= 10)
                              {
                                setSendingAddressPostalCode(val);
                              }
                            }}
                          />
                          {sendingAddressError && sendingAddressPostalCode == undefined && (<span className="error">{sendAddError.postal_code}</span>)}
                        </Form.Group>
                    </Col>
                  </Row>

                  <Row>
                    <Col sm={6}>
                      <Form.Group className="mb-4 text-left">
                        {/* <Form.Label>Area Code</Form.Label> */}
                        <Form.Label>{contentDetails?.after_login?.area_code}</Form.Label>
                          <Form.Control
                            type="text"
                            // placeholder="Enter area code"
                            placeholder={contentDetails?.after_login?.area_code_placeholder}
                            name="area_code"
                            value={sendingAddressAreaCode}
                            // onChange={(e)=>setSendingAddressAreaCode(e.target.value)}
                            onChange={(e)=>{
                              const val = e.target.value.replace(/[^0-9]/g, '').replace(/[eE]/g, '');
                              if (val.length <= 10)
                              {
                                setSendingAddressAreaCode(val);
                              }
                            }}
                          />
                          {sendingAddressError && sendingAddressAreaCode == undefined && (<span className="error">{sendAddError.area_code}</span>)}
                        </Form.Group>
                    </Col>
                    <Col sm={6}>
                      <Form.Group className="mb-4 text-left">
                        {/* <Form.Label>Phone Number</Form.Label> */}
                        <Form.Label>{contentDetails?.your_details_page_data?.phoneNumber_label}</Form.Label>
                          <Form.Control
                            type="text"
                            // placeholder="Enter phone number"
                            placeholder={contentDetails?.your_details_page_data?.phoneNumber_placeholder}
                            name="phone_number"
                            value={sendingAddressPhoneNumber}
                            // onChange={(e)=>setSendingAddressPhoneNumber(e.target.value)}
                            // maxLength={11}
                            onChange={(e)=>{
                              const val = e.target.value.replace(/[^0-9]/g, '').replace(/[eE]/g, '');
                              if (val.length <= 10)
                              {
                                setSendingAddressPhoneNumber(val);
                              }
                            }}
                          />
                          {sendingAddressError && sendingAddressPhoneNumber == undefined && (<span className="error">{sendAddError.phone_number}</span>)}
                        </Form.Group>
                    </Col>
                  </Row>

                  <a
                    role="button"
                    tabindex="0"
                    type="submit"
                    class="BtnSubmit2 btn btn-primary"
                    onClick={() => handleSendingAddress()}
                  >
                    {/* Submit */}
                    {contentDetails?.after_login?.submit_button}
                  </a>
                </div>
              </div> 
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default NewAddress;
