import React, {useState, useEffect, useRef} from "react";
import axios from "axios";
import "./ForgotPassword.scss";
import logo from "../../assets/images/Logo.svg";
import newlogo from "../../assets/images/Clever-New-Logo.png";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { Link, useNavigate } from "react-router-dom";
import {API_BASE_URL} from "../../app_url";
import { getPageContent, getUserStatus } from "../../api/apiRequest";
import Cookies from "js-cookie";
import {NotificationManager} from 'react-notifications';
import { useLangContext } from "../../context/languageContext";


const ForgotPassword = () => {
  const navigate = useNavigate();
  const {hostLanguage} = useLangContext();

  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [loginInfo, setLoginInfo] = useState("");
  const [userData, setLoginData] = useState();
  const [tokenexist, setTokenexist] = useState(false);
  const [contentDetails,setContentDetails] = useState();

  useEffect(() => {
    GetCookie();
    getAllPageContent();
    let value = JSON.parse(localStorage.getItem("virtualOfficeLoginInfo")); //JSON.parse(secureLocalStorage.getItem('loginInfo'));
    if (value != undefined) {
      console.log(value)
      if(value.go_to_step==1){
        navigate("/LetStarted");
      }else if(value.go_to_step==2){
        navigate("/SelectProduct");
      }else if(value.go_to_step==3){
        navigate("/YourDetails");
      }else if(value.go_to_step==4){
        navigate("/Payment");
      }else if(value.go_to_step==5){
        navigate("/Dashboard");
      }
    }
    
  }, []);

  const GetCookie = () => {
    //alert(Cookies.get("token"));
    setTokenexist(Cookies.get("token"));

    if(Cookies.get("language")){
      //setSelectedLanguage(Cookies.get("language"));
    }
  };


  // get page content
  const getAllPageContent = async() => {
    try
    {
      let apiRes = await  getPageContent(hostLanguage);
      let responseData = await apiRes.json();
      if(responseData.res)
      {
        setContentDetails(responseData.data);
        // console.warn(responseData.data);
      }
      else
      {
        console.error(responseData.msg,'',2000);
      }
    }
    catch(error)
    {
      console.error(error);
    }
  }

  const [formDataSignin, setFormDataSignin] = useState({
    email: "",
    password: "",
  });

  const [formErrorsSignin, setFormErrorsSignin] = useState({
    email: "",
    password: "",
  });

  const handleChangeSignin = e => {
    const {name, value} = e.target;
    setFormDataSignin({...formDataSignin, [name]: value});
    setFormErrorsSignin({...formErrorsSignin, [name]: ''});
  };

  //get user status
const handleUserStatus = async () => {
  try
  {
    let apiRes = await getUserStatus();
    let responseData = await apiRes.json();
    if(responseData.res)
    {
      // console.warn(responseData.data)
      // console.warn(responseData.data.go_to_step)
      if(responseData.data.product_id === 1)
      {
        if(responseData.data.go_to_step==1)
        {
          navigate("/LetStarted");
        }
        else if(responseData.data.go_to_step==2)
        {
          navigate("/SelectProduct");
        }
        else if(responseData.data.go_to_step==3)
        {
          navigate("/YourDetails");
        }
        else if(responseData.data.go_to_step==4)
        {
          navigate("/Payment");
        }
        else if(responseData.data.go_to_step==5)
        {
          navigate("/Dashboard");
        }
        setIsSubmitting(false);
          setErrorMessage(""); // Clear any previous error messages
          setFormDataSignin({
            email: "",
            password: "",
          });
          setFormErrorsSignin({
            email: "",
            password: "",
          });
      }
      else if(responseData.data.product_id === 2)
      {
        if(responseData.data.go_to_step==1)
        {
          navigate("/LetStarted");
        }
        else if(responseData.data.go_to_step==2)
        {
          navigate("/SelectProduct");
        }
        else if(responseData.data.go_to_step==3)
        {
          navigate("/SelectCountryPhoneNumber");
        }
        else if(responseData.data.go_to_step==4)
        {
          navigate("/Payment");
        }
        else if(responseData.data.go_to_step==5)
        {
          navigate("/Dashboard");
        }
        setIsSubmitting(false);
          setErrorMessage(""); // Clear any previous error messages
          setFormDataSignin({
            email: "",
            password: "",
          });
          setFormErrorsSignin({
            email: "",
            password: "",
          });
      }
      else
      {
        throw new Error('Product_id is not matched.');
      }
    }
    else
    {
      // NotificationManager.error(responseData.msg, '',2000);
    }
  }
  catch(error)
  {
    // NotificationManager.error('Something went wrong!', '',2000);
  }
}

  const handleForgotPassword = e => {
    e.preventDefault();
    if (validateFormSignin()) {
      setIsSubmitting(true);
      // Send the signup data to your Python Flask API
      // You can use libraries like Axios to make API requests
      // Example:
      axios
        .post(`${API_BASE_URL}user/forget-password`, JSON.stringify(formDataSignin), {
          headers: {
            "Content-Type": "application/json", // Set the content type
          },
        })
        .then(response => {
          console.log(response.data)
        if (response.data.res==false)
        {
          setErrorMessage(response.data.msg)
          setIsSubmitting(false);
        }
        else
        {
          const token = response.data.token;
          //localStorage.setItem("virtualOfficeLoginInfo", JSON.stringify(response.data.data));
         // NotificationManager.success(response.data.msg, '',2000);
          setSuccessMessage(response.data.msg, '',2000);
          console.warn(response.data.data);
          setErrorMessage("");
          
          //handleUserStatus();

          // if(response.data.data.go_to_step==1)
          // {
          //   navigate("/LetStarted");
          // }
          // else if(response.data.data.go_to_step==2)
          // {
          //   navigate("/SelectProduct");
          // }
          // else if(response.data.data.go_to_step==3)
          // {
          //   navigate("/YourDetails");
          // }
          // else if(response.data.data.go_to_step==4)
          // {
          //   navigate("/Payment");
          // }
          // else if(response.data.data.go_to_step==5)
          // {
          //   navigate("/Dashboard");
          // }

          // if (props?.from) {
          //   navigate(props?.from);
          //   navigate(0);
          // } else {
          //   navigate("/@" + response.data.data.user.email);
          // }

          // setIsSubmitting(false);
          // setErrorMessage(""); // Clear any previous error messages
          // setFormDataSignin({
          //   email: "",
          //   password: "",
          // });
          // setFormErrorsSignin({
          //   email: "",
          //   password: "",
          // });
        }
        })
        .catch(error => {
          setIsSubmitting(false);
          if (error.response) {
            setErrorMessage(error.response.data.error || "Please enter valid email");
          } else {
            setErrorMessage("Please enter valid email");
          }
          setSuccessMessage(""); // Clear any previous success messages
        });
    }
  };

  const validateFormSignin = () => {
    let isValid = true;
    const errors = {
      email: "",
      password: "",
    };

    if (formDataSignin.email === "") {
      
      if(hostLanguage == 'ch' || hostLanguage == 'de')
      {
        errors.email = "Geben sie ihre E-Mail Adresse ein";
      }
      else if(hostLanguage == 'en')
      {
        errors.email = "Enter your email";
      }
      isValid = false;
    }

    // if (formDataSignin.password === "") {
    //   errors.password = "Please enter your password";
    //   isValid = false;
    // }

    setFormErrorsSignin(errors);
    return isValid;
  };

  const handleRoute = (route) => {
    navigate(route);
  };

  return (
    <div className="Loginody">
      <div className="MainBody">
        <Link to='/'>
          {/* <img src={logo} className="MainLogo" alt="logo"/> */}
          <img src={newlogo} className="MainLogo" alt="logo"/>
        </Link>
        {/* <h1>Forgot Password</h1> */}
        <h1>{contentDetails?.forgotPassword_page_data?.forgotPassword}</h1>

        <div className="InnerBox">
          <form className="form" onSubmit={handleForgotPassword}>
                {successMessage && <div className="success-message">{successMessage}</div>}
                {errorMessage && <div className="error">{errorMessage}</div>}
                
            <Form.Group className="mb-4 mail" controlId="">
              {/* <Form.Label>Email Address</Form.Label> */}
              <Form.Label>{contentDetails?.forgotPassword_page_data?.emailAddress}</Form.Label>
              {/* <Form.Control type="email" placeholder="Enter your email" value={formDataSignin.email}
                    onChange={handleChangeSignin}/> */}
              <input
                name="email"
                value={formDataSignin.email}
                onChange={handleChangeSignin}
                type="text"
                // placeholder="Enter your email"
                placeholder={contentDetails?.forgotPassword_page_data?.emailAddress_placeholder}
                className="form-control"
              />
              {formErrorsSignin.email && <span className="error">{formErrorsSignin.email}</span>}
            </Form.Group>

            {isSubmitting ? (
              // <Button variant="primary" type="submit" className="submitBtn" >Submit</Button>
              <Button variant="primary" type="submit" className="submitBtn" >{contentDetails?.forgotPassword_page_data?.submit_button_text}</Button>
            ):(
              <Button variant="primary" type="submit" className="submitBtn"
              // href="/StuffDashboard"
              //onClick={() => handleRoute("/StuffDashboard")}
            >
              {contentDetails?.forgotPassword_page_data?.submit_button_text}
            </Button>
            )}
            
          </form>
          {/* <Link to="/Login" style={{marginLeft: '200px'}}>Back</Link> */}
          <Link to="/Login" style={{marginLeft: '200px'}}>{contentDetails?.forgotPassword_page_data?.back_button_text}</Link>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
