import React, { useEffect, useState } from "react";
import Header from "../../components/header/Header";
import "./RenewPayment.scss";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Step from "../../components/step/Step";
import Form from "react-bootstrap/Form";
import cloud from "../../assets/images/CloudIcon.svg";
import phone from "../../assets/images/PhnIcon.svg";
import TickBlue from "../../assets/images/TickBlue.svg";
import Button from "react-bootstrap/Button";
import Accordion from "react-bootstrap/Accordion";
import { HiOutlinePlusSm, HiOutlineTrash, HiCheck } from "react-icons/hi";
import { useNavigate, Link, redirect, useLocation } from "react-router-dom";
import {NotificationManager} from 'react-notifications';
import Modal from "react-bootstrap/Modal";
import {Formik} from 'formik';
import * as yup from 'yup';

import { getPageContent, getRenewalProductDetails } from "../../api/apiRequest";
import PayPalComponent from "../PaymentPages/PayPalComponent";
import PayPalPayment from "../PaymentPages/PayPalPayment";
import Conditions from "../accept-condition/Conditions";
import { useLangContext } from "../../context/languageContext";

const RenewPayment = () => {
  const navigate = useNavigate();
  const {state} = useLocation();
  const {hostLanguage,languageJson} = useLangContext();
  console.warn(state)
  const [thankShow, setThanksShow] = useState(false);
  const thankhandleClose = () => setThanksShow(false);
  const [isDisable,setIsDisable] = useState(false);
  const [checkBoxValue1,setCheckBoxValue1] = useState();
  const [checkBoxValue2,setCheckBoxValue2] = useState();
  const [productPrice,setProductPrice] = useState();
  const [contentDetails,setContentDetails] = useState();
  

  const handleParentData1 = (e) => {
    setCheckBoxValue1(e);
    console.log(e);
  }

  const handleParentData2 = (e) => {
    setCheckBoxValue2(e);
    console.log(e);
  }

  //product details
  const fetchProductDetails = async() => {
    let apiRes = await getRenewalProductDetails(hostLanguage,state);
    let responseData = await apiRes.json();
    if(responseData.res)
    {
      console.log(responseData.data);
      if(responseData?.data?.product?.id == 1)
      {
        console.warn(parseFloat(responseData?.data?.plan?.price).toFixed(2));
        setProductPrice(responseData?.data?.plan?.price);
      }
      else if(responseData?.data?.product?.id == 2)
      {
        console.warn(parseFloat(responseData?.data?.phone_area?.total).toFixed(2));
        setProductPrice(responseData?.data?.phone_area?.total);
      }
    }
    else
    {
      console.error(responseData.data);
    }
  }

  // get page content
  const getAllPageContent = async() => {
    try
    {
      let apiRes = await  getPageContent(hostLanguage);
      let responseData = await apiRes.json();
      if(responseData.res)
      {
        setContentDetails(responseData.data);
        // console.warn(responseData.data.header);
      }
      else
      {
        NotificationManager.error(responseData.msg,'',2000);
      }
    }
    catch(error)
    {
      console.error(error);
    }
  }

  useEffect(()=>{
    getAllPageContent();
    if(state!=null)
    {
      fetchProductDetails();
    }
  },[hostLanguage])

  return (
    <div className="lightBlue">
      <Header />
      <div className="MainBody">
        <div className="container">
          <Row className="">
            <Col sm={8}>
              <div className="MainBodyRgt">
                {productPrice!= undefined ? (
                  // <h5>Net Payabale Amount: € {parseFloat(productPrice).toFixed(2)}</h5>
                  <h5>{contentDetails?.after_login?.net_payable_amount} € {parseFloat(productPrice).toFixed(2)}</h5>
                ):(
                  // <h5>Net Payabale Amount: € 0.00</h5>
                  <h5>{contentDetails?.after_login?.net_payable_amount} € 0.00</h5>
                )}
                {/* <p>Your selected cleverdomizil services</p> */}
                <p>{contentDetails?.after_login?.your_selected_cleverdomizil_services}</p>
                <div className="MainBodyRgtInner">
                  <Conditions propsData1 = {handleParentData1} propsData2 = {handleParentData2} type='renewal' amount={parseFloat(productPrice).toFixed(2)} productId={state}/>
                  {
                    checkBoxValue1 && checkBoxValue1 === 'accept_terms' &&
                    checkBoxValue2 && checkBoxValue2 === 'accept_contract' ? (
                      <>
                        {/* <h3>Payment With</h3> */}
                        <h3>{contentDetails?.after_login?.payment_with}</h3>
                        <PayPalComponent productInfo={'renewal-payment'} scanData={state} productPrice ={parseFloat(productPrice).toFixed(2)} type={'renewal-payment'}/>
                        <PayPalPayment productInfo={'renewal-payment'} scanData={state} productPrice ={parseFloat(productPrice).toFixed(2)} type={'renewal-payment'}/>
                      </>
                    ):(
                      <></>
                  )}
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </div>
        <Modal
          show={thankShow}
          onHide={thankhandleClose}
          backdrop="static"
          keyboard={false}
          centered
          className="CustomModal CustomModalTnk"
        >
          <Modal.Header closeButton>
            <Modal.Title></Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <img src={TickBlue} className="TickBlueLogo" alt="logo" />
            <h5>Thank You for requesting</h5>
            {/* <h2>Your order is ready for shipment.</h2> */}
            <h2>We are processing your shipment.</h2>
          </Modal.Body>
          <Modal.Footer>
            {/* <Button
              variant="secondary"
              onClick={thankhandleClose}
              className="lftBtn"
            >
              Check Status
            </Button> */}
            {isDisable ? (
              <Button variant="primary" className="rgtBtn" disabled style={{cursor:'not-allowed'}}>Go to MailBox</Button>
            ):(
              <Button variant="primary" className="rgtBtn" onClick={()=>{setIsDisable(true);navigate('/AllMail')}}>Go to MailBox</Button>
            )}
          </Modal.Footer>
        </Modal>
    </div>
  );
};

export default RenewPayment;
