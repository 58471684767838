import React, { useState, useEffect, useRef } from "react";
import Nav from "react-bootstrap/Nav";
import "./Sidebar.scss";
import { NavLink } from "react-router-dom";
import { useNavigate, Link } from "react-router-dom";
import { NotificationManager } from "react-notifications";
import { changeTitle, onLogout } from "../../utils/commonData";

import { getAllMailCount, getPageContent } from "../../api/apiRequest";
import { useContextValues } from "../../context/commonContext";
import { useLangContext } from "../../context/languageContext";

const Sidebar = (props) => {
  const { eventFire } = useContextValues();
  const { hostLanguage, languageJson } = useLangContext();
  const navigate = useNavigate();
  const [allMailCount, setAllMailCount] = useState();
  const [contentDetails, setContentDetails] = useState();

  const Logout = () => {
    onLogout();
    navigate("/Login");
  };

  // fetching sidebar count
  const fetchingSideBarCount = async () => {
    try {
      let apiRes = await getAllMailCount();
      let responseData = await apiRes.json();
      if (responseData.res) {
        setAllMailCount(responseData.data);
      } else {
        NotificationManager.error(responseData.msg, "", 2000);
      }
    } catch (error) {
      console.error(error);
    }
  };

  // get page content
  const getAllPageContent = async () => {
    try {
      let apiRes = await getPageContent(hostLanguage);
      let responseData = await apiRes.json();
      if (responseData.res) {
        setContentDetails(responseData.data);
      } else {
        NotificationManager.error(responseData.msg, "", 2000);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchingSideBarCount();
  }, [eventFire]);

  useEffect(() => {
    getAllPageContent();
  }, [hostLanguage]);

  return (
    <div className="SidebarMenu">
      <Nav>
        <Nav.Item>
          <NavLink to={"/AllMail"}>
            {contentDetails?.after_login?.AllMail}{" "}
            <span class="count-span">
              {allMailCount?.all_mail_count > 0 && allMailCount?.all_mail_count < 10 ? `0${allMailCount?.all_mail_count}` : allMailCount?.all_mail_count}
            </span>
          </NavLink>
        </Nav.Item>

        <Nav.Item>
          <NavLink to={"/MailBox"}>
            {contentDetails?.after_login?.NewMail}{" "}
            <span class="count-span">
              {allMailCount?.all_new_mail_count > 0 && allMailCount?.all_new_mail_count < 10
                ? `0${allMailCount?.all_new_mail_count}`
                : allMailCount?.all_new_mail_count}
            </span>
          </NavLink>
        </Nav.Item>
        <Nav.Item>
          <NavLink to={"/EnvelopScan"}>
            {contentDetails?.after_login?.EnvelopScan}{" "}
            <span class="count-span">
              {allMailCount?.all_envelop_count > 0 && allMailCount?.all_envelop_count < 10
                ? `0${allMailCount?.all_envelop_count}`
                : allMailCount?.all_envelop_count}
            </span>
          </NavLink>
        </Nav.Item>
        <Nav.Item>
          <NavLink to={"/Scans"}>
            {contentDetails?.after_login?.ScanDocuments}{" "}
            <span className="count-span">
              {allMailCount?.all_scan_document_count > 0 && allMailCount?.all_scan_document_count < 10
                ? `0${allMailCount?.all_scan_document_count}`
                : allMailCount?.all_scan_document_count}
            </span>
          </NavLink>
        </Nav.Item>
        <Nav.Item>
          <NavLink to={"/MyRequest"}>
            {contentDetails?.after_login?.MyRequest}{" "}
            <span class="count-span">
              {allMailCount?.all_request_count > 0 && allMailCount?.all_request_count < 10
                ? `0${allMailCount?.all_request_count}`
                : allMailCount?.all_request_count}
            </span>
          </NavLink>
        </Nav.Item>
        <Nav.Item>
          <NavLink to={"/Trashed"}>
            {contentDetails?.after_login?.Trashed}{" "}
            <span class="count-span">
              {" "}
              {allMailCount?.all_trash_count > 0 && allMailCount?.all_trash_count < 10 ? `0${allMailCount?.all_trash_count}` : allMailCount?.all_trash_count}
            </span>
          </NavLink>
        </Nav.Item>

        <Nav.Item>
          <NavLink to={"/InvoicesPayments"}>{contentDetails?.after_login?.InvoicesAndPayments}</NavLink>
        </Nav.Item>

        <Nav.Item>
          <NavLink to={"/purchased-phone-number"}>{contentDetails?.after_login?.PurchasedNumbers}</NavLink>
        </Nav.Item>
      </Nav>
    </div>
  );
};

export default Sidebar;
